import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import FormTextField from '../../form/NativeFormTextField';
import FormSmallCheckBox from '../../form/FormSmallCheckBox';
import { finishRegistrationFormValidation } from '../../../models/auth/AuthModelValidation';
import './SignInBySMSFormStyles.scss';
import {Button} from "@material-ui/core";

let FinishRegistrationForm = ({ handleSubmit, submitting, invalid, registrationAsEmail, registrationAsOAuth }) => {
  const [t] = useTranslation();
  const [showFullADO, setShowFullADO] = useState(false);

  const truncateADO = useMemo(() => {
    if(showFullADO){
      return t('finishRegistration.form.personalDataProcessingAgreementLabel')
        .replace(/\\n/g, '\n')
    }
    return (t('finishRegistration.form.personalDataProcessingAgreementLabel')
      .replace(/\\n/g, '\n')
      .substring(0, 150)) + '...'
  }, [showFullADO])

  return (
    <form onSubmit={handleSubmit}>
      <p>{t('finishRegistration.form.header')}</p>
      <div className="form-group">
        <Field
          disabled={registrationAsOAuth}
          name="firstName"
          component={FormTextField}
          label={t('finishRegistration.form.firstNameLabel')}
          autoFocus
        />
      </div>
      <div className="form-group">
        <Field
          disabled={registrationAsOAuth}
          name="lastName"
          component={FormTextField}
          label={t('finishRegistration.form.lastNameLabel')}
        />
      </div>
      <div className="form-group">
        <Field
          disabled={registrationAsEmail || registrationAsOAuth}
          name="email"
          type="email"
          component={FormTextField}
          label={t('finishRegistration.form.emailLabel')}
        />
      </div>
      <div className="form-group">
        <Field
          name="isAdult"
          disabled={registrationAsOAuth}
          component={FormSmallCheckBox}
          label={t('finishRegistration.form.isAdultLabel')}
        />
      </div>
      <div className="form-group">
        <Field
          name="termsAndConditions"
          component={FormSmallCheckBox}
          label={
            <span>
              <span>{t('finishRegistration.form.termsLabel')}</span>
              <span><a className={'link-clear'} style={{ textTransform: 'lowercase' }} href={t('finishRegistration.termsAndConditionsUrl')} title={t('finishRegistration.form.termsLink')} target="_blank" rel="noopener noreferrer">{t('finishRegistration.form.termsLink')} </a> </span>
            </span>
        }
        />
      </div>
      <div className="form-group">
        <Field
          name="personalDataProcessing"
          component={FormSmallCheckBox}
          label={<>
            <span>{truncateADO}</span>
            <div className='truncate-button'>
              <Button onClick={() => setShowFullADO(prev => !prev)}>
                {!showFullADO && <span>Pokaż więcej</span>}
                {showFullADO && <span>Ukryj</span>}
              </Button></div>
          </>}
        />
      </div>
      <button type="submit" className="btn btn-default" disabled={invalid || submitting}>
        {submitting ? t('finishRegistration.form.submitButtonProgress') : t('finishRegistration.form.submitButton')}
      </button>
    </form>
  );
};

FinishRegistrationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  registrationAsEmail: PropTypes.bool,
  registrationAsOAuth: PropTypes.bool
};

FinishRegistrationForm = reduxForm({
  form: 'finishRegistration',
  validate: finishRegistrationFormValidation,
})(FinishRegistrationForm);

export default FinishRegistrationForm;
