import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Typography, Button } from '@material-ui/core';
import DocumentTitle from 'react-document-title';
import { actions } from '../../actions/termsAndConditionsActions';
import i18n from '../../i18n';

class OperatorTermsAndConditionsPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleAcceptTerms = this.handleAcceptTerms.bind(this);
  }

  componentDidMount() {
    this.props.actions.getTermsAndConditions(this.props.match.params.operator);
  }

  handleAcceptTerms() {
    this.props.actions.showDialog(this.props.match.params.operator);
  }

  render() {
    const { content, acceptedTerms } = this.props;
    return (
      <DocumentTitle title={i18n.t('termsAndConditions.title')}>
        <div className="page">
          <div style={{ margin: 16 }}>
            <Grid container justify="center">
              <Grid item xs={12} md={8}>
                <Typography variant="h4" gutterBottom>{i18n.t('termsAndConditions.title')}</Typography>
                {(!content.fetching && content.data && content.data.additionalImageUrl != null)
                  &&
                  <a
                    href={content.data.additionalImageUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      style={{ marginTop: 8, marginBottom: 8, display: 'block', marginLeft: 'auto', marginRight: 'auto', maxHeight: 400, maxWidth: 400, height: 'auto', width: 'auto', objectFit: 'contain' }}
                      src={content.data.additionalImageUrl} />
                  </a>
                }
                {(!content.fetching && content.data) && <div dangerouslySetInnerHTML={{ __html: content.data.content }} />}
                {(!content.fetching && content.data && content.data.content && !acceptedTerms.some(t => t.tenant === content.data.tenant && t.isLatestVersionAccepted)) && <Button fullWidth color="primary" variant="contained" onClick={this.handleAcceptTerms}>{i18n.t('termsAndConditions.dialog.title')}</Button>}
              </Grid>
            </Grid>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

OperatorTermsAndConditionsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  acceptedTerms: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    content: state.termsAndConditions,
    acceptedTerms: state.userState.acceptedTermsAndConditions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorTermsAndConditionsPage);
