import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { withGoogleMap, GoogleMap, Polygon, Marker, Circle } from 'react-google-maps';
import blue from '@material-ui/core/colors/blue';
import { ZoneAreaTypes } from '../../models/ZoneModel';

const shapeOptions = {
  operationsZone: {
    strokeColor: "#000",
    strokeOpacity: 0.8,
    strokeWeight: 4,
    fillColor: "#607D8B",
    fillOpacity: 0.3,
    clickable: false,
    zIndex: 1,
  },
  preferredBikeReturnZone: {
    strokeColor: "#e9e623",
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: "#e9e623",
    fillOpacity: 0.5,
    clickable: false,
    zIndex: 2,
  },
};

const userLocationIcon = {
  path: 'M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z',
  fillColor: blue[600],
  fillOpacity: 1,
  strokeWeight: 0,
  anchor: { x: 12, y: 24 }
};

const getMarkerIcon = zoneIconUrl => {
  return {
    url: zoneIconUrl,
    /* eslint-disable no-undef */
    scaledSize: new google.maps.Size(32, 32)
  };
};

const noPoi = [
  {
    featureType: "poi.business",
    stylers: [
      { visibility: "off" }
    ]
  }
];

let initialLocation = { lat: 52, lng: 20 };
let initialZoom = 14;

const updateInitialLocation = value => {
  if (!value || (value.lat === initialLocation.lat && value.lng === initialLocation.lng)) return;

  initialLocation = value;
}

const updateInitialZoom = value => {
  if (!value) return;

  initialZoom = value;
}

const mapOptions = { streetViewControl: false, mapTypeControl: false, clickableIcons: false, fullscreenControl: false, styles: noPoi };
const MapViewRaw = compose(
  withHandlers(() => {
    const refs = {
      map: undefined,
    }

    return {
      onMapMounted: () => ref => {
        refs.map = ref;
      },
      onBoundsChanged: ({ onRegionChanged }) => () => {
        if (!onRegionChanged) return;

        const center = refs.map.getCenter();
        const bounds = refs.map.getBounds();
        const sw = bounds.getSouthWest()
        const latitudeDelta = center.lat() - sw.lat();
        const longitudeDelta = center.lng() - sw.lng();

        const region = { latitude: center.lat(), longitude: center.lng(), latitudeDelta, longitudeDelta };
        onRegionChanged(region);
      }
    }
  }),
  withGoogleMap
)(props => {
  updateInitialLocation(props.initialLocation);
  updateInitialZoom(props.initialZoom);
  return (
    <GoogleMap
      ref={props.onMapMounted}
      defaultZoom={props.initialZoom}
      defaultCenter={props.initialLocation}
      defaultOptions={mapOptions}
      onIdle={() => props.onBoundsChanged()}
      onClick={props.onClick}
      center={initialLocation}
      zoom={initialZoom}
    >
      {props.bikes ? props.bikes.map(bike => props.markerElement(bike)) : null}
      {props.zones ? props.zones.filter(z => !z.marker && z.areaType === ZoneAreaTypes.polygon).map(zone => (
        <Polygon
          key={zone.id}
          path={zone.area}
          options={shapeOptions[zone.type]}
        />)) : null}
      {props.zones ? props.zones.filter(z => !z.marker && z.areaType === ZoneAreaTypes.circle).map(zone => (
        <Circle
          key={zone.id}
          options={shapeOptions[zone.type]}
          center={zone.area.center}
          radius={zone.area.radius}
        />)) : null}
      {props.zones ? props.zones.filter(z => z.marker).map(zone => (
        <Marker
          key={zone.id}
          icon={zone.marker.icon ? getMarkerIcon(zone.marker.icon) : null}
          position={zone.marker}
        />)) : null}
      {props.user &&
        <Marker
          icon={userLocationIcon}
          position={props.user}
        />}
    </GoogleMap>
  )
});

const Map = (props) => (<MapViewRaw  {...props} />);

Map.propTypes = {
  markerElement: PropTypes.func.isRequired,
  initialLocation: PropTypes.object.isRequired,
  initialZoom: PropTypes.number.isRequired,
  bikes: PropTypes.array,
  zones: PropTypes.array,
  onRegionChanged: PropTypes.func,
};

export default Map;
