import React, {useEffect} from 'react'
import AnonymousContentPanel from "../../AnonymousContentPanel";
import DocumentTitle from "react-document-title";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import routePaths from "../../../../routePaths";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {actions as authActions} from "../../../../actions/authActions";
import {Oauth2Provider} from "../../../../models/auth/AuthModel";
import {useLocation} from "react-router";
import FinishRegistrationForm from "../../signIn/FinishRegistrationForm";

const Oauth2Page = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();
  const params = useLocation();

  const auth = useSelector(x => x.auth)

  const handleLogin = (code) => {
    dispatch(authActions.requestOauth2Login(Oauth2Provider.plockCard, {code}))
  }

  useEffect(() => {
    const urlSearchParams =  new URLSearchParams(params.search);
    const code = urlSearchParams.get('code');
    handleLogin(code)
  }, []);
  return(
    <DocumentTitle title="ROOVEE">
      <AnonymousContentPanel>
        {auth.oauth2.isFetching && <dvi>
          <div style={{fontSize: 18}}>{t('oauth2.loginProcess.line1')}</div>
          <div style={{fontSize: 18}}>{t('oauth2.loginProcess.line2')}</div>
        </dvi>}
        {auth.authenticationProgress === 3 && <FinishRegistrationForm
          registrationAsOAuth={true}
          initialValues={{
            email: auth.oauth2.email,
            firstName: auth.oauth2.firstName,
            lastName: auth.oauth2.lastName,
            isAdult: auth.oauth2.isAdult,
          }}
          hasEmail={auth.oauth2.email}
          onSubmit={data => dispatch(authActions.finishRegistrationByOAuth2(data, auth.authenticationToken))}
        />}
        {auth.oauth2.error &&
          <div>
            <div style={{fontSize: 18}}>{t('oauth2.error.line1')}</div>
            <div style={{fontSize: 18}}>{t('oauth2.error.line2')}</div>
            {auth.oauth2.errorData && <div style={{fontSize: 18, textAlign: "justify", marginTop: 5}}>{t(auth.oauth2.errorData)}</div>}
            <div style={{marginTop: 15}}>
              <Button component={Link}
                      to={routePaths.home}
                      className="btn btn-default"
                      style={{whiteSpace: "normal"}}
              >
                {t('oauth2.redirectToMainPage')}
              </Button>
            </div>
          </div>}
      </AnonymousContentPanel>
    </DocumentTitle>
  )
}

export default Oauth2Page;
