import {CircularProgress} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const Oauth2Fetching = ({show}) => {
  if(!show) return null;
  return (
    <div>
      <CircularProgress color="secondary"  />
      <div style={{ fontSize: 18 }}>Zostaniesz przekierowany do</div>
      <div style={{ marginTop: 1, marginBottom: 1, fontSize: 18, fontWeight: 600 }}>miejskiej karty w Płocku.</div>
      <div style={{ fontSize: 18 }}>Prosimy czekać.</div>
    </div>
  )
}

Oauth2Fetching.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default Oauth2Fetching;
