import validate from 'validate.js';
import i18n from '../i18n';
import { BikeParts } from './BikeParts';

export const bikeDefectFormValidation = values => {
  const constraints = {
    bikeNumber: {
      presence: { message: i18n.t('common.validation.required') },
    },
    description: () => {
      if (!values.brokenParts || values.brokenParts.length === 0 || values.brokenParts.some(b => b.value === BikeParts.qr.value || b.value === BikeParts.lock.value))
      {
        return {
          presence: { message: i18n.t('common.validation.required') },
        };
      }
    },
    brokenParts: {
      presence: { message: i18n.t('common.validation.pickOne') },
      length: {
        minimum: 1,
        message: i18n.t('common.validation.pickOne')
      }
    },
  };

  return validate(values, constraints, { fullMessages: false });
};