import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';
import UserProfileDetails from './UserProfileDetails';

const UserProfilePage = () => {
  const [t] = useTranslation();

  return (
    <DocumentTitle title={t('userProfile.title')}>
      <div className="page">
        <UserProfileDetails />
      </div>
    </DocumentTitle>
  );
};

export default UserProfilePage;
