import userApi from '../api/userApi';
import * as logger from '../utils/logger';
export const types = {
  SUBSCRIBE_CAMPAIGN: 'SUBSCRIBE_CAMPAIGN',
  SUBSCRIBE_CAMPAIGN_SUCCESS: 'SUBSCRIBE_CAMPAIGN_SUCCESS',
  SUBSCRIBE_CAMPAIGN_FAILURE: 'SUBSCRIBE_CAMPAIGN_FAILURE',
  CHECK_SUBSCRIBE_CAMPAIGN_SUCCESS: 'CHECK_SUBSCRIBE_CAMPAIGN_SUCCESS',
  CHECK_SUBSCRIBE_CAMPAIGN_FAILURE: 'CHECK_SUBSCRIBE_CAMPAIGN_FAILURE',

};

export const actions = {
  subscribeCampaign: (isSubscribe) => dispatch => {
    dispatch({ type: types.SUBSCRIBE_CAMPAIGN });
    return userApi
      .subscribeCampaign(isSubscribe)
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.SUBSCRIBE_CAMPAIGN_SUCCESS, isSubscribe });
        }
        dispatch({type: types.SUBSCRIBE_CAMPAIGN_FAILURE});
        return logger.error(response);
      });
  },
  unsubscribeNewsletterCampaign: (userId) => dispatch => {
    dispatch({ type: types.SUBSCRIBE_CAMPAIGN });
    return userApi
      .unsubscribeNewsletterCampaign(userId)
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.SUBSCRIBE_CAMPAIGN_SUCCESS, isSubscribe: false });
        }
        dispatch({type: types.SUBSCRIBE_CAMPAIGN_FAILURE});
        return logger.error(response);
      });
  },
  getUserSubscribeCampaign: () => dispatch => {
    return userApi
      .checkSubscribeCampaign()
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.CHECK_SUBSCRIBE_CAMPAIGN_SUCCESS, data: response.data });
        }
        dispatch({type: types.CHECK_SUBSCRIBE_CAMPAIGN_FAILURE});
        return logger.error(response);
      });
  }
};
