import i18n from '../i18n';
import userApi from '../api/userApi';
import { actions as notificationActions } from './notificationActions';
import { actions as walletActions } from './walletActions';
import * as logger from '../utils/logger';
export const types = {
  GET_USER_STATE_SUCCESS: 'GET_USER_STATE_SUCCESS',
  GET_USER_STATE_FAILURE: 'GET_USER_STATE_FAILURE',
  UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
  UPDATE_USER_PROFILE_FAILURE: 'UPDATE_USER_PROFILE_FAILURE',
  UPDATE_USER_PREFERRED_LANGUAGE_SUCCESS: 'UPDATE_USER_PREFERRED_LANGUAGE_SUCCESS',

  RESET_ASSIGN_PHONE_NUMBER: 'RESET_ASSIGN_PHONE_NUMBER',

  ASSIGN_PHONE_NUMBER_REQUEST: 'ASSIGN_PHONE_NUMBER_REQUEST',
  ASSIGN_PHONE_NUMBER_SUCCESS: 'ASSIGN_PHONE_NUMBER_SUCCESS',
  ASSIGN_PHONE_NUMBER_FAILURE: 'ASSIGN_PHONE_NUMBER_FAILURE',

  ASSIGN_PHONE_NUMBER_FINISHED_REQUEST: 'ASSIGN_PHONE_NUMBER_FINISHED_REQUEST',
  ASSIGN_PHONE_NUMBER_FINISHED_SUCCESS: 'ASSIGN_PHONE_NUMBER_FINISHED_SUCCESS',
  ASSIGN_PHONE_NUMBER_FINISHED_FAILURE: 'ASSIGN_PHONE_NUMBER_FINISHED_FAILURE',
};

export const actions = {
  loadUserState: () => dispatch => {
    return userApi
      .getState()
      .then(response => {
        if (response.ok && response.data.success !== false) {
          if (response.data.profile.preferredLanguage) i18n.changeLanguage(response.data.profile.preferredLanguage);

          return dispatch({ type: types.GET_USER_STATE_SUCCESS, userState: response.data });
        }

        dispatch({type: types.GET_USER_STATE_FAILURE});
        dispatch(notificationActions.showError(i18n.t('dashboard.actions.loadUserStateFailure')));
        return logger.error(response);
      });
  },
  updateDetails: data => dispatch => {
    return userApi
      .update(data)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch(notificationActions.showSuccess(i18n.t('userProfile.actions.updateUserProfileSuccess')));
          return dispatch({ type: types.UPDATE_USER_PROFILE_SUCCESS, userDetails: data });
        }
        if (response.ok && response.data.success === false) {
          if (response.data.failure.errorCode === 'errorCode.userManagement.mobileLogin.emailExist'){
            dispatch({ type: types.UPDATE_USER_PROFILE_FAILURE });
            dispatch(notificationActions.showError(i18n.t('signIn.actions.emailExist')));
            return logger.error(response);
          }
        }
        dispatch({type: types.UPDATE_USER_PROFILE_FAILURE});
        dispatch(notificationActions.showError(i18n.t('userProfile.actions.updateUserProfileFailure')));
        return logger.error(response);
      });
  },
  updatePreferredLanguage: language => dispatch => {
    return userApi
      .updatePreferredLanguage(language)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.UPDATE_USER_PREFERRED_LANGUAGE_SUCCESS, languageCode: language });
          dispatch(walletActions.loadWallet());
          return dispatch(notificationActions.showSuccess(i18n.t('userProfile.actions.updateUserProfileSuccess')));
        }

        dispatch(notificationActions.showError(i18n.t('userProfile.actions.updateUserProfileFailure')));
        return logger.error(response);
      });
  },
  resetAssignPhoneNumber: () => dispatch => {
    dispatch({ type: types.RESET_ASSIGN_PHONE_NUMBER });
  },
  assignPhoneNumber: (phoneNumberPrefix, phoneNumber, reCaptchaToken) => dispatch => {
    dispatch({ type: types.ASSIGN_PHONE_NUMBER_REQUEST, phoneNumber, phoneNumberPrefix });
    return userApi
      .assignPhoneNumber(phoneNumberPrefix, phoneNumber, reCaptchaToken)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.ASSIGN_PHONE_NUMBER_SUCCESS });
          dispatch(walletActions.loadWallet());
          return dispatch(notificationActions.showSuccess(i18n.t('assignPhoneNumber.assignForm.sentSMSCode')));
        }
        dispatch({ type: types.ASSIGN_PHONE_NUMBER_FAILURE });
        if(response.data && response.data.failure && response.data.failure.errorCode) {
          dispatch(notificationActions.showError(i18n.t(response.data.failure.errorCode)));
        }
        else {
          dispatch(notificationActions.showError(i18n.t('assignPhoneNumber.assignForm.requestSmsCodeFailure')));
        }
      });
  },
  assignPhoneNumberVerificationCode: (code) => dispatch => {
    dispatch({ type: types.ASSIGN_PHONE_NUMBER_FINISHED_REQUEST});
    return userApi
      .verifyAssignPhoneNumberCode(code)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.ASSIGN_PHONE_NUMBER_FINISHED_SUCCESS });
          dispatch(walletActions.loadWallet());
          return dispatch(notificationActions.showSuccess(i18n.t('assignPhoneNumber.verificationForm.successAssignPhoneNumber')));
        }
        dispatch({ type: types.ASSIGN_PHONE_NUMBER_FINISHED_FAILURE });
        if(response.data && response.data.failure && response.data.failure.errorCode) {
          dispatch(notificationActions.showError(i18n.t(response.data.failure.errorCode)));
        }
        else {
          dispatch(notificationActions.showError(i18n.t('assignPhoneNumber.verificationForm.requestVerificationSMSCodeFailure')));
        }
      });
  }
};
