import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Typography, Card, CardHeader, CardContent, CardActions} from '@material-ui/core';
import PurchasePlanDialog from './PurchasePlanDialog';
import { actions as plansActions } from '../../actions/plansActions';
import { actions as termsAndConditionsActions } from '../../actions/termsAndConditionsActions';
import numberFormatter from '../../utils/numberFormatter';
import i18n from '../../i18n';

class PlanDetailsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseDialogVisible: false,
      submitting: false,
    };

    this.handleStartPurchase = this.handleStartPurchase.bind(this);
    this.handleSubmitPurchase = this.handleSubmitPurchase.bind(this);
    this.handleCancelPurchase = this.handleCancelPurchase.bind(this);
  }

  handleStartPurchase() {
    if (this.props.termsAcceptanceRequired) {
      this.props.termsActions.showDialog(this.props.plan.tenant);
      return;
    }

    this.setState({ purchaseDialogVisible: true });
  }

  handleSubmitPurchase(planId) {
    this.setState({ submitting: true });
    this.props.actions.buy(planId)
      .then(() => this.setState({ purchaseDialogVisible: false, submitting: false }));
  }

  handleCancelPurchase() {
    this.setState({ purchaseDialogVisible: false });
  }

  render() {
    const { plan, disabled, insufficientFunds } = this.props;

    return (
      <React.Fragment>
        <Card>
          <CardHeader title={plan.name} subheader={plan.description} />
          <CardContent>
            <Typography variant="subtitle1">
              <strong>{numberFormatter.format(plan.price)}</strong> zł {i18n.t('plans.planDetailsCard.validity', {validity: i18n.t(`plans.validity.${plan.validity}`).toLowerCase()})}
            </Typography>
            {plan.freeMinutesPerDay && <Typography variant="subtitle1">
              {i18n.t('plans.planDetailsCard.freeMinutesPerDay')} <strong>{plan.freeMinutesPerDay}</strong>
            </Typography>}
          </CardContent>
          <CardActions disableSpacing>
            <Button color="secondary" onClick={this.handleStartPurchase} disabled={disabled || insufficientFunds}>
              {i18n.t('plans.planDetailsCard.chooseButton')}
            </Button>
            {insufficientFunds && <Typography variant="caption" color="error" display="block">
              {i18n.t('plans.planDetailsCard.insufficientFunds')}
            </Typography>}
          </CardActions>
        </Card>
        <PurchasePlanDialog
          plan={plan}
          open={this.state.purchaseDialogVisible}
          onSubmit={this.handleSubmitPurchase}
          onCancel={this.handleCancelPurchase}
          submitting={this.state.submitting}
        />
      </React.Fragment>
    );
  }
}

PlanDetailsCard.propTypes = {
  plan: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  termsActions: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  insufficientFunds: PropTypes.bool,
  termsAcceptanceRequired: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(plansActions, dispatch),
    termsActions: bindActionCreators(termsAndConditionsActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(PlanDetailsCard);
