import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/walletActions';
import { WalletModel } from '../models/WalletModel';

export default function walletReducer(state = Immutable(initialState.wallet), action) {
   switch (action.type) {
    case types.GET_WALLET:
      return state.merge({ fetching: true, error: null });
    case types.GET_WALLET_SUCCESS: {
      const { balance, availableToWithdraw, walletEmail, latestTopUps, rewards, refunds,
        withdrawals, payments, automaticWithdrawalInfos, withdrawalFromForm, automaticWithdrawal } = action.wallet;
      return state.merge({ fetching: false, error: null, data: new WalletModel(balance, availableToWithdraw, walletEmail,
          latestTopUps, rewards, refunds, withdrawals, payments, automaticWithdrawalInfos, withdrawalFromForm,
          automaticWithdrawal) });
    }
    case types.GET_WALLET_FAILURE:
      return state.merge({ fetching: false, error: true });
    case types.TOP_UP_SUCCESS: {
      window.location.href = action.paymentUrl;
      return state.merge({ fetching: true });
    }
     case types.CONFIRM_AUTOMATIC_WITHDRAW_INFO_SUCCESS:
     case types.CONFIRM_AUTOMATIC_WITHDRAW_INFO_FAILURE: {
       const automaticWithdrawalInfos = state.data.automaticWithdrawalInfos.filter(x => x.id !== action.id)
       return state.merge({
         data: new WalletModel(state.data.balance, state.data.availableToWithdrawal,
           state.data.walletEmail, state.data.latestTopUps, state.data.rewards, state.data.refunds,
           state.data.withdrawals, state.data.payments, automaticWithdrawalInfos)
       });
     }
    default:
      return state;
  }
}
