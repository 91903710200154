import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/termsAndConditionsActions';

export default function operatorsReducer(state = Immutable(initialState.operators), action) {
   switch (action.type) {
    case types.GET_TENANTS:
      return state.merge({ fetching: true, error: null });
    case types.GET_TENANTS_SUCCESS:
      return state.merge({ fetching: false, error: null, data: action.tenants });
    case types.GET_TENANTS_FAILURE:
      return state.merge({ fetching: false, error: true });
    default:
      return state;
  }
}
