import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions  from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TopUpForm from './TopUpForm';

const TopUpDialog = ({ open, onSubmit, onCancel, dispatch, submitting }) => {
  const [t] = useTranslation();
  return (
    <Dialog open={open}>
      <DialogTitle>{t('wallet.dialog.title')}</DialogTitle>
      <DialogContent>
        <TopUpForm onSubmit={onSubmit} />
        <DialogContentText>
          <Typography variant="caption">
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={submitting}>
          {t('common.cancelButton')}
        </Button>
        <Button onClick={() => dispatch(submit('topUp'))} disabled={submitting} color="secondary">
          {t('wallet.dialog.payButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TopUpDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
};

export default connect()(TopUpDialog);
