export const BikeParts = {
  basket: { value: 'basket' },
  bell: { value: 'bell' },
  brakes: { value: 'brakes' },
  chain: { value: 'chain' },
  drive: { value: 'drive' },
  handlebar: { value: 'handlebar' },
  lights: { value: 'lights' },
  lock: { value: 'lock' },
  other: { value: 'other' },
  qr: { value: 'qr' },
  seat: { value: 'seat' },
  wheel: { value: 'wheel' },
  phoneHolder: { value: 'phoneHolder' },
  unusable: { value: 'unusable' },
  vandalism: { value: 'vandalism' },
  kickstand: { value: 'kickstand' },
  childSeat: { value: 'childSeat' },
};
