import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment  from 'moment';
import { Card, CardMedia, CardContent, Typography, Grid, Divider} from '@material-ui/core';
import { actions as cardsActions } from '../../actions/cardsActions';
import { CardStatuses } from '../../models/CardsModel';
import ConfirmationButton from '../common/ConfirmationButton';
import i18n from '../../i18n';

class AssignedCardDetails extends Component {
  state = {
    submitting: false,
  };

  handleRemoveCard = () => {
    this.setState({ submitting: true });
    this.props.actions.remove(this.props.card.cardId)
      .then(() => this.setState({ submitting: false }));
  }

  handleCancelAssignCard() {
    this.setState({ assignDialogVisible: false });
  }
  render() {
    const { card } = this.props;

    return (
      <React.Fragment>
        <Card elevation={0} style={{background: 'transparent'}}>
          <CardContent>
            <Card elevation={5}>
              <CardMedia image={card.imageUrl} style={{height: 0, paddingTop: '50%'}} />
            </Card>
            {card.tenants.map(tenant => (
              <React.Fragment key={tenant.tenantId}>
                <Typography variant="subtitle1" style={{marginTop: 20}} display="block">{tenant.name}</Typography>
                <Typography variant="caption" color="textSecondary" display="block">{tenant.description}</Typography>
              </React.Fragment>
            ))}
            <Divider style={{marginTop: 8, marginBottom: 8}} />
            <Grid container justify="space-between" alignItems="flex-end">
              <Grid item>
                <Typography variant="caption" display="block">{i18n.t('cards.cardNumberLabel')} {card.cardNumber}</Typography>
                <Typography variant="caption" style={{color: CardStatuses[card.status].color}} display="block">{i18n.t(`cards.cardStatuses.${card.status}`)}</Typography>
                <Typography variant="caption" display="block">{i18n.t('cards.addedAtLabel')} <strong>{moment(card.addedAt).format('DD.MM.YYYY HH:mm')}</strong></Typography>
                {card.activatedAt && <Typography variant="caption" display="block">{i18n.t('cards.activatedAtLabel')} <strong>{moment(card.activatedAt).format('DD.MM.YYYY HH:mm')}</strong></Typography>}
              </Grid>
              <Grid item>
                <ConfirmationButton
                  color="default"
                  size="small"
                  disabled={this.state.submitting}
                  onConfirm={this.handleRemoveCard}
                  confirmLabel={i18n.t('cards.removeButton')}
                  cancelLabel={i18n.t('common.cancelButton')}
                  confirmationText={i18n.t('cards.removeConfirmationText')}
                  confirmationTitle={i18n.t('cards.removeConfirmationTitle')}
                >
                  {i18n.t('cards.removeButton')}
                </ConfirmationButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

AssignedCardDetails.propTypes = {
  card: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(cardsActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(AssignedCardDetails);
