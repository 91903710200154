import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/ridesActions';
import { HistoricalRideModel } from '../models/HistoricalRideModel';

export default function ridesHistoryReducer(state = Immutable(initialState.ridesHistory), action) {
  switch (action.type) {
    case types.GET_RIDES: {
      const { pageNumber, pageSize } = action;
      return state.merge({ fetching: true, pageNumber, pageSize });
    }
    case types.GET_RIDES_SUCCESS: {
      const { pageNumber, pageSize, totalDataCount, data } = action;
      return state.merge({
        fetching: false,
        error: null,
        pageNumber,
        pageSize,
        totalDataCount,
        data: data.map(ride => new HistoricalRideModel(ride))
      });
    }
    case types.GET_RIDES_FAILURE:
      return state.merge({ fetching: true });
    case types.GET_RIDE: {
      return state.merge({ fetching: true, details: {} });
    }
    case types.GET_RIDE_SUCCESS: {
      const { cached, ride } = action;
      if (cached) return state.merge({ fetching: false, details: cached });
      return state.merge({ fetching: false, details: new HistoricalRideModel(ride) });
    }
    case types.GET_RIDE_FAILURE:
      return state.merge({ fetching: true });
    default:
      return state;
  }
}
