import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import NativeFormTextField from '../form/NativeFormTextField';
import { smsCodeVerificationFormValidation } from '../../models/auth/AuthModelValidation';
import './SmsCodeVerificationForm.scss';
import {LoginMode} from "../../models/auth/AuthModel";

let SmsCodeVerificationForm = ({ handleSubmit, submitting, phoneNumber, email, loginMode }) => {
  const [t] = useTranslation();

  const checkLoginMode = (mode) => {
    return loginMode === mode;
  }

  return (
    <form onSubmit={handleSubmit}>
      {checkLoginMode(LoginMode.sms) && <p>{t('smsCodeVerification.form.header', {phoneNumber})}</p>}
      {checkLoginMode(LoginMode.email) && <p>{t('smsCodeVerification.form.headerEmail', {email})}</p>}
      <div className="form-group">
        <Field
          name="code"
          type="number"
          component={NativeFormTextField}
          label={t('smsCodeVerification.form.codeLabel')}
          autoFocus
        />
      </div>
      <button type="submit" className="btn btn-default" disabled={submitting}>
        {submitting ? t('smsCodeVerification.form.submitButtonProgress') : t('smsCodeVerification.form.submitButton')}
      </button>
    </form>
  );
};

SmsCodeVerificationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  phoneNumber: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  loginMode: PropTypes.string.isRequired,
};

SmsCodeVerificationForm = reduxForm({
  form: 'smsCodeVerification',
  validate: smsCodeVerificationFormValidation,
})(SmsCodeVerificationForm);

export default SmsCodeVerificationForm;
