import React, { useCallback, useEffect } from 'react';
import { change } from 'redux-form';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';


const FormCaptcha = ({ formName, action }) => {
  const dispatch = useDispatch();

  const onChange = (token) => {
    dispatch(change(formName, 'reCaptchaToken', token));
  };
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha(action);
    onChange(token);
  }, [executeRecaptcha, action, formName]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <React.Fragment></React.Fragment>
  );
};

FormCaptcha.propTypes = {
  formName: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};

export default FormCaptcha;
