import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import i18n from '../../i18n';

const changeLanguage = lng => i18n.changeLanguage(lng);

const styles = theme => ({
  select: {
    fontSize: theme.typography.body1.fontSize,
  },
});

const LanguagePicker = ({ classes, onChange, selectedLanguage }) => (
  <FormControl>
    <Select
      value={selectedLanguage || 'pl'}
      onChange={event => {
        onChange(event.target.value);
        changeLanguage(event.target.value)
      }}
      className={classes.select}
      inputProps={{
        name: 'language',
        id: 'language-picker',
      }}
    >
      <MenuItem value="pl">polski</MenuItem>
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="de">Deutsch</MenuItem>
      <MenuItem value="ru">Русский</MenuItem>
      <MenuItem value="cs">Čeština</MenuItem>
      <MenuItem value="lt">Lietuvių</MenuItem>
      <MenuItem value="uk">україньска</MenuItem>
    </Select>
  </FormControl>
);

LanguagePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string,
};

export default withStyles(styles)(LanguagePicker);
