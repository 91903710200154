import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-google-maps';
import defaultBikeAvailableIcon from '../../assets/bikeAvailableIcon.png';

const BikeMarker = ({ bike, isSelected, onSelect, ...other }) => {
  const getIcon = bikeIconUrl => {
    return {
      url: bikeIconUrl,
      /* eslint-disable no-undef */
      scaledSize: new google.maps.Size(32, 32)
    };
  };

  return (
    <Marker
      icon={getIcon(bike.icon || defaultBikeAvailableIcon)}
      position={bike.location}
      title={`Rower: ${bike.bikeNumber}`}
      {...other}
      onClick={() => onSelect && onSelect(bike)}
      animation={isSelected && window.google.maps.Animation.BOUNCE}
    />
  );
};

BikeMarker.propTypes = {
  bike: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default BikeMarker;
