import apiBase from './apiBase';

class PlansApi {
  static get() {
    return apiBase.create().get('/plans');
  }

  static buy(planId) {
    return apiBase.create().post(`/plans/${planId}/buy`);
  }
}

export default PlansApi;
