import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { actions as authActions } from '../../../actions/authActions';
import NewPhoneNumberForm from './NewPhoneNumberForm';
import SmsCodeVerificationForm from '../SmsCodeVerificationForm';
import AnonymousContentPanel from '../AnonymousContentPanel';
import queryString from 'query-string';
import i18n from '../../../i18n';

class SetNewPhoneNumberPage extends Component {
  constructor(props) {
    super(props);
    this.state = { tokenExpired: false };
  }

  componentDidMount() {
    const { token } = this.props;
    const decoded = jwtDecode(token);
    const tokenExpiresAt = moment.unix(decoded.exp);
    this.setState({ tokenExpired: tokenExpiresAt < moment() });
  }

  render() {
    const { auth, token, authActions } = this.props;
    const { tokenExpired } = this.state;
    return (<DocumentTitle title="ROOVEE">
      <AnonymousContentPanel>
        {tokenExpired && <span>{i18n.t('changePhoneNumber.tokenExpired')}</span>}
        {!tokenExpired && !auth.newPhoneNumberVerificationInProgress && <NewPhoneNumberForm
          onSubmit={data => authActions.startNewPhoneNumberVerification(Object.assign({}, data, { token }))} />}
        {!tokenExpired && auth.newPhoneNumberVerificationInProgress && <SmsCodeVerificationForm
          onSubmit={data => authActions.completeNewPhoneNumberVerification(data.code, token)}
          phoneNumber={auth.newPhoneNumber} />}
      </AnonymousContentPanel>
    </DocumentTitle>);
  }
}

SetNewPhoneNumberPage.propTypes = {
  authActions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

function mapStateToProps(state, ownProps) {
  const query = queryString.parse(ownProps.location.search);
  return {
    auth: state.auth,
    token: query.token,
    lang: query.lang,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetNewPhoneNumberPage);
