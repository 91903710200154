import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import FormTextField from '../form/FormTextField';
import { userProfileFormValidation } from '../../models/UserProfileValidation';

let UpdateProfileForm = ({ handleSubmit }) => {
  const [t] = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="firstName"
          component={FormTextField}
          label={t('userProfile.form.firstNameLabel')}
          autoFocus
          fullWidth
        />
      </div>
      <div>
        <Field
          name="lastName"
          component={FormTextField}
          label={t('userProfile.form.lastNameLabel')}
          fullWidth
        />
      </div>
      <div>
        <Field
          name="email"
          type="email"
          component={FormTextField}
          label={t('userProfile.form.emailLabel')}
          fullWidth
        />
      </div>
    </form>
  );
};

UpdateProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

UpdateProfileForm = reduxForm({
  form: 'userProfile',
  validate: userProfileFormValidation,
})(UpdateProfileForm);

export default UpdateProfileForm;