import i18n from '../i18n';

export const topUpStatus = {
  created: { value: 'created', name: 'walletHistory.topUpStatus.created' },
  pending: { value: 'pending', name: 'walletHistory.topUpStatus.pending' },
  success: { value: 'success', name: 'walletHistory.topUpStatus.success' },
  failed: { value: 'failed', name: 'walletHistory.topUpStatus.failed' },
};

export const topUpTypes = {
  online: 'online',
  external: 'external',
};

export const balanceItemType = {
  onlineTopUp: { value: 'onlineTopUp', name: 'walletHistory.balanceItemType.onlineTopUp' },
  externalTopUp: { value: 'externalTopUp', name: 'walletHistory.balanceItemType.externalTopUp' },
  reward: { value: 'reward', name: 'walletHistory.balanceItemType.reward' },
  refund: { value: 'refund', name: 'walletHistory.balanceItemType.refund' },
  withdrawal: { value: 'withdrawal', name: 'walletHistory.balanceItemType.withdrawal' },
  payment: { value: 'payment', name: 'walletHistory.balanceItemType.payment' },
  automaticWithdrawal: { value: 'automaticWithdrawal', name: 'walletHistory.balanceItemType.automaticWithdrawal' },
  withdrawalRefundForm: { value: 'withdrawalRefundForm', name: 'walletHistory.balanceItemType.withdrawalRefundForm' },
};

export class WalletOnlineTopUpModel {
  constructor({ amount, date, status }) {
    this.type = balanceItemType.onlineTopUp;
    this.amount = amount;
    this.date = date;
    this.status = topUpStatus[status.toLowerCase()];
    this.getDescription = () => i18n.t(this.status.name);
  }
}

export class WalletExternalTopUpModel {
  constructor({ amount, date }) {
    this.type = balanceItemType.externalTopUp;
    this.amount = amount;
    this.date = date;
    this.getDescription = () => null;
  }
}

export class WalletRewardModel {
  constructor({ amount, date, fromTenant }) {
    this.type = balanceItemType.reward;
    this.amount = amount;
    this.date = date;
    this.fromTenant = fromTenant;
    this.getDescription = () => this.fromTenant;
  }
}

export class WalletRefundModel {
  constructor({ amount, date, fromTenant }) {
    this.type = balanceItemType.refund;
    this.amount = amount;
    this.date = date;
    this.fromTenant = fromTenant;
    this.getDescription = () => this.fromTenant;
  }
}

export class WalletWithdrawalRefundFormModel {
  constructor({ amount, date }) {
    this.type = balanceItemType.withdrawalRefundForm;
    this.amount = amount;
    this.date = date;
    this.getDescription = () => null;
  }
}

export class WalletAutomaticWithdrawalModel {
  constructor({ amount, date }) {
    this.type = balanceItemType.automaticWithdrawal;
    this.amount = amount;
    this.date = date;
    this.getDescription = () => null;
  }
}

export class WalletWithdrawalModel {
  constructor({ amount, date }) {
    this.type = balanceItemType.withdrawal;
    this.amount = -amount;
    this.date = date;
    this.getDescription = () => null;
  }
}

export class WalletPaymentModel {
  constructor({ amount, date, tags, toTenant }) {
    this.type = balanceItemType.payment;
    this.amount = -amount;
    this.date = date;
    this.tags = tags;
    this.toTenant = toTenant;
    if (tags.rentalId) {
      this.getDescription = () => `${this.toTenant} - `;
    }
    if (tags.planId) {
      this.getDescription = () => `${this.toTenant} - ${i18n.t('walletHistory.planPurchase')}`;
    }
  }
}

export class WalletModel {
  constructor(balance, availableToWithdrawal, walletEmail, latestTopUps, rewards, refunds, withdrawals, payments,
              automaticWithdrawalInfos, withdrawalFromForm, automaticWithdrawal) {
    this.balance = balance || null;
    this.availableToWithdrawal = availableToWithdrawal || null;
    this.walletEmail = walletEmail;
    this.latestTopUps = (latestTopUps && latestTopUps.map(p => p.type === topUpTypes.online
      ? new WalletOnlineTopUpModel(p)
      : new WalletExternalTopUpModel(p))) || [];
    this.rewards = (rewards && rewards.map(p => new WalletRewardModel(p))) || [];
    this.refunds = (refunds && refunds.map(p => new WalletRefundModel(p))) || [];
    this.withdrawals = (withdrawals && withdrawals.map(p => new WalletWithdrawalModel(p))) || [];
    this.payments = (payments && payments.map(p => new WalletPaymentModel(p))) || [];
    this.automaticWithdrawalInfos = automaticWithdrawalInfos || [];
    this.automaticWithdrawal = (automaticWithdrawal && automaticWithdrawal.map(p => new WalletAutomaticWithdrawalModel(p))) || [];
    this.withdrawalFromForm = (withdrawalFromForm && withdrawalFromForm.map(p => new WalletWithdrawalRefundFormModel(p))) || [];
  }
}
