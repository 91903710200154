import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import NativeFormTextField from '../../form/NativeFormTextField';
import NativeFormSelect from '../../form/NativeFormSelect';
import PhoneNumberPrefixes from '../../../models/PhoneNumberPrefixes'
import { requestPhoneNumberChangeFormValidation } from '../../../models/auth/AuthModelValidation';
import './RequestPhoneNumberChangeForm.scss';

let RequestPhoneNumberChangeForm = ({ handleSubmit, submitting }) => {
  const [t] = useTranslation();
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <Field
          name="phoneNumberPrefix"
          component={NativeFormSelect}
          label={t('signIn.form.countryLabel')}
          values={PhoneNumberPrefixes.map(p => ({
            value: p.prefix,
            key: p.countryCode,
            name: `${p.prefix} ${t(`countries.${p.countryCode}`)}`,
          }))
          }
        />
      </div>
      <div className="form-group">
        <Field
          name="phoneNumber"
          type="number"
          component={NativeFormTextField}
          label={t('signIn.form.phoneNumberLabel')}
          fullWidth
          autoFocus
        />
      </div>
      <div className="form-group">
        <Field
          name="email"
          type="email"
          component={NativeFormTextField}
          label={t('changePhoneNumber.request.emailLabel')}
          fullWidth
        />
      </div>
      <button type="submit" className="btn btn-default" disabled={submitting}>
        {submitting ? t('signIn.form.submitButtonProgress') : t('signIn.form.submitButton')}
      </button>
    </form>
  );
};

RequestPhoneNumberChangeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

RequestPhoneNumberChangeForm = reduxForm({
  form: 'requestPhoneNumberChangeForm',
  validate: requestPhoneNumberChangeFormValidation,
  initialValues: { phoneNumberPrefix: '+48' }
})(RequestPhoneNumberChangeForm);

export default RequestPhoneNumberChangeForm;
