import React from 'react';
import PropTypes from 'prop-types';

const ErrorNotification =  ({message}) => {
  return (
    <div>
      <span>{message}</span>
    </div>
  );
};

ErrorNotification.propTypes = {
  message: PropTypes.string.isRequired
};

export default ErrorNotification;
