import apiBase from './apiBase';

class TermsAndConditionsApi {
  static getTenants() {
    return apiBase.create().get('/tenants');
  }

  static getTermsAndConditions(tenant) {
    return apiBase.create().get(`/terms/${tenant}`);
  }

  static acceptTermsAndConditions(tenant, version) {
    return apiBase.create().post(`/terms/${tenant}/${version}/accept`);
  }
}

export default TermsAndConditionsApi;
