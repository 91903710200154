import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import moment from 'moment';
import numberFormatter from '../../utils/numberFormatter';
import _ from 'lodash';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

const styles = () => ({
  tableRow: {
    cursor: 'pointer',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  dataCell: {
    whiteSpace: 'nowrap',
  }
});

class RidesListTable extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangePageNumber = this.handleChangePageNumber.bind(this);
    this.handleChangePageSize = this.handleChangePageSize.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  handleChangePageNumber = (event, pageNumber) => {
    this.props.onFetchRides(this.props.pageSize, pageNumber + 1)
  };

  handleChangePageSize = event => {
    const newMaxPageNumber = Math.ceil(this.props.totalRidesCount / event.target.value);
    this.props.onFetchRides(event.target.value, _.min([this.props.pageNumber, newMaxPageNumber]));
  };

  handleRowClick = rideId => {
    if (window.getSelection().type === 'Range') return;
    this.props.onOpenDetails(rideId);
  };

  render() {
    const { i18n, classes, rides, pageSize, pageNumber, totalRidesCount } = this.props;
    const emptyRows = pageSize - rides.length;
    return (
      <Paper>
        <div className={classes.tableWrapper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{i18n.t('rides.table.headers.rideDate')}</TableCell>
                <TableCell padding="none" align="right">{i18n.t('rides.table.headers.rideDuration')}</TableCell>
                <TableCell align="right">{i18n.t('rides.table.headers.rideDistance')}</TableCell>
                <TableCell padding="none">{i18n.t('rides.table.headers.rideBikeNumber')}</TableCell>
                <TableCell align="right">{i18n.t('rides.table.headers.rideFare')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rides.map(ride => {
                return (
                  <TableRow
                    hover
                    key={ride.id}
                    className={classes.tableRow}
                    onClick={() => this.handleRowClick(ride.id)}
                  >
                    <TableCell className={classes.dataCell}>
                      {moment(ride.startedAt).format('LLL')}
                    </TableCell>
                    <TableCell className={classes.dataCell} padding="none" align="right">
                      {ride.duration}
                    </TableCell>
                    <TableCell align="right">
                      {ride.traceIsAccurate
                        ? <span>{numberFormatter.format(ride.traceLengthInMeters / 1000)}&nbsp;km</span>
                        : '-'}
                    </TableCell>
                    <TableCell className={classes.dataCell} padding="none">
                      {ride.bikeNumber}
                    </TableCell>
                    <TableCell align="right">
                      {numberFormatter.format(ride.fare)}&nbsp;zł
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              {rides.length > 0 && <TableRow>
                <TablePagination
                  colSpan={5}
                  count={totalRidesCount}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[10, 25, 50]}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${i18n.t('rides.table.pagingSummary.displayedRowsFrom')} ${count}`}
                  labelRowsPerPage={i18n.t('rides.table.pagingSummary.rowsPerPage')}
                  page={pageNumber - 1}
                  onChangePage={this.handleChangePageNumber}
                  onChangeRowsPerPage={this.handleChangePageSize}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>}
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

RidesListTable.propTypes = {
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  onFetchRides: PropTypes.func.isRequired,
  onOpenDetails: PropTypes.func.isRequired,
  rides: PropTypes.array.isRequired,
  totalRidesCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default withStyles(styles)(RidesListTable);
