import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/zonesActions';

export default function zonesReducer(state = Immutable(initialState.zones), action) {
   switch (action.type) {
    case types.GET_ZONES:
      return state.merge({ fetching: true, error: null });
    case types.GET_ZONES_SUCCESS: {
      return state.merge({ fetching: false, error: null, data: action.zones });
    }
    case types.GET_ZONES_FAILURE:
      return state.merge({ fetching: false, error: true });
    default:
      return state;
  }
}
