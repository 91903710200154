import apiBase from './apiBase';

class RidesApi {
  static createReservation(bikeNumber, location) {
    return apiBase.create().post('/mobile/rides/createReservation', {
      bikeNumber,
      location,
    });
  }

  static cancelReservation(rideId) {
    return apiBase.create().post(`/mobile/rides/${rideId}/cancelReservation`);
  }

  static getRides(pageSize, pageNumber) {
    return apiBase.create().post('/mobile/myRides', { paging: { pageNumber, pageSize } });
  }

  static getRide(rideId) {
    return apiBase.create().get(`/mobile/myRides/${rideId}`);
  }
}

export default RidesApi;
