import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import i18n from '../../i18n';

const changeLanguage = lng => i18n.changeLanguage(lng);

class LanguagePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lang: i18n.language ? i18n.language.substring(0, 2) : 'pl' };
  }

  render() {
    const { dispatch } = this.props;
    const { lang } = this.state;
    return (<select className="form-control"
      value={lang}
      onChange={event => {
        dispatch(reset('signIn'));
        dispatch(reset('smsCodeVerification'));
        dispatch(reset('finishRegistration'));
        dispatch(reset('newPhoneNumber'));
        changeLanguage(event.target.value);
        this.setState({ lang: event.target.value });
      }}
    >
      <option value="pl">polski</option>
      <option value="en">English</option>
      <option value="de">Deutsch</option>
      <option value="ru">Русский</option>
      <option value="cs">Čeština</option>
      <option value="lt">Lietuvių</option>
      <option value="uk">україньска</option>
    </select>);
  }
}

LanguagePicker.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(LanguagePicker);
