import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import BikePartsPicker from '../bikeDefects/BikePartsPicker';

const FormBikePartsPicker = ({
  input,
  label,
  meta: { touched, error },
}) => (
  <React.Fragment>
    <Typography variant="caption" gutterBottom style={{marginTop: 16}}>{label}</Typography>
    {(touched && error) && <Typography color="error" variant="caption" gutterBottom>{error}</Typography>}
    <BikePartsPicker onChange={parts => input.onChange(parts)} />
  </React.Fragment>
);

FormBikePartsPicker.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
};

export default FormBikePartsPicker;
