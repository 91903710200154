import React from 'react';
import PropTypes from 'prop-types';

const NativeFormSelect = ({
  input,
  label,
  values,
  ...custom
}) => (
  <div>
    <label>{label}</label>
    <select
      className="form-control"
      value={input.value}
      onChange={event => input.onChange(event.target.value)}
      {...custom}
    >
      {values.map(item => (
        <option key={item.key} value={item.value}>{item.name}</option>
      ))}
    </select>
  </div>
);

NativeFormSelect.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  values: PropTypes.array
};

export default NativeFormSelect;
