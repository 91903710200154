import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import DocumentTitle from 'react-document-title';
import { actions } from '../../actions/termsAndConditionsActions';
import OperatorsList from './OperatorsList';
import i18n from '../../i18n';

class OperatorsPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.actions.getTenants();
  }
  
  render() {
    const { operators } = this.props;
    return (
      <DocumentTitle title={i18n.t('operators.title')}>
        <div className="page">
          <div style={{margin: 16}}>
            <Grid container justify="center">
              <Grid item xs={12} md={8}>
                <Typography variant="h4" gutterBottom>{i18n.t('operators.title')}</Typography>
                {operators.data && <OperatorsList operators={operators.data} />}
              </Grid>
            </Grid>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

OperatorsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  operators: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    operators: state.operators,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorsPage);
