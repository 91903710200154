import i18n from '../i18n';
import plansApi from '../api/plansApi';
import { actions as notificationActions } from './notificationActions';
import { actions as userActions } from './userActions';
import { actions as walletActions } from './walletActions';
import * as logger from '../utils/logger';

export const types = {
  GET_PLANS: 'GET_PLANS',
  GET_PLANS_SUCCESS: 'GET_PLANS_SUCCESS',
  GET_PLANS_FAILURE: 'GET_PLANS_FAILURE',
  BUY_PLAN: 'BUY_PLAN',
  BUY_PLAN_SUCCESS: 'BUY_PLAN_SUCCESS',
  BUY_PLAN_FAILURE: 'BUY_PLAN_FAILURE',
};

export const actions = {
  load: () => dispatch => {
    dispatch({ type: types.GET_PLANS });
    return plansApi
      .get()
      .then(response => {
        if (response.ok && response.data && response.data.success !== false) {
          return dispatch({ type: types.GET_PLANS_SUCCESS, plans: response.data });
        }
        if(response.ok && response.data && response.data.success === false ) {
          return dispatch({ type: types.GET_PLANS_SUCCESS, plans: [] });
        }

        dispatch({ type: types.GET_PLANS_FAILURE });
        dispatch(notificationActions.showError(i18n.t('plans.actions.loadPlansFailure')));
        return logger.error(response);
      });
  },

  buy: planId => dispatch => {
    dispatch({ type: types.BUY_PLAN });
    return plansApi
      .buy(planId)
      .then(response => {
        if (response.ok && response.data && response.data.success !== false) {
          dispatch({ type: types.BUY_PLAN_SUCCESS });
          dispatch(userActions.loadUserState());
          dispatch(walletActions.loadWallet());
          return;
        }

        dispatch({ type: types.BUY_PLAN_FAILURE });
        dispatch(notificationActions.showError(i18n.t('plans.actions.buyFailure')));
        return logger.error(response);
      });
  }
};
