import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

// make router not navigate when only hash was changed
let previousLocation;
const routerSetState = Router.prototype.setState;
Router.prototype.setState = function (...args) {

  const loc = this.props.history.location;
  if (previousLocation
    && loc.state && loc.state.dontNavigate
    && loc.pathname === previousLocation.pathname
    && loc.search === previousLocation.search
    && loc.hash !== previousLocation.hash
  ) {
    previousLocation = { ...loc };
    return;
  }

  previousLocation = { ...loc };
  return routerSetState.apply(this, args);
};
const routerDidMount = Router.prototype.componentDidMount;
Router.prototype.componentDidMount = function (...args) {
  previousLocation = {
    ...this.props.history.location,
  };
  if (typeof routerDidMount === 'function') {
    return routerDidMount.apply(this, args);
  }
};

export default createBrowserHistory();
