import i18n from '../../i18n';
const validEmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const phoneNumberFormValidation = values => {
  const errors = {};
  const requiredFields = [
    'phoneNumber',
    'terms',
    'conditions'
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = i18n.t('common.validation.required');
    }
  });

  return errors;
};

export const assignPhoneNumberFormValidation = values => {
  const errors = {};
  const requiredFields = [
    'phoneNumber',
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = i18n.t('common.validation.required');
    }
  });
  return errors;
};

export const assignPhoneNumberVerificationFormValidation = values => {
  const errors = {};

  if (!values.code) {
    errors.code = i18n.t('common.validation.required');
  }
  if (values.code && values.code.length !== 6) {
    errors.code = 'Kod musi składać się z 6 cyfr'
  }

  return errors;
};

export const emailFormValidation = values => {
  const errors = {};
  const requiredFields = [
    'email',
    'terms',
    'conditions'
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = i18n.t('common.validation.required');
    }
  });

  if (!values.email || !validEmailRegex.test(values.email)) {
    errors.email = i18n.t('common.validation.email');
  }
  return errors;
};

export const smsCodeVerificationFormValidation = values => {
  const errors = {};

  if (!values.code || values.code.length !== 6) {
    errors.code = i18n.t('smsCodeVerification.form.validation.code');
  }

  return errors;
};

export const finishRegistrationFormValidation = values => {
  const errors = {};
  const requiredFields = [
    'firstName',
    'lastName',
    'email',
    'termsAndConditions',
    'personalDataProcessing',
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = i18n.t('common.validation.required');
    }
  });

  if (!values.email || !validEmailRegex.test(values.email)) {
    errors.email = i18n.t('common.validation.email');
  }

  return errors;
};

export const requestPhoneNumberChangeFormValidation = values => {
  const errors = {};
  const requiredFields = [
    'phoneNumber',
    'email',
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = i18n.t('common.validation.required');
    }
  });

  if (!values.email || !validEmailRegex.test(values.email)) {
    errors.email = i18n.t('common.validation.email');
  }

  return errors;
};
