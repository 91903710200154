import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import DocumentTitle from 'react-document-title';
import HistoryTable from './HistoryTable';

const WalletHistoryPage = () => {
  const [t] = useTranslation();
  return (
    <DocumentTitle title={t('walletHistory.header')}>
      <div className="page">
        <div style={{margin: 16}}>
          <Grid container justify="center">
            <Grid item xs={12} md={8}>
              <Typography variant="h4" gutterBottom>{t('walletHistory.header')}</Typography>
              <HistoryTable />
            </Grid>
          </Grid>
        </div>
      </div>
    </DocumentTitle>
  );
}

export default WalletHistoryPage;
