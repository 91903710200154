import React, {useEffect} from 'react';
import DocumentTitle from 'react-document-title';
import {useSelector} from "react-redux";
import Oauth2Fetching from "./Oauth2Fetching";
import Oauth2Error from "./Oauth2Error";

const Oauth2SignIn = () => {


  const auth = useSelector(x => x.auth)

  useEffect(() => {
    if(auth.oauth2.url){
      window.location.href = auth.oauth2.url
    }
  }, [auth.oauth2])

  return (
    <DocumentTitle title="ROOVEE">
      <div>
        <Oauth2Fetching show={auth.oauth2.isFetching} />
        <Oauth2Error show={auth.oauth2.error} />
      </div>
    </DocumentTitle>
  );
}


export default Oauth2SignIn;


