import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/termsAndConditionsActions';
import { TermsAndConditionsModel } from '../models/TermsAndConditionsModel';

export default function termsAndConditionsReducer(state = Immutable(initialState.termsAndConditions), action) {
  switch (action.type) {
    case types.DISPLAY_DIALOG:
      return state.merge({ displayDialog: true });
    case types.HIDE_DIALOG:
      return state.merge({ displayDialog: false });
    case types.GET_TERMS:
      return state.merge({ fetching: true, error: null });
    case types.GET_TERMS_SUCCESS: {
      const { tenant, version, content, additionalImageUrl, personalDataProcessingClause } = action.terms;
      return state.merge({ fetching: false, error: null, data: new TermsAndConditionsModel(tenant, version, content, additionalImageUrl, personalDataProcessingClause), });
    }
    case types.GET_TERMS_FAILURE:
      return state.merge({ fetching: false, error: true });
    case types.ACCEPT_TERMS_SUCCESS:
      return state.merge({ displayDialog: false });
    default:
      return state;
  }
}
