export class PlanModel {
  constructor(id, name, description, price, validity, freeMinutesPerDay, tenant) {
    this.id = id || null;
    this.name = name || null;
    this.description = description || null;
    this.tenant = tenant || null;
    this.validity = validity || null;
    this.price = price || null;
    this.freeMinutesPerDay = freeMinutesPerDay || null;
  }
}

export const PlanValidityTypes = Object.freeze({
  daily: { value: 'daily' },
  monthly: { value: 'monthly' },
  quarterly: { value: 'quarterly' },
  annualy: { value: 'annualy' },
});
