import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

class ConfirmationButton extends React.Component {
  state = {
    isOpen: false
  };
  
  handleAction = () => this.setState({ isOpen: true });

  handleConfirm = () => {
    this.setState({ isOpen: false });
    this.props.onConfirm();
  }

  handleCancel = () => {
    this.setState({ isOpen: false });
    this.props.onCancel && this.props.onCancel();
  }

  render() {
    const {
      confirmationTitle,
      confirmationText,
      cancelLabel,
      confirmLabel,
      children,
      onConfirm, // eslint-disable-line no-unused-vars
      onCancel, // eslint-disable-line no-unused-vars
      ...other
    } = this.props;

    return (
      <React.Fragment>
        <Button
          onClick={this.handleAction}
          {...other}
        >
          {children}
        </Button>
        <Dialog open={this.state.isOpen} onClose={this.handleCancel}>
          <DialogTitle>{confirmationTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmationText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel}>
              {cancelLabel}
            </Button>
            <Button onClick={this.handleConfirm} color="secondary">
              {confirmLabel}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ConfirmationButton.propTypes = {
  children: PropTypes.node.isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmationText: PropTypes.string.isRequired,
  confirmationTitle: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  disabled: PropTypes.bool,
};

ConfirmationButton.defaultProps = {
  confirmLabel: 'Ok',
  cancelLabel: 'Anuluj',
};

export default ConfirmationButton;
