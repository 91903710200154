import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import AnonymousContentPanel from "../auth/AnonymousContentPanel";
import i18n from "../../i18n";
import {bindActionCreators} from "redux";
import PropTypes from 'prop-types';
import {useParams} from "react-router";
import {actions as userCampaignActions} from "../../actions/userCampaignActions";


const CampaignUnsubscribePage = ({campaignActions, userCampaign}) => {
  const {userId} = useParams();


  useEffect(() => {
    if(userId) {
      campaignActions.unsubscribeNewsletterCampaign(userId);
    }
  }, [])

  return (
    <DocumentTitle title="ROOVEE">
      <AnonymousContentPanel>
        {!userCampaign.subscribeFetching && !userCampaign.subscribeError &&
          <div style={{fontWeight: 700}}>{i18n.t('campaign.unsubscribe.success')}</div>
        }
        {!userCampaign.subscribeFetching && userCampaign.subscribeError &&
          <div style={{fontWeight: 700}}>{i18n.t('campaign.unsubscribe.error')}</div>
        }
       </AnonymousContentPanel>
    </DocumentTitle>
  );
}

CampaignUnsubscribePage.propTypes = {
  campaignActions: PropTypes.object.isRequired,
  userCampaign: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    campaignActions: bindActionCreators(userCampaignActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    userCampaign: state.userCampaign || {},
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignUnsubscribePage);
