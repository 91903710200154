import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Button, Divider, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { actions as walletActions } from '../../actions/walletActions';
import numberFormatter from '../../utils/numberFormatter';
import TopUpDialog from './TopUpDialog';
import routePaths from '../../routePaths';
import i18n from '../../i18n';

const styles = () => ({
  paper: {
    padding: 16,
    background: `linear-gradient(${lightBlue[300]}, ${lightBlue[600]})`,
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column'
  },
});

class Wallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topUpDialogVisible: false,
      submitting: false,
    };

    this.handleStartTopUp = this.handleStartTopUp.bind(this);
    this.handleSubmitTopUp = this.handleSubmitTopUp.bind(this);
    this.handleCancelTopUp = this.handleCancelTopUp.bind(this);
    this.handleMoveToWithdrawalFundsPage = this.handleMoveToWithdrawalFundsPage.bind(this);
  }

  handleStartTopUp() {
    this.setState({ topUpDialogVisible: true });
  }

  handleMoveToWithdrawalFundsPage() {
    this.props.history.push(routePaths.withdrawalFunds);
  }

  handleSubmitTopUp(data) {
    this.setState({ submitting: true });
    this.props.actions.topUp(data.amount, this.props.userEmail)
      .then(() => this.setState({ topUpDialogVisible: false, submitting: false }));
  }

  handleCancelTopUp() {
    this.setState({ topUpDialogVisible: false });
  }

  render() {
    const { wallet, classes  } = this.props;

    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Typography variant="h6" style={{ color: '#fff' }} gutterBottom>{i18n.t('wallet.header')}</Typography>
          <Typography align="center" variant="h3" style={{ color: '#fff', flexGrow: 1 }}>
            {numberFormatter.format(wallet.balance)}<span style={{fontSize: '0.5em'}}>zł</span>
          </Typography>
          <Divider />
          <Grid container justify="space-between">
            <div>
              <Button color="primary" variant="contained" size="small" style={{marginTop: 16}} onClick={this.handleStartTopUp}>{i18n.t('wallet.topUpButton')}</Button>
              {wallet.availableToWithdrawal > 0 && <Button
                color="primary"
                variant="contained"
                size="small"
                style={{marginTop: 16, marginLeft: 10}}
                onClick={this.handleMoveToWithdrawalFundsPage}>
                {i18n.t('wallet.returnFundsForm')}
              </Button>}
            </div>
            <Button size="small" style={{marginTop: 16, color: '#fff'}} component={Link} to={routePaths.walletHistory}>{i18n.t('wallet.historyButton')}</Button>
          </Grid>
        </Paper>
        <TopUpDialog
          open={this.state.topUpDialogVisible}
          onSubmit={this.handleSubmitTopUp}
          onCancel={this.handleCancelTopUp}
          submitting={this.state.submitting}
        />
      </React.Fragment>
    );
  }
}

Wallet.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  wallet: PropTypes.object.isRequired,
  userEmail: PropTypes.string,
  history: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(walletActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    wallet: state.wallet.data,
    userEmail: state.userState.userProfile.email,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Wallet));
