import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    lng: 'pl',
    fallbackLng: 'pl',
    /* eslint-disable no-undef */
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}.json',
    },
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
      useSuspense: true,
    },
    detection: {
      order: ['querystring', 'navigator'],
    }
  });

i18n.on('languageChanged', lng => moment.updateLocale(lng));

export default i18n;
