export default {
  home: '/',
  signIn: '/signin',
  requestPhoneNumberChange: '/change-phone-number',
  setNewPhoneNumber: '/new-phone-number',
  userProfile: '/profile',
  verifyEmail: '/verify-email',
  oauth2Success: '/oauth2/success/:operator',
  oauth2Failure: '/oauth2/failure/:operator',
  plans: '/plans',
  walletHistory: '/wallet-history',
  withdrawalFunds: '/withdrawal-funds',
  rides: '/rides',
  operators: '/operators',
  operatorTerms: '/operators/:operator/terms-and-conditions',
  operatorPricing: '/operators/:operator/pricing',
  cards: '/cards',
  paymentRedirect: '/payment-redirect',
  campaignUnsubscribe: '/campaign/unsubscribe',
  confirmNewPhoneNumber: '/confirm-new-phone-number',

};
