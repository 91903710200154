import React from "react"
import {Button, FormControl, Grid, Paper, Typography} from "@material-ui/core";
import PhoneNumberPrefixes from "../../../models/PhoneNumberPrefixes";
import i18n from "../../../i18n";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {assignPhoneNumberFormValidation} from "../../../models/auth/AuthModelValidation";
import FormSelect from "../../form/FormSelect";
import FormTextField from "../../form/FormTextField";
import './style.scss'


let AssignPhoneNumberForm = ({handleSubmit, fetching}) => {

  return(
    <form onSubmit={handleSubmit}>
      <div>
        <h4>{i18n.t('assignPhoneNumber.assignForm.header')}</h4>
      </div>
      <Paper style={{padding: 16, marginTop: 24}}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="body2" class='vertical-center'>
              {i18n.t('assignPhoneNumber.assignForm.prefix')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={9}>
            <FormControl>
              <Field
                name="phoneNumberPrefix"
                component={FormSelect}
                values={PhoneNumberPrefixes.map(p => ({
                  value: p.prefix,
                  key: p.countryCode,
                  name: `${p.prefix} ${i18n.t(`countries.${p.countryCode}`)}`,
                }))}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="body2" class='vertical-center'>
              {i18n.t('assignPhoneNumber.assignForm.phoneNumberLabel')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={9}>
            <div className="form-group">
              <FormControl>
              <Field
                name="phoneNumber"
                type="number"
                component={FormTextField}
                label={i18n.t('assignPhoneNumber.assignForm.phoneNumberLabel')}
              />
              </FormControl>
            </div>
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
          <Button disabled={fetching} type="submit">
            {fetching ? i18n.t('assignPhoneNumber.assignForm.submitButtonProgress') :
              i18n.t('assignPhoneNumber.assignForm.submitButtonSMSCode')}
          </Button>
        </div>
      </Paper>
    </form>
  )

}
AssignPhoneNumberForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  fetching: PropTypes.bool.isRequired,
};

AssignPhoneNumberForm = reduxForm({
  form: 'assignPhoneNumber',
  validate: assignPhoneNumberFormValidation,
  initialValues: { phoneNumberPrefix: '+48' }
})(AssignPhoneNumberForm);

export default AssignPhoneNumberForm;
