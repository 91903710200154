import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/userCampaignActions';

export default function userCampaignReducer(state = Immutable(initialState.userCampaign), action) {
   switch (action.type) {
     case types.SUBSCRIBE_CAMPAIGN: {
       return state.merge({
         subscribeFetching: true,
         subscribeError: false,
         isSubscribe: state.isSubscribe,
       });
     }
     case types.SUBSCRIBE_CAMPAIGN_SUCCESS: {
       return state.merge({
         subscribeFetching: false,
         subscribeError: false,
         isSubscribe: action.isSubscribe,
       });
     }
     case types.SUBSCRIBE_CAMPAIGN_FAILURE: {
       return state.merge({
         subscribeFetching: false,
         subscribeError: true,
         isSubscribe: state.isSubscribe,
       });
     }

     case types.CHECK_SUBSCRIBE_CAMPAIGN_SUCCESS: {
       return state.merge({
         subscribeFetching: state.subscribeFetching,
         subscribeError: state.subscribeError,
         isSubscribe: action.data.subscribeActive,
       });
     }
     case types.CHECK_SUBSCRIBE_CAMPAIGN_FAILURE: {
       return state.merge({
         subscribeFetching: state.subscribeFetching,
         subscribeError: state.subscribeError,
         isSubscribe: state.isSubscribe,
       });
     }

    default:
      return state;
  }
}
