import React from "react";
import PropTypes from "prop-types";

const Oauth2Error = ({show}) => {
  if(!show) return null;
  return (
    <div>
      <div style={{ fontWeight: 600, fontSize: 18 }}>Przepraszamy, wystąpił błąd</div>
      <div style={{ fontWeight: 600, fontSize: 18 }}>Prosimy spróbować później.</div>
    </div>
  )
}

Oauth2Error.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default Oauth2Error;
