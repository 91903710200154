import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const FormTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <div>
    <label htmlFor={input.name}>{label}</label>
    <input className="form-control" id={input.name} placeholder={label} {...input} {...custom} />
    {touched && error ? <Typography variant="caption" color="error">{error}</Typography> : null}
  </div>
);

FormTextField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
};

export default FormTextField;
