import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle }  from '@material-ui/core';
import UpdateProfileForm from './UpdateProfileForm';

const UpdateProfileDialog = ({ open, fullScreen, onSubmit, onCancel, dispatch, submitting, userProfile }) => {
  const [t] = useTranslation();
  return (
    <Dialog open={open} fullScreen={fullScreen} fullWidth={true}>
      <DialogTitle>{t('userProfile.dialog.title')}</DialogTitle>
      <DialogContent>
        <UpdateProfileForm onSubmit={onSubmit} initialValues={userProfile} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={submitting}>
          {t('common.cancelButton')}
        </Button>
        <Button onClick={() => dispatch(submit('userProfile'))} disabled={submitting} color="secondary">
          {t('userProfile.dialog.updateButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UpdateProfileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  userProfile: PropTypes.object.isRequired,
};

export default connect()(withMobileDialog()(UpdateProfileDialog));
