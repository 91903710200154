import React from 'react';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import PropTypes from "prop-types";
import moment from "moment";
import i18n from "../../i18n";

const FormDatePicker = ({
  input,
  label,
  meta: { touched, error },
  dateFormat,
  ...custom
}) => {

  return(
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        value={typeof input.value === 'string' ? null : input.value}
        onChange={date => {
            input.onChange(moment(date).toDate());
        }}
        format={dateFormat}
        label={label}
        cancelLabel={i18n.t('common.cancelButton')}
        okLabel={i18n.t('common.okButton')}
        {...custom}
        error={touched && error}
      />
      {touched && error && <div style={{ color: '#f44336', fontSize: '0.75rem', marginTop: 4}}>
        {i18n.t('common.validation.required')}
      </div>}
    </MuiPickersUtilsProvider>
  )
}
FormDatePicker.defaultProps = {
  dateFormat: "YYYY-MM-DD"
}

FormDatePicker.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  dateFormat: PropTypes.string
};

export default FormDatePicker;
