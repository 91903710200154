import React, {useEffect} from 'react'
import AnonymousContentPanel from "../AnonymousContentPanel";
import DocumentTitle from "react-document-title";
import {useLocation} from "react-router";
import {Button, CircularProgress} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import { actions as authActions } from '../../../actions/authActions';
import i18n from "../../../i18n";
import {Link} from "react-router-dom";
import routePaths from "../../../routePaths";

const VerifyEmailPage = () => {
  const params = useLocation();
  const dispatch = useDispatch();

  const authState = useSelector(state => state.auth)

  useEffect(() => {
    const urlSearchParams =  new URLSearchParams(params.search);
    const token = urlSearchParams.get('token')
    dispatch(authActions.verifyUserEmail(token))
  }, [params.search])

  return(
    <DocumentTitle title="ROOVEE">
      <AnonymousContentPanel>
        {authState.verifyEmailInProgress && <CircularProgress color="secondary"  />}
        {!authState.verifyEmailInProgress && <>
          {authState.verifyEmailSuccess && <div style={{ color: 'black' }}>{i18n.t('verifyEmail.success')}</div>}
          {!authState.verifyEmailSuccess && <>
            {authState.verifyEmailErrorCode && <div style={{ color: 'black' }}>{i18n.t(authState.verifyEmailErrorCode)}</div>}
            {!authState.verifyEmailErrorCode && <div style={{ color: 'black' }}>{i18n.t(authState.verifyEmailErrorCode)}</div>}
            </>}
          <div style={{ marginTop: 15}}>
            <Button component={Link}
                    to={routePaths.home}
                    className="btn btn-default"
            >
              {i18n.t('verifyEmail.redirectToMainPage')}
            </Button>
          </div>
        </>}
      </AnonymousContentPanel>
    </DocumentTitle>
  )
}

export default VerifyEmailPage;
