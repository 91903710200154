import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { geolocated } from 'react-geolocated';
import { withStyles } from '@material-ui/core/styles';
import withScriptjs from 'react-google-maps/lib/withScriptjs';
import { bindActionCreators } from 'redux';
import { Paper } from '@material-ui/core';
import { actions as bikesActions } from '../../actions/bikesActions';
import { actions as ridesActions } from '../../actions/ridesActions';
import { actions as zonesActions } from '../../actions/zonesActions';
import Map from './Map';
import BikeMarker from './BikeMarker';
import BikeReservationCard from './BikeReservationCard';
import ActiveReservationCard from './ActiveReservationCard';
import { RideModes } from '../../models/RideModels';

const styles = () => ({
  card: {
    position: 'relative'
  },
  mapContainer: {
    width: '100%',
    height: 400,
  },
});

const defaultZoom = 14;
const defaultZoomGeolocationDisabled = 6;

class BikesMapCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      region: null,
      selectedBike: null,
    }

    this.handleRegionChanged = this.handleRegionChanged.bind(this);
    this.handleReserveBike = this.handleReserveBike.bind(this);
    this.handleCancelReservation = this.handleCancelReservation.bind(this);
    this.handleReservationExpired = this.handleReservationExpired.bind(this);
  }

  componentDidMount() {
    this.props.bikesActions.getRentedBikes();
  }

  handleRegionChanged(region) {
    this.setState({ region });
    if (this.props.ride.mode === RideModes.reservation.value) return;
    if (region.latitudeDelta > 1 || region.longitudeDelta > 0.4) return;

    this.props.bikesActions.getBikesToRent(region);
  }

  handleReserveBike(bike) {
    this.props.ridesActions.reserveBike(bike);
    this.setState({ selectedBike: null });
  }

  handleCancelReservation(rideId) {
    this.props.ridesActions.cancelReservation(rideId);
  }

  handleReservationExpired(rideId) {
    this.props.ridesActions.expireReservation(rideId);
  }

  getLocationFromCoords = coords => ({ lat: coords.latitude, lng: coords.longitude });

  getDefaultInitialLocation = () => ({ lat: 52, lng: 20 });

  render() {
    const { zones, bikes, classes, isGeolocationAvailable, isGeolocationEnabled, coords } = this.props;
    return (
      <Paper className={classes.card}>
        <Map
          containerElement={<div className={classes.mapContainer} />}
          mapElement={<div className={classes.mapContainer} />}
          initialLocation={isGeolocationAvailable && isGeolocationEnabled && coords ? this.getLocationFromCoords(coords) : this.getDefaultInitialLocation()}
          initialZoom={isGeolocationAvailable && isGeolocationEnabled && coords ? defaultZoom : defaultZoomGeolocationDisabled}
          zones={zones.data}
          bikes={bikes.data}
          markerElement={bike => <BikeMarker
            bike={bike}
            key={bike.id}
            onSelect={selectedBike => this.setState({ selectedBike })}
            isSelected={this.state.selectedBike && bike.id === this.state.selectedBike.id}
          />}
          user={coords ? this.getLocationFromCoords(coords) : null}
          onClick={() => this.setState({ selectedBike: null })}
          onRegionChanged={this.handleRegionChanged}
        />
        {(!this.props.ride.mode && this.state.selectedBike)
          && <BikeReservationCard bike={this.state.selectedBike} onReserve={this.handleReserveBike} />}
        {this.props.ride.mode === RideModes.reservation.value
          && <ActiveReservationCard reservation={this.props.ride.data} onCancel={this.handleCancelReservation} onExpiration={this.handleReservationExpired} />}
      </Paper>
    );
  }
}

BikesMapCard.propTypes = {
  classes: PropTypes.object.isRequired,
  bikesActions: PropTypes.object.isRequired,
  zonesActions: PropTypes.object.isRequired,
  ridesActions: PropTypes.object.isRequired,
  userState: PropTypes.object.isRequired,
  zones: PropTypes.object.isRequired,
  bikes: PropTypes.object.isRequired,
  ride: PropTypes.object.isRequired,
  isGeolocationAvailable: PropTypes.bool,
  isGeolocationEnabled: PropTypes.bool,
  coords: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return {
    bikesActions: bindActionCreators(bikesActions, dispatch),
    zonesActions: bindActionCreators(zonesActions, dispatch),
    ridesActions: bindActionCreators(ridesActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    zones: state.zones,
    bikes: state.bikes,
    userState: state.userState,
    ride: state.ride,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withScriptjs(geolocated({
    positionOptions: {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: Infinity,
    },
    userDecisionTimeout: 5000,
  })(BikesMapCard))));
