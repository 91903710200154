import React, { useEffect } from 'react';
import config from '../config';

const PaymentRedirect = () => {
  useEffect(() => {
    setTimeout(function () { window.location.href = config.PAYMENT_REDIRECT_URI; }, 700);
  }, []);

  return (
    <body>
      <p>Redirecting...</p>
    </body>
  );
};

export default PaymentRedirect;
