import i18n from '../i18n';
import ridesApi from '../api/ridesApi';
import { actions as notificationActions } from './notificationActions';
import { actions as termsActions } from './termsAndConditionsActions';
import * as logger from '../utils/logger';

export const types = {
  RESERVE_BIKE: 'RESERVE_BIKE',
  RESERVE_BIKE_SUCCESS: 'RESERVE_BIKE_SUCCESS',
  RESERVE_BIKE_FAILURE: 'RESERVE_BIKE_FAILURE',
  CANCEL_BIKE_RESERVATION: 'CANCEL_BIKE_RESERVATION',
  CANCEL_BIKE_RESERVATION_SUCCESS: 'CANCEL_BIKE_RESERVATION_SUCCESS',
  CANCEL_BIKE_RESERVATION_FAILURE: 'CANCEL_BIKE_RESERVATION_FAILURE',
  GET_RIDES: 'GET_RIDES',
  GET_RIDES_SUCCESS: 'GET_RIDES_SUCCESS',
  GET_RIDES_FAILURE: 'GET_RIDES_FAILURE',
  GET_RIDE: 'GET_RIDE',
  GET_RIDE_SUCCESS: 'GET_RIDE_SUCCESS',
  GET_RIDE_FAILURE: 'GET_RIDE_FAILURE',
  EXPIRE_RESERVATION: 'EXPIRE_RESERVATION',
};

const errorKeys = {
  termsAndConditionsAcceptanceRequired: 'TermsAndConditionsAcceptanceRequired',
}

export const actions = {
  reserveBike: (bike, location) => dispatch => {
    dispatch({ type: types.RESERVE_BIKE });
    return ridesApi
      .createReservation(bike.bikeNumber, location)
      .then(response => {
        if (response.ok && response.data && !response.data.failure) {
          dispatch(notificationActions.showSuccess(i18n.t('map.actions.createReservationSuccess')));
          return dispatch({ type: types.RESERVE_BIKE_SUCCESS, reservation: response.data, bike });
        }

        dispatch({ type: types.RESERVE_BIKE_FAILURE });

        if (response.data.failure && response.data.failure.key === errorKeys.termsAndConditionsAcceptanceRequired) {
          return dispatch(termsActions.showDialog(bike.tenant));
        }

        const error = response.data.failure ? i18n.t(response.data.failure.errorCode, response.data.failure.metadata) : i18n.t('map.actions.createReservationFailure');
        dispatch(notificationActions.showError(error));

        return logger.error(response.data);
      });
  },
  cancelReservation: rideId => dispatch => {
    dispatch({ type: types.CANCEL_BIKE_RESERVATION });
    return ridesApi
      .cancelReservation(rideId)
      .then(response => {
        if (response.ok && response.data && !response.data.failure) {
          dispatch(notificationActions.showSuccess(i18n.t('map.actions.cancelReservationSuccess')));
          return dispatch({ type: types.CANCEL_BIKE_RESERVATION_SUCCESS });
        }

        dispatch({ type: types.CANCEL_BIKE_RESERVATION_FAILURE });
        dispatch(notificationActions.showError(i18n.t('map.actions.cancelReservationFailure')));

        return logger.error(response.data);
      });
  },
  expireReservation: () => dispatch => {
    dispatch(notificationActions.showSuccess(i18n.t('map.actions.expireReservation')));
    return dispatch({ type: types.EXPIRE_RESERVATION });
  },
  getRides: (pageSize, pageNumber) => dispatch => {
    dispatch({ type: types.GET_RIDES, pageSize, pageNumber });
    return ridesApi
      .getRides(pageSize, pageNumber)
      .then(response => {
        if (response.ok && response.data && !response.data.failure) {
          const { pageNumber, pageSize, totalDataCount, hasNextPage, data } = response.data;
          return dispatch({ type: types.GET_RIDES_SUCCESS, pageNumber, pageSize, totalDataCount, hasNextPage, data });
        }

        dispatch({ type: types.GET_RIDES_FAILURE });
        dispatch(notificationActions.showError(i18n.t('rides.actions.getRidesFailure')));

        return logger.error(response.data);
      })
  },
  getRide: (rideId) => (dispatch, getState) => {
    dispatch({ type: types.GET_RIDE, rideId });
    const cached = getState().ridesHistory.data.find(r => r.id === rideId);
    if (cached) {
      dispatch({ type: types.GET_RIDE_SUCCESS, cached });
      return Promise.resolve();
    }

    return ridesApi
      .getRide(rideId)
      .then(response => {
        if (response.ok && response.data && !response.data.failure) {
          return dispatch({ type: types.GET_RIDE_SUCCESS, ride: response.data });
        }

        dispatch({ type: types.GET_RIDE_FAILURE });
        dispatch(notificationActions.showError(i18n.t('rides.actions.getRideFailure')));

        return logger.error(response.data);
      })
  },
};
