import validate from 'validate.js';
import i18n from '../i18n';

export const topUpFormValidation = values => {
  const constraints = {
    amount: {
      presence: { message: i18n.t('common.validation.required') },
      numericality: {
        greaterThan: 0,
        message: i18n.t('common.validation.greaterThan0'),
      },
    },
  };

  return validate(values, constraints, { fullMessages: false });
};