import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Button, Typography } from '@material-ui/core';
import LocationIcon from '@material-ui/icons/LocationOnTwoTone';
import BikeIcon from '@material-ui/icons/DirectionsBikeTwoTone';
import i18n from '../../i18n';

const styles = () => ({
  card: {
    position: 'absolute',
    top: 10,
    left: 10,
    padding: 10,
  },
  label: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: 10,
  },
  actions: {
    textAlign: 'right',
    marginTop: 16,
  },
});

class BikeReservationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bikeAddress: null,
    };
  }

  geocoder = new window.google.maps.Geocoder();

  refreshBikeAddress() {
    this.geocoder.geocode({ location: this.props.bike.location }, (results, status) => {
      if (status == 'OK' && results && results.length > 0) {
        this.setState({ bikeAddress: results[0].formatted_address });
      } else {
        this.setState({ bikeAddress: i18n.t('map.bikeReservationCard.unknownAddress') });
      }
    });
  }

  componentDidMount() {
    this.refreshBikeAddress();
  }

  componentDidUpdate(prevProps) {
    if (this.props.bike === prevProps.bike) return;
    
    this.refreshBikeAddress();
  }

  render() {
    const { bike, onReserve, classes } = this.props;

    return (
      <Paper elevation={8} className={classes.card}>
        <Typography variant="body2" color="secondary" className={classes.label} gutterBottom><BikeIcon color="disabled" className={classes.icon} />{bike.bikeNumber}</Typography>
        <Typography className={classes.label} gutterBottom><LocationIcon color="disabled" className={classes.icon} /> {this.state.bikeAddress}</Typography>
        <Typography variant="caption">{i18n.t('map.bikeReservationCard.valid')}</Typography>
        <div className={classes.actions}>
          <Button size="small" color="secondary" onClick={() => onReserve(bike)}>
            {i18n.t('map.bikeReservationCard.reserveButton')}
          </Button>
        </div>
      </Paper>
    );
  }
}

BikeReservationCard.propTypes = {
  classes: PropTypes.object.isRequired,
  bike: PropTypes.object.isRequired,
  onReserve: PropTypes.func.isRequired,
};

export default withStyles(styles)(BikeReservationCard);
