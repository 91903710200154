import i18n from '../i18n';
const validEmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const userProfileFormValidation = values => {
  const errors = {};
  const requiredFields = [
    'firstName',
    'lastName',
    'email',
  ];
  
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = i18n.t('common.validation.required');
    }
  });
  
  if (!values.email || !validEmailRegex.test(values.email)) {
    errors.email = i18n.t('common.validation.email');
  }

  return errors;
};
