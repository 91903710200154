import React from 'react';
import { IconButton } from '@material-ui/core';
import ContactIcon from '@material-ui/icons/HeadsetMicTwoTone';
import ContactDialog from './ContactDialog';

class ContactIconButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
    };

    this.handleStart = this.handleStart.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleStart() {
    this.setState({ dialogVisible: true });
  }
  
  handleCancel() {
    this.setState({ dialogVisible: false });
  }

  render() {
    return (
      <React.Fragment>
        <IconButton color="default" onClick={this.handleStart}>
          <ContactIcon />
        </IconButton>
        <ContactDialog
          open={this.state.dialogVisible}
          onCancel={this.handleCancel}
        />
      </React.Fragment>
    );
  }
}

export default ContactIconButton;
