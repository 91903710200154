import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { InputAdornment } from '@material-ui/core';
import FormTextField from '../form/FormTextField';
import { topUpFormValidation } from '../../models/WalletValidation';

let TopUpForm = ({ handleSubmit }) => {
  const [t] = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="amount"
          type="number"
          component={FormTextField}
          label={t('wallet.form.amountLabel')}
          autoFocus
          InputProps={{
            endAdornment: <InputAdornment position="end">zł</InputAdornment>,
          }}
        />
      </div>
    </form>
  );
};

TopUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

TopUpForm = reduxForm({
  form: 'topUp',
  validate: topUpFormValidation,
  initialValues: { amount: 10 }
})(TopUpForm);

export default TopUpForm;