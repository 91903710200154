import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const FormSelect = ({
  input,
  label,
  meta: { touched, error },
  values,
  ...custom
}) => (
   <FormControl {...custom} error={touched && error}>
    <InputLabel htmlFor="dropdown">{label}</InputLabel>
    <Select
      value={input.value}
      renderValue={value => value}
      onChange={event => input.onChange(event.target.value)}
      input={<Input id={`dropdown`} />}
      style={{ height: 29 }}
    >
      {values.map(item => (
        <MenuItem key={item.key} value={item.value}>{item.name}</MenuItem>
      ))}
   </Select>
 </FormControl>
);

FormSelect.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  values: PropTypes.array
};

export default FormSelect;
