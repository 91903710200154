import i18n from '../i18n';
import bikesApi from '../api/bikesApi';
import { actions as notificationActions } from './notificationActions';
import { actions as termsActions } from './termsAndConditionsActions';
import * as logger from '../utils/logger';

export const types = {
  GET_BIKES_TO_RENT: 'GET_BIKES_TO_RENT',
  GET_BIKES_TO_RENT_SUCCESS: 'GET_BIKES_TO_RENT_SUCCESS',
};

const errorKeys = {
  termsAndConditionsAcceptanceRequired: 'TermsAndConditionsAcceptanceRequired',
}

export const actions = {
  getBikesToRent: region => dispatch => {
    dispatch({ type: types.GET_BIKES_TO_RENT });
    return bikesApi
      .getBikesToRent(region)
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.GET_BIKES_TO_RENT_SUCCESS, bikes: response.data });
        }
      });
  },
  getRentedBikes: () => dispatch => {
    dispatch({ type: types.GET_BIKES_TO_RENT });
    return bikesApi
      .getRentedBikes()
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.GET_BIKES_TO_RENT_SUCCESS, bikes: response.data });
        }
      });
  },
  reportDefect: data => dispatch => {
    return bikesApi
      .reportDefect(data.bikeNumber, data.description, data.brokenParts || [], null, data.photo)
      .then(response => {
        if (response.ok && response.data && !response.data.failure) {
          dispatch(notificationActions.showSuccess(i18n.t('bikeDefects.actions.reportDefectSuccess')));
          return true;
        } else if (response.data && response.data.failure) {
          if (response.data.failure && response.data.failure.key === errorKeys.termsAndConditionsAcceptanceRequired) {
            dispatch(termsActions.showDialog(response.data.failure.metadata.tenant));
            return false;
          }
          const error = response.data.failure ? i18n.t(response.data.failure.errorCode, response.data.failure.metadata) : i18n.t('bikeDefects.actions.reportDefectFailure');
          dispatch(notificationActions.showError(error));
          logger.error(response.data);
          return false;
        } else if (response.status === 404) {
          dispatch(notificationActions.showError(i18n.t('bikeDefects.actions.reportDefectFailureBikeNotFound')));
          return false;
        }
        dispatch(notificationActions.showError(i18n.t('bikeDefects.actions.reportDefectFailure')));
        return false;
      });
  }
};
