import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/ridesActions';
import { types as userStateTypes } from '../actions/userActions';
import { ReservationModel, RideModes } from '../models/RideModels';

export default function rideReducer(state = Immutable(initialState.ride), action) {
   switch (action.type) {
    case types.RESERVE_BIKE:
      return state.merge({ fetching: true, error: null, mode: null });
    case types.RESERVE_BIKE_SUCCESS: {
      return state.merge({
        fetching: false,
        error: null,
        mode: RideModes.reservation.value,
        data: new ReservationModel(action.reservation.rideId, action.reservation.bikeId, action.reservation.bikeNumber, action.bike.location, action.reservation.expiresAt),
      });
    }
    case types.CANCEL_BIKE_RESERVATION_SUCCESS:
      return state.merge({ fetching: false, error: null, mode: null });
    case types.EXPIRE_RESERVATION:
      return state.merge({ fetching: false, error: null, mode: null });
    case userStateTypes.GET_USER_STATE_SUCCESS: {
      const { reservations } = action.userState;
      if (reservations.length > 0) {
        const reservation = reservations[0];
        return state.merge({
          mode: RideModes.reservation.value,
          data: new ReservationModel(
            reservation.id,
            reservation.bike.id,
            reservation.bike.number,
            { lat: reservation.bike.latitude, lng: reservation.bike.longitude },
            reservation.reservationExpiresAt
          ),
        });
      }

      return state;
    }
    default:
      return state;
  }
}
