import React from 'react';
import PropTypes from 'prop-types';
import './AnonymousContentPanelStyles.scss';
import { useTranslation } from 'react-i18next';
import routePaths from '../../routePaths';
import logo from '../../assets/logo.png';
import iot from '../../assets/logotypes/iot.png';
import bluemedia from '../../assets/logotypes/bluemedia.png';
import orange from '../../assets/logotypes/orange.png';
import roovee from '../../assets/logotypes/roovee.png';
import LanguagePicker from './LanguagePicker';

const AnonymousContentPanel = ({ children }) => {
  const [t] = useTranslation();

  return (
    <div className="anonymous-content-panel">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3">
              <img src={logo} alt="roovee" className="logo img-responsive" />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-10 col-xs-offset-1 col-sm-4 col-sm-offset-4 col-lg-2 col-lg-offset-5">
              {children}
              <hr />
              <div className="row">
                <div className="col-xs-6 col-md-5 col-lg-6">
                  <LanguagePicker />
                </div>
                <div className="col-xs-6 col-md-4 col-md-offset-3 col-lg-6 col-lg-offset-0">
                  <a href={t('signIn.termsAndConditionsUrl')} title={t('signIn.termsAndConditionsLink')} target="_blank" rel="noopener noreferrer"><span>{t('signIn.termsAndConditionsLink')}</span></a>
                  <a href={routePaths.requestPhoneNumberChange}><span>{t('signIn.phoneNumberChangeLink')}</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer>
        <div className="container-fluid">
          <div className="row">
              <div className="col-sm-3">
                <img src={roovee} alt="roovee" />
                <p>&copy; {new Date().getFullYear()} ROOVEE<br />
                {t('contact.dialog.title')}:<br />
                </p>
                <p>tel.: +48 22 300 51 19 <br /> <a href="mailto:bok@roovee.eu">bok@roovee.eu</a></p>
              </div>

              <div className="col-sm-3">
                <img src={iot} alt="Internet Of Things" />
              </div>

              <div className="col-sm-3">
                <img src={orange} alt="Orange" />
              </div>

              <div className="col-sm-3">
                <img src={bluemedia} alt="Blue Media" />
              </div>
          </div>
        </div>
      </footer>
    </div>
  )
};

AnonymousContentPanel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AnonymousContentPanel;
