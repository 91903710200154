import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = () => ({
  root: {
    alignSelf: 'start'
  },
  label: {
    fontSize: 12,
  },
});

const SmallCheckBox = ({
  label,
  onChange,
  classes
}) => (
  <FormControlLabel 
    control={
      <Checkbox
        onChange={onChange}
        color="default"
        classes={{ root: classes.root }}    
      />
    }
    label={label}
    classes={{ label: classes.label }}
  />
);

SmallCheckBox.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.any,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SmallCheckBox);
