import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import NativeFormTextField from '../../form/NativeFormTextField';
import NativeFormSelect from '../../form/NativeFormSelect';
import { phoneNumberFormValidation } from '../../../models/auth/AuthModelValidation';
import PhoneNumberPrefixes from '../../../models/PhoneNumberPrefixes'
import './SignInBySMSFormStyles.scss';
import FormCaptcha from "../../form/FormCaptcha";
import FormCheckBoxLabelWithChildren from "../../form/FormCheckBoxWithLink";

let SignInBySMSForm = ({ handleSubmit, submitting }) => {
  const [t] = useTranslation();
  return (
    <form onSubmit={handleSubmit}>
      <p>{t('signIn.form.header')}</p>
      <div className="form-group">
        <Field
          name="phoneNumberPrefix"
          component={NativeFormSelect}
          label={t('signIn.form.countryLabel')}
          values={PhoneNumberPrefixes.map(p => ({
            value: p.prefix,
            key: p.countryCode,
            name: `${p.prefix} ${t(`countries.${p.countryCode}`)}`,
          }))}
        />
      </div>
      <div className="form-group">
        <Field
          name="phoneNumber"
          type="number"
          component={NativeFormTextField}
          label={t('signIn.form.phoneNumberLabel')}
          autoFocus
        />
        <Field
          name="reCaptchaToken"
          component={FormCaptcha}
          formName={'signInBySMS'}
          action={'registrationWebByPhoneNumber'}
        />
      </div>
      <div className="form-group">
        <Field
          name="terms"
          component={FormCheckBoxLabelWithChildren}
          label={
            <span>
              <span>{t('signIn.form.terms')}</span>
              <span><a className={'link-clear'} href={t('signIn.termsAndConditionsUrl')} title={t('signIn.form.termsLink')} target="_blank" rel="noopener noreferrer"> {t('signIn.form.termsLink')} </a> </span>
            </span>
          }
          autoFocus
        />
        <Field
          name="conditions"
          component={FormCheckBoxLabelWithChildren}
          label={
            <span>
              <span>{t('signIn.form.conditions')}</span>
              <span><a className={'link-clear'} href={t('signIn.conditionsUrl')} title={t('signIn.conditionsLink')} target="_blank" rel="noopener noreferrer">{t('signIn.conditionsLink')}</a> </span>
            </span>
          }
          autoFocus
        />
      </div>
      <button type="submit" className="btn btn-default" disabled={submitting}>
        {submitting ? t('signIn.form.submitButtonProgress') : t('signIn.form.submitButton')}
      </button>
    </form>
  );
};

SignInBySMSForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

SignInBySMSForm = reduxForm({
  form: 'signInBySMS',
  validate: phoneNumberFormValidation,
  initialValues: { phoneNumberPrefix: '+48' }
})(SignInBySMSForm);

export default SignInBySMSForm;
