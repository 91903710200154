import apiBase from './apiBase';

class UserApi {
  static getState() {
    return apiBase.create().get('/mobile/me');
  }

  static update(data) {
    return apiBase.create().put('/mobile/me/details', data);
  }

  static subscribeCampaign(isSubscribe) {
    return apiBase.create().post(`/mobile/subscribeNewsletter`, {
      "isSubscribe": isSubscribe
    });
  }
  static unsubscribeNewsletterCampaign(userId) {
    return apiBase.create().post(`/mobile/unsubscribeNewsletter`, {
      userId: userId
    });
  }

  static checkSubscribeCampaign() {
    return apiBase.create().get(`/mobile/subscribeNewsletter`);
  }

  static updatePreferredLanguage(preferredLanguageCode) {
    return apiBase.create().put('/mobile/me/preferredLanguage', { preferredLanguageCode });
  }

  static assignPhoneNumber(phoneNumberPrefix, phoneNumber, reCaptchaToken) {
    return apiBase.create().post('/mobile/account/registration/phone', { phoneNumberPrefix, phoneNumber, reCaptchaToken });
  }

  static verifyAssignPhoneNumberCode(code) {
    return apiBase.create().post('/mobile/account/registration/phone/verify', { code });
  }
}

export default UserApi;
