import apiBase from './apiBase';
import dataUriToBlob from '../utils/dataUriToBlob';

const formDataHeaders = { 'Content-Type': 'multipart/form-data' };

class BikesApi {
  static getBikesToRent(region) {
    return apiBase.create().get('/mobile/bikes/bikesAvailableInSection', {
      longitude: region.longitude,
      latitude: region.latitude,
      LatitudeDelta: region.latitudeDelta,
      LongitudeDelta: region.longitudeDelta,
    });
  }

  static getRentedBikes() {
    return apiBase.create().get('/mobile/bikes/bikesRentedByMe');
  }

  static reportDefect(bikeNumber, description, brokenParts, location, photo) {
    const form = new FormData();
    form.append('bikeNumber', bikeNumber);
    form.append('description', description);
    brokenParts.forEach(p => {
      form.append('brokenParts', p.value);
    });

    if (location) {
      form.append('userLocation.latitude', location.lat.toString());
      form.append('userLocation.longitude', location.lng.toString());
      form.append('userLocation.altitude', location.alt.toString());
      form.append('userLocation.horizontalAccuracy', location.hAcc.toString());
    }

    if (photo) {
      form.append('photo', dataUriToBlob(photo));
    }
    
    return apiBase.create().post('/mobile/bikes/reportDefect', form, { headers: formDataHeaders });
  }
}

export default BikesApi;
