import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardHeader } from '@material-ui/core';

const styles = theme => ({
  card: {
    transition: 'box-shadow 0.1s ease-in-out',
    borderBottomWidth: 3,
    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme.shadows[6],
    },
  },
  cardSelected: {
    borderBottomColor: theme.palette.primary.light,
    borderBottomStyle: 'inset',
    boxShadow: theme.shadows[6],
  }
});

const TenantDetailsCard = ({ tenant, plansCount, classes, onClick, selected }) => {
  const [t] = useTranslation();
  return (
    <Card className={classNames(classes.card, selected ? classes.cardSelected : '')} onClick={onClick}>
      <CardHeader title={tenant.toUpperCase()} subheader={`${t('plans.tenantDetailsCard.plansCount')} ${plansCount}`} />
    </Card>
  );
}

TenantDetailsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  tenant: PropTypes.string.isRequired,
  plansCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default withStyles(styles, { name: 'TenantDetailsCard' })(TenantDetailsCard);
