/* eslint-disable import/default */

import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { actions as initActions } from './actions/initActions';
import moment from 'moment';
import locale from 'moment/locale/pl';
import Root from './components/Root';
import './i18n';
console.log('i18n jest')
import config from './config';
import configureStore from './store/configureStore';
require('./favicon.ico');
import './styles/styles.scss';
import { syncApiWithStore } from './api/apiBase';
import history from './history'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

moment.updateLocale('pl', locale);
const store = configureStore();
syncApiWithStore(store);

store.dispatch(initActions.preloadData());

render(
  <AppContainer>
    <GoogleReCaptchaProvider
      reCaptchaKey={config.RECAPTCHA_KEY}
      useEnterprise={true}
    >
      <Root store={store} history={history} />
    </GoogleReCaptchaProvider>
  </AppContainer>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    render(
      <AppContainer>
        <GoogleReCaptchaProvider
          reCaptchaKey={config.RECAPTCHA_KEY}
          useEnterprise={true}
        >
        <NewRoot store={store} history={history} />
        </GoogleReCaptchaProvider>
      </AppContainer>,
      document.getElementById('app')
    );
  });
}
