import { AuthModel } from '../models/auth/AuthModel';
import { UserStateModel } from '../models/UserStateModel';
import { WalletModel } from '../models/WalletModel';
import { TermsAndConditionsModel } from '../models/TermsAndConditionsModel';

export default {
  auth: new AuthModel(null),
  notifications: [],
  userState: new UserStateModel(),
  userCampaign:{
    subscribeFetching: true,
    subscribeError: false,
    isSubscribe: true,
  },
  wallet: {
    data: new WalletModel(null, null, null, [], [], [], [], []),
    fetching: false,
    error: null,
  },
  plans: {
    data: [],
    fetching: false,
    error: null,
  },
  termsAndConditions: {
    data: new TermsAndConditionsModel(),
    fetching: false,
    error: null,
    displayDialog: false,
  },
  bikes: {
    data: [],
    fetching: false,
    error: null,
  },
  zones: {
    data: [],
    fetching: false,
    error: null,
  },
  ride: {
    data: [],
    fetching: false,
    error: null,
  },
  ridesHistory: {
    data: [],
    details: {trace: []},
    pageNumber: 1,
    pageSize: 10,
    totalDataCount: 0,
    fetching: false,
  },
  operators: {
    data: [],
    fetching: false,
    error: null,
  },
  cards: {
    data: null,
    fetching: false,
  },
};
