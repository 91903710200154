import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/bikesActions';
import { BikeModel } from '../models/BikeModel';

export default function bikesReducer(state = Immutable(initialState.bikes), action) {
   switch (action.type) {
    case types.GET_BIKES_TO_RENT:
      return state.merge({ fetching: true, error: null });
    case types.GET_BIKES_TO_RENT_SUCCESS: {
      return state.merge({
        fetching: false,
        error: null,
        data: action.bikes
          .filter(b => b.location)
          .map(b => new BikeModel(b.id, b.bikeNumber, b.tenant, b.location, b.icon)),
      });
    }
    default:
      return state;
  }
}
