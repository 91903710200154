import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#DEE1DE',
    padding: 15,
  },
};

const NoTracePanel = () => {
  const [t] = useTranslation();
  return (
    <div style={styles.container}>
      <Typography style={styles.text} align='center' variant="subtitle1">{t('rideDetails.traceNotSaved')}</Typography>
    </div >
  );
};

export default NoTracePanel;
