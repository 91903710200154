import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = () => ({
  root: {
    alignSelf: 'start'
  },
  label: {
    fontSize: 12,
    whiteSpace: 'pre-line',
  },
});

const FormSmallCheckBox = ({
  input,
  label,
  classes,
  disabled
}) => (
  <FormControlLabel
    control={
      <Checkbox
        disabled={disabled}
        checked={input.value}
        onChange={e => {
          input.onChange(e, !input.value);
        }}
        value={input.name}
        color="default"
        classes={{ root: classes.root }}
      />
    }
    label={label}
    classes={{ label: classes.label }}
  />
);
FormSmallCheckBox.defaultProps = {
  disabled: false,
}

FormSmallCheckBox.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export default withStyles(styles)(FormSmallCheckBox);
