import validate from 'validate.js';
import i18n from '../i18n';

export const assignCardValidation = values => {
  let constraints = {
    cardNumber: {
      presence: { message: i18n.t('common.validation.required'), allowEmpty: false },
    },
  };
  if (values.requireBirthday){
    constraints.birthday = {
      presence: { message: i18n.t('common.validation.required'), allowEmpty: false },
    }
  }
  return validate(values, constraints, { fullMessages: false });
};
