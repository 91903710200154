import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DocumentTitle from 'react-document-title';
import {Grid, Typography, CircularProgress, Paper} from '@material-ui/core';
import { actions } from '../../actions/cardsActions';
import CardDetails from './CardDetails';
import AssignedCardDetails from './AssignedCardDetails';
import i18n from '../../i18n';
import {withStyles} from "@material-ui/core/styles";

const styles = () => ({
  paper: {
    marginTop: 40,
    padding: 16,
    maxHeight: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperNotCard: {
    display: 'flex',
    justifyContent: 'center'
  }
});

class CardsPage extends Component {
  componentDidMount() {
    this.props.actions.load();
  }

  checkUserHasAcceptedAnyTenantTerms() {
    return this.props.acceptedTerms && this.props.acceptedTerms.length !== 0;

  }

  render() {
    const { cards, fetching, classes } = this.props;
    return (
      <DocumentTitle title={i18n.t('cards.title')}>
        <div className="page">
        <div style={{margin: 16}}>
          <Grid container justify="center">
            <Grid item xs={12} md={8}>
              {fetching ? <div style={{textAlign: 'center'}}><CircularProgress size={50} color="secondary" /></div> : cards ? <React.Fragment>
                {!fetching && cards.configured.length ===  0 && cards.available.length === 0 &&
                  <div className={classes.wrapperNotCard}>
                    <Paper className={classes.paper}>
                      {this.checkUserHasAcceptedAnyTenantTerms() ?
                        <div style={{textAlign: 'center'}}>{i18n.t('cards.noCardsToShow.NoAvailableCards')}</div>
                      : <div style={{textAlign: 'center'}}>{i18n.t('cards.noCardsToShow.NoAnyTermsTenantAccepted')}</div>}
                    </Paper>
                  </div>
                }
                {cards.configured.length > 0 && <React.Fragment>
                  <Typography variant="h4" gutterBottom>
                    {i18n.t('cards.assigned')}
                  </Typography>
                  <Grid container spacing={2}>
                    {cards.configured.map(card => (<Grid item xs={12} sm={12} md={6} xl={3} key={card.cardProviderId}>
                      <AssignedCardDetails card={card} />
                    </Grid>))}
                  </Grid>
                </React.Fragment>}
                {cards.available.length > 0 && <React.Fragment>
                  <Typography variant="h4" gutterBottom>
                    {i18n.t('cards.available')}
                  </Typography>
                  <Grid container spacing={3}>
                    {cards.available.map(card => (<Grid item xs={12} sm={12} md={6} xl={3} key={card.cardProviderId}>
                      <CardDetails card={card} termsAcceptanceRequired={card.tenants.length === 1 && !this.props.acceptedTerms.some(t => t.tenant === card.tenants[0].tenantId && t.isLatestVersionAccepted)} />
                    </Grid>))}
                  </Grid>
                </React.Fragment>}
              </React.Fragment> : null}
            </Grid>
          </Grid>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

CardsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  cards: PropTypes.object,
  acceptedTerms: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    fetching: state.cards.fetching,
    cards: state.cards.data,
    acceptedTerms: state.userState.acceptedTermsAndConditions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CardsPage));

