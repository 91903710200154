import React from 'react'
import AssignPhoneNumberForm from "./AssignPhoneNumberForm";
import PropTypes from "prop-types";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import AssignPhoneNumberVerificationForm from "./AssignPhoneNumbeVerificationForm";


const AssignPhoneNumber = ({handleSubmitAssign, handleSubmitVerificationCode, assignPhoneNumber}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onHandleSubmitAssign = async (data) => {
    const reCaptchaToken = await executeRecaptcha('assignPhoneNumber');
    handleSubmitAssign(data.phoneNumberPrefix, data.phoneNumber, reCaptchaToken)
  }

  return(
    <>
      {assignPhoneNumber.isAssignPhoneNumberStage && <AssignPhoneNumberForm
        onSubmit={onHandleSubmitAssign}
        fetching={assignPhoneNumber.fetching}
      />}
      {assignPhoneNumber.isVerificationStage && <AssignPhoneNumberVerificationForm
        fetching={assignPhoneNumber.fetching}
        onSubmit={handleSubmitVerificationCode}
        phoneNumber={`${assignPhoneNumber.prefix}${assignPhoneNumber.phoneNumber}`}
      />}
    </>
  )
}

AssignPhoneNumber.propTypes = {
  handleSubmitAssign: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  assignPhoneNumber: PropTypes.object.isRequired,
  handleSubmitVerificationCode: PropTypes.object.isRequired,
};

export default AssignPhoneNumber
