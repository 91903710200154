import i18n from '../i18n';
import authenticationApi from '../api/authenticationApi';
import localStorageProvider from '../utils/localStorageProvider';
import { actions as notificationActions } from './notificationActions';
import { actions as initActions } from './initActions';
import routePaths from '../routePaths';
import * as logger from '../utils/logger';
import config from '../config';
import history from '../history';
export const types = {
  REQUEST_SMS_CODE_SUCCESS: 'REQUEST_SMS_CODE_SUCCESS',
  REQUEST_SMS_CODE_FAILURE: 'REQUEST_SMS_CODE_FAILURE',
  REQUEST_EMAIL_CODE_SUCCESS: 'REQUEST_EMAIL_CODE_SUCCESS',
  REQUEST_EMAIL_CODE_FAILURE: 'REQUEST_EMAIL_CODE_FAILURE',
  REQUEST_OAUTH2_CODE: "REQUEST_OAUTH2_CODE",
  REQUEST_OAUTH2_CODE_SUCCESS: 'REQUEST_OAUTH2_CODE_SUCCESS',
  REQUEST_OAUTH2_CODE_FAILURE: 'REQUEST_OAUTH2_CODE_FAILURE',
  REQUEST_OAUTH2_LOGIN: "REQUEST_OAUTH2_LOGIN",
  REQUEST_OAUTH2_LOGIN_SUCCESS: 'REQUEST_OAUTH2_LOGIN_SUCCESS',
  REQUEST_OAUTH2_LOGIN_FAILURE: 'REQUEST_OAUTH2_LOGIN_FAILURE',
  VERIFY_SMS_CODE_FAILURE: 'VERIFY_SMS_CODE_FAILURE',
  FINISH_REGISTRATION: 'FINISH_REGISTRATION',
  FINISH_REGISTRATION_FAILURE: 'FINISH_REGISTRATION_FAILURE',
  SIGNED_IN: 'SIGNED_IN',
  SIGNED_OUT: 'SIGNED_OUT',
  REQUEST_PHONE_NUMBER_CHANGE_SUCCESS: 'REQUEST_PHONE_NUMBER_CHANGE_SUCCESS',
  REQUEST_PHONE_NUMBER_CHANGE_FAILURE: 'REQUEST_PHONE_NUMBER_CHANGE_FAILURE',
  START_NEW_PHONE_NUMBER_VERIFICATION_SUCCESS: 'START_NEW_PHONE_NUMBER_VERIFICATION_SUCCESS',
  START_NEW_PHONE_NUMBER_VERIFICATION_FAILURE: 'START_NEW_PHONE_NUMBER_VERIFICATION_FAILURE',
  COMPLETE_NEW_PHONE_NUMBER_VERIFICATION_SUCCESS: 'COMPLETE_NEW_PHONE_NUMBER_VERIFICATION_SUCCESS',
  COMPLETE_NEW_PHONE_NUMBER_VERIFICATION_FAILURE: 'COMPLETE_NEW_PHONE_NUMBER_VERIFICATION_FAILURE',
  VERIFY_EMAIL_CODE_REQUEST: 'VERIFY_EMAIL_CODE_REQUEST',
  VERIFY_EMAIL_CODE_SUCCESS: 'VERIFY_EMAIL_CODE_SUCCESS',
  VERIFY_EMAIL_CODE_FAILURE: 'VERIFY_EMAIL_CODE_FAILURE',
  CONFIRM_NEW_PHONE_NUMBER_REQUEST: 'CONFIRM_NEW_PHONE_NUMBER_REQUEST',
  CONFIRM_NEW_PHONE_NUMBER_SUCCESS: 'CONFIRM_NEW_PHONE_NUMBER_SUCCESS',
  CONFIRM_NEW_PHONE_NUMBER_FAILURE: 'CONFIRM_NEW_PHONE_NUMBER_FAILURE',
};

const defaultLanguage = 'pl';

export const actions = {

  verifyUserEmail: (token) => async (dispatch) => {
    dispatch({ type: types.VERIFY_EMAIL_CODE_REQUEST });
    const response = await authenticationApi.verifyEmail(token)
    if (response.ok && response.data.success !== false) {
      dispatch({ type: types.VERIFY_EMAIL_CODE_SUCCESS });
      return
    }
    if (response.ok && response.data.success === false && response.data.failure && response.data.failure.errorCode) {
      dispatch({ type: types.VERIFY_EMAIL_CODE_FAILURE, verifyEmailErrorCode: response.data.failure.errorCode });
      return
    }
    dispatch({ type: types.VERIFY_EMAIL_CODE_FAILURE, verifyEmailErrorCode: 'errorCode.userManagement.mobileLogin.unknownErrorEmailVerifyToken' });

  },
  requestSmsCode: signInData => dispatch => {
    return authenticationApi
      .requestSmsCode(signInData, i18n.language ? i18n.language.substring(0, 2) : defaultLanguage)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          const token = response.data;
          return dispatch({ type: types.REQUEST_SMS_CODE_SUCCESS, token, phoneNumber: signInData.phoneNumberPrefix + signInData.phoneNumber });
        }

        dispatch({ type: types.REQUEST_SMS_CODE_FAILURE });
        dispatch(notificationActions.showError(i18n.t('signIn.actions.requestSmsCodeFailure')));
        return logger.error(response);
      });
  },

  requestEmailCode: signInData => dispatch => {
    return authenticationApi
      .requestEmailCode(signInData, i18n.language ? i18n.language.substring(0, 2) : defaultLanguage)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          const token = response.data;
          return dispatch({ type: types.REQUEST_EMAIL_CODE_SUCCESS, token, email: signInData.email, });
        }

        dispatch({ type: types.REQUEST_EMAIL_CODE_FAILURE });
        dispatch(notificationActions.showError(i18n.t('signIn.actions.requestSmsCodeFailure')));
        return logger.error(response);
      });
  },

  requestOauth2Url: (provider) => dispatch => {
    dispatch({ type: types.REQUEST_OAUTH2_CODE, provider });
    return authenticationApi
      .requestOauth2Url(provider)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          return dispatch({ type: types.REQUEST_OAUTH2_CODE_SUCCESS, url: response.data.url, provider: provider
          });
        }
        dispatch({ type: types.REQUEST_OAUTH2_CODE_FAILURE,  provider: provider, });
        return logger.error(response);
      });
  },

  requestOauth2Login: (provider, body) => dispatch => {
    dispatch({ type: types.REQUEST_OAUTH2_LOGIN, provider });
    return authenticationApi
      .requestOauth2Login(provider, body)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.REQUEST_OAUTH2_LOGIN_SUCCESS, provider: provider});
          if (response.data.isRegistrationCompleted === false) {
            dispatch({ type: types.FINISH_REGISTRATION, registrationAsEmail: response.data.registrationAsEmail,
              token: response.data.token,
              registrationAsOAuth : response.data.registrationAsOAuth, oauth2: {
                email: response.data.email,
                firstName: response.data.firstName,
                lastName: response.data.lastName
              } });
            return;
          }
          const token = response.data.authToken;

          localStorageProvider.setItem(config.TOKEN_NAME, token);
          dispatch({ type: types.SIGNED_IN, token });
          dispatch(initActions.preloadData());
          return history.push(routePaths.home);
        }
        dispatch({ type: types.REQUEST_OAUTH2_LOGIN_FAILURE,  provider: provider, error: response.data.failure.errorCode});
        return logger.error(response);
      });
  },



  verifySmsCode: (code, token) => dispatch => {
    return authenticationApi
      .verifySmsCode(code, token)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          if (response.data.isRegistrationCompleted === false) {
            dispatch({ type: types.FINISH_REGISTRATION, registrationAsEmail: response.data.registrationAsEmail, email: response.data.email });
            return;
          }

          const token = response.data.authToken;

          localStorageProvider.setItem(config.TOKEN_NAME, token);
          dispatch({ type: types.SIGNED_IN, token });
          dispatch(initActions.preloadData());
          return history.push(routePaths.home);
        }

        dispatch({ type: types.VERIFY_SMS_CODE_FAILURE });
        dispatch(notificationActions.showError(i18n.t('signIn.actions.verifySmsCodeFailure')));
        return logger.error(response);
      });
  },

  verifyEmailCode: (code, token) => dispatch => {
    return authenticationApi
      .verifyEmailCode(code, token)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          if (response.data.isRegistrationCompleted === false) {
            dispatch({ type: types.FINISH_REGISTRATION, registrationAsEmail: response.data.registrationAsEmail, email: response.data.email });
            return;
          }
          const token = response.data.authToken;

          localStorageProvider.setItem(config.TOKEN_NAME, token);
          dispatch({ type: types.SIGNED_IN, token });
          dispatch(initActions.preloadData());
          return history.push(routePaths.home);
        }

        dispatch({ type: types.VERIFY_EMAIL_CODE_FAILURE });
        dispatch(notificationActions.showError(i18n.t('signIn.actions.verifyCodeFailure')));
        return logger.error(response);
      });
  },

  finishRegistration: (data, token) => dispatch => {
    const { firstName, lastName, email, isAdult } = data;
    return authenticationApi
      .finishRegistration(firstName, lastName, email, isAdult, token)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          const token = response.data;
          localStorageProvider.setItem(config.TOKEN_NAME, token);
          dispatch({ type: types.SIGNED_IN, token });
          dispatch(initActions.preloadData());
          return history.push(routePaths.home);
        }
        if (response.ok && response.data.success === false) {
          if (response.data.failure.errorCode === 'errorCode.userManagement.mobileLogin.emailExist'){
            dispatch({ type: types.FINISH_REGISTRATION_FAILURE });
            dispatch(notificationActions.showError(i18n.t('signIn.actions.emailExist')));
            return logger.error(response);
          }
        }
        dispatch({ type: types.FINISH_REGISTRATION_FAILURE });
        dispatch(notificationActions.showError(i18n.t('signIn.actions.finishRegistrationFailure')));
        return logger.error(response);
      });
  },

  finishRegistrationByOAuth2: (data, token) => dispatch => {
    return authenticationApi
      .finishRegistrationByOAuth2(token, data.email)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          const token = response.data;
          localStorageProvider.setItem(config.TOKEN_NAME, token);
          dispatch({ type: types.SIGNED_IN, token });
          dispatch(initActions.preloadData());
          return history.push(routePaths.home);
        }
        if (response.ok && response.data.success === false) {
          if (response.data.failure.errorCode === 'errorCode.userManagement.mobileLogin.emailExist'){
            dispatch({ type: types.FINISH_REGISTRATION_FAILURE });
            dispatch(notificationActions.showError(i18n.t('signIn.actions.emailExist')));
            return logger.error(response);
          }
        }
        dispatch({ type: types.FINISH_REGISTRATION_FAILURE });
        dispatch(notificationActions.showError(i18n.t('signIn.actions.finishRegistrationFailure')));
        return logger.error(response);
      });
  },

  requestPhoneNumberChange: data => dispatch => {
    return authenticationApi
      .requestPhoneNumberChange(data.phoneNumberPrefix, data.phoneNumber, data.email)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch(notificationActions.showSuccess(i18n.t('changePhoneNumber.request.requestSuccess')));
          return dispatch({ type: types.REQUEST_PHONE_NUMBER_CHANGE_SUCCESS });
        }

        dispatch({ type: types.REQUEST_PHONE_NUMBER_CHANGE_FAILURE });
        dispatch(notificationActions.showError(i18n.t('changePhoneNumber.request.requestFailure')));
        return logger.error(response);
      });
  },

  startNewPhoneNumberVerification: data => dispatch => {
    const { phoneNumber, phoneNumberPrefix } = data;
    return authenticationApi
      .startNewPhoneNumberVerification(phoneNumberPrefix, phoneNumber, data.token)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          return dispatch({ type: types.START_NEW_PHONE_NUMBER_VERIFICATION_SUCCESS, phoneNumber, phoneNumberPrefix });
        }

        if (response.ok && response.data.failure) {
          const errorText = i18n.exists(response.data.failure.errorCode)
            ? i18n.t(response.data.failure.errorCode)
            : i18n.t('changePhoneNumber.request.requestFailure');
          dispatch(notificationActions.showError(errorText));
          return dispatch({ type: types.START_NEW_PHONE_NUMBER_VERIFICATION_FAILURE });
        }

        dispatch(notificationActions.showError(i18n.t('changePhoneNumber.request.requestFailure')));
        dispatch({ type: types.START_NEW_PHONE_NUMBER_VERIFICATION_FAILURE });
        return logger.error(response);
      });
  },

  completeNewPhoneNumberVerification: (verificationCode, token) => dispatch => {
    return authenticationApi
      .completeNewPhoneNumberVerification(verificationCode, token)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch(notificationActions.showSuccess(i18n.t('changePhoneNumber.success')));
          dispatch({ type: types.COMPLETE_NEW_PHONE_NUMBER_VERIFICATION_SUCCESS });
          return history.push(routePaths.signIn);
        }

        dispatch({ type: types.COMPLETE_NEW_PHONE_NUMBER_VERIFICATION_FAILURE });
        dispatch(notificationActions.showError(i18n.t('signIn.actions.verifySmsCodeFailure')));
        return logger.error(response);
      });
  },

  confirmNewPhoneNumber: (token) => dispatch => {
    dispatch({ type: types.CONFIRM_NEW_PHONE_NUMBER_REQUEST });
    return authenticationApi
      .confirmNewPhoneNumber(token)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch(notificationActions.showSuccess(i18n.t('changePhoneNumber.success')));
          dispatch({ type: types.CONFIRM_NEW_PHONE_NUMBER_SUCCESS });
          return history.push(routePaths.signIn);
        }

        dispatch({ type: types.CONFIRM_NEW_PHONE_NUMBER_FAILURE });
        return response.data
      });
  },

  signOut: () => dispatch => {
    localStorageProvider.removeItem(config.TOKEN_NAME);
    dispatch({ type: types.SIGNED_OUT });
    history.push(routePaths.signIn);
  },
};
