import apiBase from './apiBase';

class WalletApi {
  static getWallet() {
    return apiBase.create().get('/mobile/wallet');
  }

  static confirmAutomaticWithdraw(id) {
    return apiBase.create().post('/mobile/wallet/confirmAutomaticWithdraw?api-version=2', { id: id });
  }

  static createPayment(amount, email) {
    return apiBase.create().post('/mobile/payments/online/create', { amount, email });
  }

  static withdrawRefundsForm(data) {
    return apiBase.create().post('/mobile/wallet/withdrawFundsForm', data);
  }
}

export default WalletApi;
