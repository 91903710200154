import jwtDecode from 'jwt-decode';

export const LoginMode = {
  select: 'select',
  sms: 'sms',
  email: 'email',
  oauth2: 'oauth2',
};

export const Oauth2Provider = {
  plockCard: 'plockCard'
};

export class AuthModel {
  constructor(jwtToken) {
    if (!jwtToken) {
      this.id = null;
      this.authenticationProgress = 1;
      this.authenticationToken = null;
      this.phoneNumber = null;
      this.signedIn = false;
      this.newPhoneNumberVerificationInProgress = false;
      this.newPhoneNumber = null;
      this.verifyEmailErrorCode = null;
      this.verifyEmailSuccess = false;
      this.verifyEmailInProgress = false;
      this.confirmEmailInProgress = false;
      this.confirmEmailSuccess = false;
      this.registrationAsEmail = false;
      this.email = null;
      this.oauth2 = {
        provider: null,
        url: null,
        isFetching: false,
        error: false,
        email: null,
        firstName: null,
        lastName: null
      }

    } else {
      const decoded = jwtDecode(jwtToken);
      this.authenticationProgress = null;
      this.id = decoded.id;
      this.authenticationToken = null;
      this.phoneNumber = decoded.phoneNumber;
      this.signedIn = true;
      this.newPhoneNumberVerificationInProgress = false;
      this.registrationAsEmail = false;
      this.email = null;
      this.newPhoneNumber = null;
      this.verifyErrorCode = null;
      this.verifyEmailSuccess = false;
      this.verifyEmailInProgress = false;
      this.oauth2 = {
        provider: null,
        url: null,
        isFetching: false,
        error: false,
        email: null,
        firstName: null,
        lastName: null,
        isAdult: false,
      }
    }
  }
}
