import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import FormTextField from '../form/FormTextField';
import { assignCardValidation } from '../../models/CardsValidation';
import FormDatePicker from "../form/FormDatePicker";

let AssignCardForm = ({ handleSubmit, requireBirthday }) => {
  const [t] = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="cardNumber"
          component={FormTextField}
          label={t('cards.form.cardNumberLabel')}
          placeholder={t('cards.form.cardNumberPlaceholder')}
          autoFocus
          fullWidth
        />
        {requireBirthday && <Field
          name="birthday"
          component={FormDatePicker}
          label={t('cards.form.birthdayLabel')}
          placeholder={t('cards.form.birthdayPlaceholder')}
          fullWidth
        />}
      </div>
    </form>
  );
};

AssignCardForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  requireBirthday: PropTypes.bool.isRequired
};

AssignCardForm = reduxForm({
  form: 'assignCard',
  validate: assignCardValidation,
  initialValues: { cardNumber: '', birthday: null }
})(AssignCardForm);

export default AssignCardForm;
