import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, MobileStepper, IconButton } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import moment from 'moment';
import BackIcon from '@material-ui/icons/KeyboardArrowLeftTwoTone';
import NextIcon from '@material-ui/icons/KeyboardArrowRightTwoTone';
import i18n from '../../i18n';

const styles = theme => ({
  root: {
    background: '#fff',
    padding: 0,
  },
  dotActive: {
    background: theme.palette.secondary[600]
  },
});

class ActivePlansSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePlan: 0,
    };
  }

  handlePlanChange = activePlan => {
    this.setState({ activePlan });
  };

  handleNext = () => {
    this.setState(state => ({
      activePlan: state.activePlan + 1,
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activePlan: state.activePlan - 1,
    }));
  };

  renderPlanDetails = plan => (
    <React.Fragment>
      <Typography variant="subtitle1">
        {plan.name}
      </Typography>
      <Typography variant="caption" gutterBottom>
        {i18n.t('userState.activePlansSlider.operator')} {plan.tenant.toUpperCase()}
      </Typography>
      <Typography variant="body1">
        {i18n.t('userState.activePlansSlider.validTo')} <strong>{moment(plan.validTo).format('DD.MM.YYYY HH:mm')}</strong>
      </Typography>
      {plan.freeMinutesRemainingToday !== null && <Typography variant="body1">
        {i18n.t('userState.activePlansSlider.freeMinutesRemainingToday')} <strong>{plan.freeMinutesRemainingToday}</strong>
      </Typography>}
    </React.Fragment>
  );

  renderDefaultPlanDetails = plan => (
    <React.Fragment>
      <Typography variant="subtitle1">
        {i18n.t('userState.activePlansSlider.defaultPlanPricing')}
      </Typography>
      <Typography variant="caption" gutterBottom>
        {i18n.t('userState.activePlansSlider.operator')} {plan.tenant.toUpperCase()}
      </Typography>
      {plan.minutes !== null && <Typography variant="body1">
        {i18n.t('userState.activePlansSlider.freeMinutesRemainingToday')} <strong>{plan.minutes}</strong>
      </Typography>}
    </React.Fragment>
  );

  render() {
    const { activePlans, classes } = this.props;
    return (
      <React.Fragment>
        <SwipeableViews
          index={this.state.activePlan}
          onChangeIndex={this.handlePlanChange}
          enableMouseEvents
          style={{ flexGrow: 1 }}
        >
          {activePlans.map(plan => (
            <React.Fragment key={plan.tenantId}>
              {plan.id ? this.renderPlanDetails(plan) : this.renderDefaultPlanDetails(plan)}
            </React.Fragment>)
          )}
        </SwipeableViews>
        <MobileStepper
          classes={classes}
          steps={activePlans.length}
          position="static"
          activeStep={this.state.activePlan}
          nextButton={
            <IconButton size="small" onClick={this.handleNext} disabled={this.state.activePlan === activePlans.length - 1}>
              <NextIcon />
            </IconButton>
          }
          backButton={
            <IconButton size="small" onClick={this.handleBack} disabled={this.state.activePlan === 0}>
              <BackIcon />
            </IconButton>
          }
        />
      </React.Fragment>
    );
  }
}

ActivePlansSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  activePlans: PropTypes.array.isRequired,
};

export default withStyles(styles)(ActivePlansSlider);
