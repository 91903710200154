import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Card, CardActions, CardMedia, CardContent, Typography} from '@material-ui/core';
import AssignCardDialog from './AssignCardDialog';
import { actions as cardsActions } from '../../actions/cardsActions';
import { actions as termsAndConditionsActions } from '../../actions/termsAndConditionsActions';
import i18n from '../../i18n';

class CardDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignDialogVisible: false,
      submitting: false,
    };

    this.handleStartAssignCard = this.handleStartAssignCard.bind(this);
    this.handleSubmitAssignCard = this.handleSubmitAssignCard.bind(this);
    this.handleCancelAssignCard = this.handleCancelAssignCard.bind(this);
  }

  handleStartAssignCard() {
    if (this.props.termsAcceptanceRequired) {
      this.props.termsActions.showDialog(this.props.card.tenants[0].tenantId);
      return;
    }

    this.setState({ assignDialogVisible: true });
  }

  handleSubmitAssignCard(data) {
    this.setState({ submitting: true });
    this.props.actions.assign(this.props.card.cardProviderId, data)
      .then(() => this.setState({ assignDialogVisible: false, submitting: false }));
  }

  handleCancelAssignCard() {
    this.setState({ assignDialogVisible: false });
  }

  render() {
    const { card } = this.props;
    return (
      <React.Fragment>
        <Card elevation={0} style={{background: 'transparent'}}>
          <CardContent>
            <Card elevation={5} style={{position: 'relative'}}>
              <CardMedia image={card.imageUrl} style={{height: 0, paddingTop: '50%'}} />
              {card.temporarilyUnavailable && <div
                style={{ position: 'absolute', top: 0, width: '100%',
                  height: '100%', backgroundColor: 'black', opacity: 0.9,
                  color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center',
                  fontSize: 30,
              }}
              >
                {i18n.t('cards.availableSoon')}
              </div>}
            </Card>
            {card.tenants.map(tenant => (
              <React.Fragment key={tenant.tenantId}>
                <Typography variant="subtitle1" style={{marginTop: 20}} display="block">{tenant.name}</Typography>
                <Typography variant="caption" color="textSecondary" display="block">{tenant.description}</Typography>
              </React.Fragment>))}
          </CardContent>
          {!card.temporarilyUnavailable && <CardActions disableSpacing>
            <Button color="secondary" onClick={this.handleStartAssignCard}>
              {i18n.t('cards.assignButton')}
            </Button>
          </CardActions>}
        </Card>
        {<AssignCardDialog
          card={card}
          open={this.state.assignDialogVisible}
          onSubmit={this.handleSubmitAssignCard}
          onCancel={this.handleCancelAssignCard}
          submitting={this.state.submitting}
        />}
      </React.Fragment>
    );
  }
}

CardDetails.propTypes = {
  card: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  termsActions: PropTypes.object.isRequired,
  termsAcceptanceRequired: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(cardsActions, dispatch),
    termsActions: bindActionCreators(termsAndConditionsActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(CardDetails);
