import { actions as userActions } from './userActions';
import { actions as walletActions } from './walletActions';
import { actions as zonesActions } from './zonesActions';

export const types = {
  PRELOAD_DATA: 'PRELOAD_DATA',
  PRELOAD_DATA_SUCCESS: 'PRELOAD_DATA_SUCCESS',
  PRELOAD_DATA_FAILURE: 'PRELOAD_DATA_FAILURE',
};

export const actions = {
  preloadData: () => (dispatch, getState) => {
    dispatch(userActions.resetAssignPhoneNumber())
    const auth = getState().auth;
    if (auth.signedIn) {
      dispatch({ type: types.PRELOAD_DATA });
      return Promise.all(
        [
          dispatch(userActions.loadUserState()),
          dispatch(zonesActions.getZones()),
          dispatch(walletActions.loadWallet())
        ])
        .then(() => dispatch({ type: types.PRELOAD_DATA_SUCCESS }))
        .catch(() => dispatch({ type: types.PRELOAD_DATA_FAILURE }));
    }
  }
};
