import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Button, Paper, Grid, Typography, Switch} from '@material-ui/core';
import { actions as userActions } from '../../actions/userActions';
import { actions as userCampaignActions } from '../../actions/userCampaignActions';
import LanguagePicker from './LanguagePicker';
import UpdateProfileDialog from './UpdateProfileDialog';
import i18n from '../../i18n';
import AssignPhoneNumber from "./assignPhoneNumber";

class UserProfileDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      submitting: false,
      prefixNumber: '+48'
    };

    this.handleStart = this.handleStart.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handlerNewsletter = this.handlerNewsletter.bind(this);
    this.handleAssignPhoneNumber = this.handleAssignPhoneNumber.bind(this);
    this.handleSubmitVerificationCode = this.handleSubmitVerificationCode.bind(this);
    this.props.campaignActions.getUserSubscribeCampaign();
  }

  handlerNewsletter(_, value){
    this.props.campaignActions.subscribeCampaign(value);
  }

  handleStart() {
    this.setState({ dialogVisible: true });
  }

  handleSubmit(data) {
    this.setState({ submitting: true });
    this.props.actions.updateDetails(data)
      .then(() => this.setState({ dialogVisible: false, submitting: false }));
  }

  handleCancel() {
    this.setState({ dialogVisible: false });
  }

  handleLanguageChange(language) {
    this.props.actions.updatePreferredLanguage(language);
  }

  handleAssignPhoneNumber(phoneNumberPrefix, phoneNumber, reCaptchaToken) {
    this.props.actions.assignPhoneNumber(phoneNumberPrefix, phoneNumber, reCaptchaToken)
  }

  handleSubmitVerificationCode({code}){
    this.props.actions.assignPhoneNumberVerificationCode(code)
  }

  render() {
    const { userProfile, userCampaign } = this.props;
    return (
      <div style={{margin: 16}}>
        <Grid container justify="center">
          <Grid item xs={12} md={8}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={12} sm={9}>
                <Typography variant="h4" gutterBottom>
                  {i18n.t('userProfile.header')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} style={{textAlign: 'right'}}>
                <Button color="secondary" onClick={this.handleStart}>{i18n.t('userProfile.updateProfileButton')}</Button>
              </Grid>
            </Grid>
            <Paper style={{padding: 16}}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <Typography variant="body2">
                    {i18n.t('userProfile.firstName')}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={9}>
                  <Typography variant="body1">
                    {userProfile.firstName}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography variant="body2">
                    {i18n.t('userProfile.lastName')}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={9}>
                  <Typography variant="body1">
                    {userProfile.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography variant="body2">
                    {i18n.t('userProfile.phoneNumber')}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={9}>
                  <Typography variant="body1">
                    {userProfile.phoneNumber}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography variant="body2">
                    {i18n.t('userProfile.email')}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={9}>
                  <Typography variant="body1">
                    {userProfile.email}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            <Paper style={{padding: 16, marginTop: 24}}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <Typography variant="body2">
                    {i18n.t('userProfile.callCenterCode')}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={9}>
                  <Typography variant="body1">
                    {userProfile.callCenterCode}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography variant="body2">
                    {i18n.t('userProfile.language')}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={9}>
                  <LanguagePicker onChange={this.handleLanguageChange} selectedLanguage={userProfile.preferredLanguage} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography variant="body2">
                    {i18n.t('campaign.newsletter')}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={9}>
                  <Switch
                    checked={userCampaign.isSubscribe}
                    name="newsletter"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    onChange={this.handlerNewsletter}
                  />
                </Grid>
              </Grid>
            </Paper>
            {userProfile.phoneNumber === null && <AssignPhoneNumber
              handleSubmitAssign={this.handleAssignPhoneNumber}
              assignPhoneNumber={this.props.assignPhoneNumber}
              handleSubmitVerificationCode={this.handleSubmitVerificationCode}
            />}
          </Grid>
        </Grid>
        <UpdateProfileDialog
          open={this.state.dialogVisible}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
          submitting={this.state.submitting}
          userProfile={{firstName: userProfile.firstName, lastName: userProfile.lastName, email: userProfile.email}}
        />
      </div>
    );
  }
}

UserProfileDetails.propTypes = {
  actions: PropTypes.object.isRequired,
  campaignActions: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
  userCampaign: PropTypes.object.isRequired,
  assignPhoneNumber: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
    campaignActions: bindActionCreators(userCampaignActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    userProfile: state.userState.userProfile || {},
    userCampaign: state.userCampaign || {},
    assignPhoneNumber: state.userState.assignPhoneNumber
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileDetails);
