import initialState from './initialState';
import localStorageProvider from '../utils/localStorageProvider';
import Immutable from 'seamless-immutable';
import { types } from '../actions/authActions';
import { AuthModel } from '../models/auth/AuthModel';
import config from '../config';

export default function authReducer(state = Immutable(new AuthModel(localStorageProvider.getItem(config.TOKEN_NAME))), action) {
  switch (action.type) {
    case types.REQUEST_OAUTH2_CODE:
      return state.merge({ oauth2: { provider: action.provider, url: null, isFetching: true, error: false }});
    case types.REQUEST_OAUTH2_CODE_SUCCESS:
      return state.merge({ oauth2: { provider: action.provider, url: action.url, isFetching: true, error: false }});
    case types.REQUEST_OAUTH2_CODE_FAILURE:
      return state.merge({ oauth2: { provider: action.provider, url: null, isFetching: false, error: true, }});
    case types.REQUEST_OAUTH2_LOGIN:
      return state.merge({ oauth2: { provider: action.provider, isFetching: true, error: false, errorData: null }});
    case types.REQUEST_OAUTH2_LOGIN_SUCCESS:
      return state.merge({ oauth2: { provider: action.provider, isFetching: false, error: false }});
    case types.REQUEST_OAUTH2_LOGIN_FAILURE:
      return state.merge({ oauth2: { provider: action.provider, isFetching: false, error: true, errorData: action.error  }});
    case types.REQUEST_SMS_CODE_SUCCESS:
      return state.merge({ authenticationToken: action.token, phoneNumber: action.phoneNumber, authenticationProgress: 2 });
    case types.REQUEST_SMS_CODE_FAILURE:
      return state.merge(initialState.auth);
    case types.REQUEST_EMAIL_CODE_SUCCESS:
      return state.merge({ authenticationToken: action.token, email: action.email, authenticationProgress: 2 });
    case types.REQUEST_EMAIL_CODE_FAILURE:
      return state.merge(initialState.auth);
    case types.FINISH_REGISTRATION:
      return state.merge({ authenticationProgress: 3, authenticationToken: action.token ? action.token : state.authenticationToken, email: action.email, registrationAsEmail: action.registrationAsEmail,
        oauth2: {
          ...state.oauth2,
          ...action.oauth2
        } });
    case types.SIGNED_IN:
      return state.merge(new AuthModel(action.token));
    case types.START_NEW_PHONE_NUMBER_VERIFICATION_SUCCESS: {
      const phoneNumber = action.phoneNumberPrefix + action.phoneNumber;
      return state.merge({ newPhoneNumberVerificationInProgress: true, newPhoneNumber: phoneNumber });
    }
    case types.START_NEW_PHONE_NUMBER_VERIFICATION_FAILURE:
      return state.merge({ newPhoneNumberVerificationInProgress: false, newPhoneNumber: null });
    case types.COMPLETE_NEW_PHONE_NUMBER_VERIFICATION_SUCCESS:
      return state.merge({ newPhoneNumberVerificationInProgress: false, newPhoneNumber: null });
    case types.SIGNED_OUT:
      return state.merge(initialState.auth);
    case types.VERIFY_EMAIL_CODE_REQUEST:
      return state.merge({ verifyEmailErrorCode: null, verifyEmailSuccess: false, verifyEmailInProgress: true });
    case types.VERIFY_EMAIL_CODE_SUCCESS:
      return state.merge({ verifyEmailErrorCode: null, verifyEmailSuccess: true, verifyEmailInProgress: false });
    case types.VERIFY_EMAIL_CODE_FAILURE:
      return state.merge({ verifyEmailErrorCode: action.verifyEmailErrorCode, verifyEmailSuccess: false, verifyEmailInProgress: false });
    case types.CONFIRM_NEW_PHONE_NUMBER_REQUEST:
      return state.merge({ confirmEmailInProgress: true, confirmEmailSuccess: false });
    case types.CONFIRM_NEW_PHONE_NUMBER_SUCCESS:
      return state.merge({ confirmEmailInProgress: false, confirmEmailSuccess: true });
    case types.CONFIRM_NEW_PHONE_NUMBER_FAILURE:
      return state.merge({ confirmEmailInProgress: false, confirmEmailSuccess: false });
    default:
      return state;
  }
}
