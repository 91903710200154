export function error(err) {
  /* eslint-disable no-console */
  console.error(err);

  if (window.Rollbar) {
    window.Rollbar.error(err, (rollbarError, rollbarData) => {
      if (rollbarError) {
        return console.log(`Error while reporting error to Rollbar: ${rollbarError}`);
      }

      return console.log(`Error successfully reported to Rollbar. UUID: ${rollbarData.result.uuid}`);
    });
  }
  /* eslint-enable no-console */
}
