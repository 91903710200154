import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/plansActions';
import { PlanModel } from '../models/PlanModel';

export default function plansReducer(state = Immutable(initialState.plans), action) {
   switch (action.type) {
    case types.GET_PLANS:
      return state.merge({ fetching: true, error: null });
    case types.GET_PLANS_SUCCESS:
      return state.merge({
        fetching: false,
        error: null,
        data: action.plans.map(p => new PlanModel(p.id, p.name, p.description, p.price, p.validity, p.freeMinutesPerDay, p.tenant)),
      });
    case types.GET_PLANS_FAILURE:
      return state.merge({ fetching: false, error: true });
    default:
      return state;
  }
}
