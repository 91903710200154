import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const ContactDialog = ({ open, fullScreen, onCancel }) => {
  const [t] = useTranslation();
  return (
    <Dialog open={open} fullScreen={fullScreen} fullWidth={true} onClose={onCancel}>
      <DialogTitle>{t('contact.dialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>{t('contact.dialog.phoneNumber')} <a href="tel:+48223005119">+48 22 300 51 19</a></p>
          <p>{t('contact.dialog.email')} <a href="mailto:bok@roovee.eu">bok@roovee.eu</a></p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          {t('common.closeButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ContactDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withMobileDialog()(ContactDialog);
