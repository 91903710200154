import i18n from '../i18n';
import zonesApi from '../api/zonesApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';

export const types = {
  GET_ZONES: 'GET_ZONES',
  GET_ZONES_SUCCESS: 'GET_ZONES_SUCCESS',
  GET_ZONES_FAILURE: 'GET_ZONES_FAILURE',
};

export const actions = {
  getZones: () => dispatch => {
    dispatch({ type: types.GET_ZONES });
    return zonesApi
      .getAllZones()
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.GET_ZONES_SUCCESS, zones: response.data });
        } else {
          dispatch({ type: types.GET_ZONES_FAILURE });
          dispatch(notificationActions.showError(i18n.t('map.actions.loadZonesFailure')));

          return logger.error(response.data);
        }
      });
  },
};
