import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { Button, ListItemIcon, ListItem } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/BuildTwoTone';
import { actions as bikesActions } from '../../actions/bikesActions';
import BikeDefectDialog from './BikeDefectDialog';

const styles = theme => ({
  button: theme.mixins.gutters({
    borderRadius: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    height: 48,
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
    },
  }),
  navItem: {
    ...theme.typography.body2,
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
});

class BikeDefectButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      submitting: false,
    };

    this.handleStart = this.handleStart.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleStart() {
    this.setState({ dialogVisible: true });
  }

  handleSubmit(data) {
    this.setState({ submitting: true });
    this.props.actions.reportDefect(data)
      .then(result => {
        if (result) this.setState({ dialogVisible: false });
      })
      .finally(() => this.setState({ submitting: false }));
  }

  handleCancel() {
    this.setState({ dialogVisible: false });
  }

  render() {
    const { classes, t } = this.props;

    return (
      <React.Fragment>
        <ListItem className={classes.navItem} disableGutters>
          <Button onClick={this.handleStart} className={classes.button}>
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            {t('bikeDefects.reportDefectButton')}
          </Button>
        </ListItem>
        <BikeDefectDialog
          open={this.state.dialogVisible}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
          submitting={this.state.submitting}
        />
      </React.Fragment>
    );
  }
}

BikeDefectButton.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(bikesActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(withTranslation()(BikeDefectButton)));
