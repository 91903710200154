import React, {useCallback, useEffect, useMemo, useState} from 'react'
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {actions as authActions} from "../../../actions/authActions";
import queryString from "query-string";
import {connect} from "react-redux";
import AnonymousContentPanel from "../AnonymousContentPanel";
import DocumentTitle from "react-document-title";
import jwtDecode from "jwt-decode";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";


const ConfirmNewPhoneNumberPage = ({authActions, auth, token}) => {

  const [t] = useTranslation();
  const [error, setError] = useState('changePhoneNumber.confirmNewPhoneNumber.failure')
  const tokenExpired = useMemo(() => {
    const decoded = jwtDecode(token);
    const tokenExpiresAt = moment.unix(decoded.exp);
    return tokenExpiresAt < moment();
  }, [token]);


  const handleConfirmNewPhoneNumber = useCallback(async () => {
    if(token) {
      const res = await authActions.confirmNewPhoneNumber(token);
      if (!res.success){
        if(res.failure && res.failure.errorCode === 'errorCode.userManagement.mobileLogin.confirmNewUserPhoneNumberWrongToken')
        setError('changePhoneNumber.tokenExpired')
      }
    }
  }, [token])

  useEffect(() => {
    if(!tokenExpired) {
      handleConfirmNewPhoneNumber().then()
    }
  }, [handleConfirmNewPhoneNumber, tokenExpired]);

  return (
    <DocumentTitle title="ROOVEE">
      <AnonymousContentPanel>
        {tokenExpired && <span>{t('changePhoneNumber.tokenExpired')}</span>}
        {!tokenExpired && auth.confirmEmailInProgress && <span>{t('changePhoneNumber.confirmNewPhoneNumber.processing')}</span>}
        {!tokenExpired && !auth.confirmEmailInProgress && auth.confirmEmailSuccess && <span>{t('changePhoneNumber.confirmNewPhoneNumber.success')}</span>}
        {!tokenExpired && !auth.confirmEmailInProgress && !auth.confirmEmailSuccess && <span>{t(error)}</span>}
      </AnonymousContentPanel>
    </DocumentTitle>
  )
}

ConfirmNewPhoneNumberPage.propTypes = {
  authActions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

function mapStateToProps(state, ownProps) {
  const query = queryString.parse(ownProps.location.search);
  return {
    auth: state.auth,
    token: query.token,
    lang: query.lang,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmNewPhoneNumberPage);
