import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import DocumentTitle from 'react-document-title';
import PriceList from './PriceList';
import i18n from '../../i18n';

class PricingPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { operator } = this.props;
    return (
      <DocumentTitle title={i18n.t('pricing.title')}>
        <div className="page">
          <div style={{margin: 16}}>
            <Grid container justify="center">
              <Grid item xs={12} md={8}>
                <Typography variant="h4" gutterBottom>{i18n.t('pricing.title')} - {operator.name}</Typography>
                <PriceList priceList={operator.pricing} />
              </Grid>
            </Grid>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

PricingPage.propTypes = {
  operator: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    operator: state.operators.data.find(o => o.id === ownProps.match.params.operator),
  };
}

export default connect(mapStateToProps)(PricingPage);
