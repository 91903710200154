import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/ridesActions';
import DocumentTitle from 'react-document-title';
import RidesList from './RidesList';
import routePaths from '../../routePaths';
import i18n from '../../i18n';

class RidesPage extends Component {
  constructor(props) {
    super(props);
    this.handleFetchRides = this.handleFetchRides.bind(this);
    this.handleNavigateToDetails = this.handleNavigateToDetails.bind(this);
  }

  componentDidMount() {
    this.props.actions.getRides(this.props.pageSize, this.props.pageNumber);
  }

  handleFetchRides(pageSize, pageNumber) {
    this.props.actions.getRides(pageSize, pageNumber);
  }

  handleNavigateToDetails(rideId) {
    this.props.history.push(`${routePaths.rides}/${rideId}`);
  }

  render() {
    const { rides, totalRidesCount, pageSize, pageNumber } = this.props;
    return (
      <DocumentTitle title={i18n.t('rides.title')}>
        <div className="page">
          <div style={{ margin: 16 }}>
            <Grid container justify="center">
              <Grid item xs={12} md={8}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={12} sm={9}>
                    <Typography variant="h4" gutterBottom>
                      {i18n.t('rides.title')}
                    </Typography>
                  </Grid>
                </Grid>
                <RidesList
                  i18n={i18n}
                  rides={rides}
                  totalRidesCount={totalRidesCount}
                  pageSize={pageSize}
                  pageNumber={pageNumber}
                  onFetchRides={this.handleFetchRides}
                  onOpenDetails={this.handleNavigateToDetails} />
              </Grid>
            </Grid>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    rides: state.ridesHistory.data,
    totalRidesCount: state.ridesHistory.totalDataCount,
    pageNumber: state.ridesHistory.pageNumber,
    pageSize: state.ridesHistory.pageSize,
  };
}

RidesPage.propTypes = {
  rides: PropTypes.array.isRequired,
  totalRidesCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RidesPage);
