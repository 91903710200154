import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemText, ListItemSecondaryAction, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import routePaths from '../../routePaths';

const OperatorsList = ({ operators }) => {
  const [t] = useTranslation();
  return (
    <Paper>
      <List>
        {operators.map(operator => (
          <ListItem key={operator.id}>
            <ListItemText primary={operator.name} secondary={operator.fullName} />
            <ListItemSecondaryAction>
              <Button
                size="small"
                color="secondary"
                component={Link}
                to={routePaths.operatorTerms.replace(':operator', operator.id)}
              >
                {t('operators.termsAndConditionsButton')}
              </Button>
              <Button
                size="small"
                color="secondary"
                component={Link}
                to={routePaths.operatorPricing.replace(':operator', operator.id)}
                disabled={!operator.pricing}
              >
                {t('operators.pricingButton')}
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}

OperatorsList.propTypes = {
  operators: PropTypes.array.isRequired,
};

export default OperatorsList;
