import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, makeStyles,
} from '@material-ui/core';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import withMobileDialog from "@material-ui/core/withMobileDialog";

const useStyles = makeStyles(() => ({
  content: {
    marginBottom: 10
  },
  data: {
    marginBottom: 5,
  },
}));

const WithdrawalFundsViewDialog = ({ open, fullScreen, onSubmit, onCancel, submitting, withdrawalData }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <Dialog open={open} fullScreen={fullScreen} fullWidth={true}>
      <DialogTitle>{t('withdrawalFunds.subtitle')}</DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <div>
            <span>{t('withdrawalFunds.form.fullName')}: </span><span>{withdrawalData.firstName} {withdrawalData.lastName}</span>
          </div>
          <div><span>{t('withdrawalFunds.form.address')}: </span><span>{withdrawalData.address}</span></div>
          <div>
            <span>{t('withdrawalFunds.form.accountNumber')}: </span><span>{withdrawalData.countryCode} {withdrawalData.accountNumber.replaceAll(/\D/g, '')}</span>
          </div>
          <div><span>{t('withdrawalFunds.form.swift')}: </span><span>{withdrawalData.swift}</span></div>

          <div><span>{t('withdrawalFunds.form.amount')}: </span><span>{withdrawalData.amount} PLN</span></div>
        </div>
        <div>{t('withdrawalFunds.warningCheckData')}</div>
      </DialogContent>
      <DialogActions>
      <Button onClick={onCancel} disabled={submitting}>
          {t('withdrawalFunds.button.edit')}
        </Button>
        <Button onClick={() => onSubmit(withdrawalData)} disabled={submitting} color="secondary">
          {t('withdrawalFunds.button.confirmForm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

WithdrawalFundsViewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  withdrawalData: PropTypes.object.isRequired,
};

export default connect()(withMobileDialog()(WithdrawalFundsViewDialog));
