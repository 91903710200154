import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/cardsActions';

export default function cardsReducer(state = Immutable(initialState.cards), action) {
   switch (action.type) {
    case types.GET_CARDS:
      return state.merge({ fetching: true });
    case types.GET_CARDS_SUCCESS:
      return state.merge({
        fetching: false,
        data: action.data,
      });
    case types.GET_CARDS_FAILURE:
      return state.merge({ fetching: false, data: null });
    default:
      return state;
  }
}
