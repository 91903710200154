import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleMap, GoogleMap, Marker, Polygon, Polyline, Circle } from 'react-google-maps';
import withScriptjs from 'react-google-maps/lib/withScriptjs';
import config from '../../config';
import _ from 'lodash';
import { ZoneAreaTypes } from '../../models/ZoneModel';

const getBoundingBox = (trace) => trace && trace.length && ({
  north: _.maxBy(trace, t => t.lat),
  south: _.minBy(trace, t => t.lat),
  east: _.maxBy(trace, t => t.lng),
  west: _.minBy(trace, t => t.lng),
});

const getMarkerIcon = zoneIconUrl => {
  return {
    url: zoneIconUrl,
    /* eslint-disable no-undef */
    scaledSize: new google.maps.Size(32, 32)
  };
};

const getCenter = (boundingBox) => boundingBox && ({
  lat: boundingBox.south.lat + (boundingBox.north.lat - boundingBox.south.lat) / 2,
  lng: boundingBox.west.lng + (boundingBox.east.lng - boundingBox.west.lng) / 2,
});

const latRad = (lat) => {
  const sin = Math.sin(lat * Math.PI / 180);
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
};

const getZoom = (boundingBox) => {
  if (!boundingBox) return null;
  const GLOBE_WIDTH = 256; // a constant in Google's map projection
  const pixelWidth = 400;

  const fraction = (latRad(boundingBox.north.lat) - latRad(boundingBox.south.lat)) / Math.PI;
  const LN2 = .693147180559945309417;
  const zoom = (Math.log(pixelWidth / GLOBE_WIDTH / fraction) / LN2);
  return _.min([18, zoom - 0.2]);
};

const shapeOptions = {
  operationsZone: {
    strokeColor: "#000",
    strokeOpacity: 0.2,
    strokeWeight: 4,
    fillColor: "#607D8B",
    fillOpacity: 0.1,
    clickable: false,
    zIndex: 1,
  },
  preferredBikeReturnZone: {
    strokeColor: "#e9e623",
    strokeOpacity: 0.4,
    strokeWeight: 2,
    fillColor: "#e9e623",
    fillOpacity: 0.2,
    clickable: false,
    zIndex: 2,
  },
};

const mapOptions = { streetViewControl: false, mapTypeControl: false, clickableIcons: false };
const MapViewRaw = withScriptjs(withGoogleMap(props => {
  const boundingBox = getBoundingBox(props.trace);
  const zoom = getZoom(boundingBox);
  const center = getCenter(boundingBox);

  return (
    <GoogleMap
      defaultZoom={props.initialZoom}
      defaultCenter={props.initialLocation}
      ref={props.onMapLoad}
      zoom={zoom}
      center={center}
      onClick={props.onMapClick}
      defaultOptions={mapOptions}
    >
      {props.markers ? props.markers.map((marker, key) => props.markerElement({ ...marker, location: { lat: marker.lat, lng: marker.lng }, key })) : null}
      {props.zones ? props.zones.filter(z => !z.marker && z.areaType === ZoneAreaTypes.polygon).map(zone => (
        <Polygon
          key={zone.id}
          path={zone.area}
          options={shapeOptions[zone.type]}
        />)) : null}
      {props.zones ? props.zones.filter(z => !z.marker && z.areaType === ZoneAreaTypes.circle).map(zone => (
        <Circle
          key={zone.id}
          options={shapeOptions[zone.type]}
          center={zone.area.center}
          radius={zone.area.radius}
        />)) : null}
      {props.zones ? props.zones.filter(z => z.marker).map(zone => (
        <Marker
          key={zone.id}
          icon={zone.marker.icon ? getMarkerIcon(zone.marker.icon) : null}
          position={zone.marker}
        />)) : null}
      {props.trace ? <Polyline path={props.trace} visible options={{ strokeColor: "#4d4d4d" }} /> : null}
    </GoogleMap>
  );
}));

const MapTraceView = (props) => (<MapViewRaw googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${config.GOOGLE_MAPS_API_KEY}`} {...props} />);

MapTraceView.propTypes = {
  markerElement: PropTypes.func.isRequired,
  initialLocation: PropTypes.object.isRequired,
  initialZoom: PropTypes.number.isRequired,
  markers: PropTypes.array,
  zones: PropTypes.array,
  trace: PropTypes.array,
};

export default MapTraceView;
