import i18n from '../i18n';
import {validateIBAN} from "ibantools";

export const WithdrawalFundsValidation = values => {
  const errors = {};
  const requiredFields = [
    'firstName',
    'lastName',
    'address',
    'countryCode',
    'accountNumber',
    'amount',
  ];



  if (values.accountNumber) {
    const iban = `${values.countryCode}${values.accountNumber.replaceAll(/\D/g,'').replaceAll(' ', '').trim()}`
    const ibanValid = validateIBAN(iban)
    if(!ibanValid.valid){
      if(ibanValid.errorCodes.includes(1) && ibanValid.errorCodes.length === 1) {
        errors.accountNumber = i18n.t('withdrawalFunds.error.wrongIbanPrefix');
      } else {
        errors.accountNumber = i18n.t('withdrawalFunds.error.wrongIban');
      }
    }
  }

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = i18n.t('common.validation.required');
    }
  });

  if (values['countryCode'] !== "PL") {
    if(!values['swift']) {
      errors['swift'] = i18n.t('common.validation.required');
    }
  }

  return errors;
};
