import React, {useMemo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions  from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useSelector} from "react-redux";
import {EmailStatus} from "../../models/UserStateModel";

const NoVerifyUserEmailDialog = () => {
  const [t] = useTranslation();

  const [close, setClose] = useState(false);
  const [typeOfString, setTypeOfString] = useState('noVerifyUserEmailDialog')

  const handleOnClose = () => {
    setClose(true);
  }
  const userState = useSelector(x => x.userState.userProfile);
  const canOpen = useMemo(() => {
    if(userState.emailStatus === EmailStatus.RequiredVerification || userState.emailStatus === EmailStatus.RequiredVerificationForOldUser) {
      setTypeOfString('noVerifyUserEmailDialog');
      return true;
    }
    if(userState.emailStatus === EmailStatus.RequiredVerificationForNewUser) {
      setTypeOfString('infoAboutRequiredVerifiedEmail');
      return true;
    }
    return false;
  }, [userState])

  return (
    <Dialog open={canOpen && !close}>
      <DialogTitle>{t(`verifyEmail.${typeOfString}.title`)}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" style={{textAlign: "justify"}}>
            {t(`verifyEmail.${typeOfString}.information`)}
          </Typography>
          <Typography variant="body1" style={{marginTop: 5, textAlign: "justify"}}>
            {t(`verifyEmail.${typeOfString}.information2`)}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} color="secondary">
          {t('common.closeButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NoVerifyUserEmailDialog;
