import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import NativeFormTextField from '../../form/NativeFormTextField';
import {emailFormValidation} from '../../../models/auth/AuthModelValidation';
import './SignInByEmailFormStyles.scss';
import FormCaptcha from "../../form/FormCaptcha";
import FormCheckBoxLabelWithChildren from "../../form/FormCheckBoxWithLink";

let SignInByEmailForm = ({ handleSubmit, submitting }) => {
  const [t] = useTranslation();
  return (
    <form onSubmit={handleSubmit}>
      <p>{t('signIn.form.emailHeader')}</p>
      <div className="form-group">
        <Field
          name="email"
          type="string"
          component={NativeFormTextField}
          label={t('signIn.form.emailLabel')}
          autoFocus
        />
        <Field
          name="reCaptchaToken"
          component={FormCaptcha}
          formName={'signInByEmail'}
          action={'registrationWebByEmail'}
        />
      </div>
      <div className="form-group">
        <Field
          name="terms"
          component={FormCheckBoxLabelWithChildren}
          label={
            <span>
              <span>{t('signIn.form.terms')}</span>
              <span><a className={'link-clear'} href={t('signIn.termsAndConditionsUrl')} title={t('signIn.form.termsLink')} target="_blank" rel="noopener noreferrer"> {t('signIn.form.termsLink')} </a> </span>
            </span>
          }
          autoFocus
        />
        <Field
          name="conditions"
          component={FormCheckBoxLabelWithChildren}
          label={
            <span>
              <span>{t('signIn.form.conditions')}</span>
              <span><a className={'link-clear'} href={t('signIn.conditionsUrl')} title={t('signIn.conditionsLink')} target="_blank" rel="noopener noreferrer">{t('signIn.conditionsLink')}</a> </span>
            </span>
          }
          autoFocus
        />
      </div>
      <button type="submit" className="btn btn-default" disabled={submitting}>
        {submitting ? t('signIn.form.submitButtonProgress') : t('signIn.form.submitButton')}
      </button>
    </form>
  );
};

SignInByEmailForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

SignInByEmailForm = reduxForm({
  form: 'signInByEmail',
  validate: emailFormValidation,
})(SignInByEmailForm);

export default SignInByEmailForm;
