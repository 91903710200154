import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Paper, SvgIcon, Table, TableRow, TableCell, TableBody, TableFooter } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/ridesActions';
import MapTraceView from '../map/MapTraceView';
import MapTraceMarker, { startTraceIcon, finishTraceIcon } from '../map/MapTraceMarker';
import NoTracePanel from './NoTracePanel';
import DocumentTitle from 'react-document-title';
import moment from 'moment';
import numberFormatter from '../../utils/numberFormatter';
import _ from 'lodash';
import './RideDetailsPage.scss';
import i18n from '../../i18n';

const PricingRow = ({ getName, amount, planName, amountClassName }) => (
  <TableRow>
    <TableCell>
      {getName()}
    </TableCell>
    <TableCell padding="none">
      {planName && <Typography variant="caption">{planName}</Typography>}
    </TableCell>
    <TableCell className={amountClassName ? `${amountClassName} amount-cell` : "amount-cell"} align="right">
      {numberFormatter.format(amount)}&nbsp;zł
    </TableCell>
  </TableRow>
);

PricingRow.propTypes = {
  getName: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  amountClassName: PropTypes.string,
  planName: PropTypes.string,
};

class RideDetailsPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.actions.getRide(this.props.match.params.rideId);
  }

  getMarkers(trace) {
    return [
      { ..._.first(trace), isStartMarker: true },
      { ..._.last(trace), isFinishMarker: true }
    ];
  }

  renderPricing() {
    const { ride } = this.props;
    return (
      <div className="panel-right">
        <h3>{i18n.t('rideDetails.pricingCalculation.title')}</h3>
        <Table style={{ width: 'inherit' }}>
          <TableBody>
            {ride.pricingCalculation ? ride.pricingCalculation.thresholdFares.map((x, key) => <PricingRow {...x} key={key} planName={ride.pricingCalculation.planName} />) : null}
            {ride.pricingCalculation ? ride.pricingCalculation.cardBenefits.map((x, key) => <PricingRow {...x} key={key} amountClassName="bonus-cell" />) : null}
            {ride.pricingCalculation ? ride.pricingCalculation.appliedDeductions.map((x, key) => <PricingRow {...x} key={key} planName={ride.pricingCalculation.planName} amountClassName="bonus-cell" />) : null}
            {ride.pricingCalculation ? ride.pricingCalculation.appliedPenalties.map((x, key) => <PricingRow {...x} key={key} planName={ride.pricingCalculation.planName} amountClassName="penalty-cell" />) : null}
            {ride.fareAdjustments ? ride.fareAdjustments.map((x, key) => <PricingRow {...x} key={key} />) : null}
          </TableBody>
          <TableFooter>
            <PricingRow getName={() => <span style={{ textTransform: 'uppercase' }}>{i18n.t('rideDetails.pricingCalculation.total')}</span>} amount={ride.fare} />
          </TableFooter>
        </Table>
      </div>
    );
  }

  render() {
    const { ride, zones } = this.props;
    return (
      <DocumentTitle title={i18n.t('rideDetails.titleWithDate', { date: moment(ride.startedAt).format('LLL') })}>
        <div className="page">
          <div style={{ margin: 16 }}>
            <Grid container justify="center">
              <Grid item xs={12} md={8}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={12} sm={9}>
                    <Typography variant="h4" gutterBottom>
                      {i18n.t('rideDetails.title')}
                    </Typography>
                  </Grid>
                </Grid>
                <Paper>
                  {ride.traceIsAccurate
                    ? <MapTraceView
                      initialLocation={{ lat: 0, lng: 0 }}
                      initialZoom={5}
                      loadingElement={<div style={{ height: '100%', width: 500, }} />}
                      containerElement={<div style={{ width: '100%', height: 400 }} />}
                      mapElement={<div style={{ width: '100%', height: 400 }} />}
                      onMapLoad={ref => this.mapRef = ref}
                      onMapClick={() => { }}
                      markers={this.getMarkers(ride.trace)}
                      onMarkerRightClick={() => { }}
                      markerElement={(props) => <MapTraceMarker {...props} />}
                      zones={zones}
                      trace={ride.trace}
                    />
                    : <NoTracePanel />
                  }
                  <div className="flex-row flex-wrap">
                    <div className="panel-right flex-column">
                      <h3>{i18n.t('rideDetails.rideLabel')}</h3>
                      <div className="flex-row flex-grow trace-summary">
                        <div className="flex-column margin-right-small align-items-center">
                          <SvgIcon><path d={startTraceIcon.path} /></SvgIcon>
                          <div className="flex trace-summary-separator" />
                          <SvgIcon><path d={finishTraceIcon.path} /></SvgIcon>
                        </div>
                        <div className="flex-column justify-space-between margin-right-small">
                          <div className="margin-bottom-large">
                            <Typography color="textSecondary">{i18n.t('rideDetails.startedAtLabel')}</Typography>
                            <Typography>{moment(ride.startedAt).local().format('HH:mm:ss')}</Typography>
                            <Typography>{moment(ride.startedAt).local().format('DD.MM.YYYY')}</Typography>
                          </div>
                          <div>
                            <Typography color="textSecondary">{i18n.t('rideDetails.finishedAtLabel')}</Typography>
                            <Typography>{moment(ride.finishedAt).local().format('HH:mm:ss')}</Typography>
                            <Typography>{moment(ride.finishedAt).local().format('DD.MM.YYYY')}</Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-right">
                      <h3 className="trace-summary-header">{i18n.t('rideDetails.rideLabel')}</h3>
                      <div>
                        <Typography color="textSecondary">{i18n.t('rideDetails.durationLabel')}</Typography>
                        <Typography className="value">{ride.duration}</Typography>
                        {ride.traceIsAccurate &&
                          <React.Fragment>
                            <Typography color="textSecondary">{i18n.t('rideDetails.distanceLabel')}</Typography>
                            <Typography className="value">{numberFormatter.format(ride.traceLengthInMeters / 1000)} km</Typography>
                          </React.Fragment>}
                        <Typography color="textSecondary">{i18n.t('rideDetails.bikeNumberLabel')}</Typography>
                        <Typography className="value">{ride.bikeNumber}</Typography>
                        {ride.rating != null && <React.Fragment>
                          <Typography color="textSecondary">{i18n.t('rideDetails.rideRatingLabel')}</Typography>
                          <Typography className="value">{`${ride.rating}/5`}</Typography>
                        </React.Fragment>}
                      </div>
                    </div>
                  </div>
                  <div>
                    {this.renderPricing(ride.pricingCalculation, ride.fare)}
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    ride: state.ridesHistory.details,
    zones: state.zones.data,
  };
}

RideDetailsPage.propTypes = {
  zones: PropTypes.array.isRequired,
  ride: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RideDetailsPage);
