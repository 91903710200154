import React from "react";
import {useTranslation} from "react-i18next";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Button, Dialog, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {actions} from '../../actions/walletActions'

const ConfirmAutomaticWithdrawalInfoModal = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const automaticWithdrawalInfos = useSelector(x => x.wallet.data.automaticWithdrawalInfos);
  const automaticWithdrawalInfo = automaticWithdrawalInfos ? automaticWithdrawalInfos[0] : null;

  const handleOnConfirm = (id) => {
    dispatch(actions.confirmAutomaticWithdraw(id))
  }

  if(!automaticWithdrawalInfo) return null;

  return (
    <Dialog open={true}>
      <DialogTitle>{t(`automaticWithdrawalInfoModal.warning`)}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" style={{textAlign: "justify"}}>
            {t(`automaticWithdrawalInfoModal.information`, {date: moment(automaticWithdrawalInfo.date).format('DD.MM.YYYY')})}
          </Typography>
          <Typography variant="body1" style={{marginTop: 5, textAlign: "justify"}}>
            {t(`automaticWithdrawalInfoModal.information2`)}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => {handleOnConfirm(automaticWithdrawalInfo.id)}}>
          {t(`automaticWithdrawalInfoModal.confirm`)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmAutomaticWithdrawalInfoModal;
