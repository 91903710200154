import apiBase from './apiBase';

class CardsApi {
  static get() {
    return apiBase.create().get('/mobile/cards');
  }

  static assign(data) {
    return apiBase.create().put('/mobile/cards/', data);
  }
  
  static remove(id) {
    return apiBase.create().delete(`/mobile/cards/${id}`);
  }
}

export default CardsApi;
