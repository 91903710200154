import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions  from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import numberFormatter from '../../utils/numberFormatter';

const PurchasePlanDialog = ({ open, onSubmit, onCancel, submitting, plan }) => {
  const [t] = useTranslation();
  return (
    <Dialog open={open}>
      <DialogTitle>{t('plans.purchasePlanDialog.title')}</DialogTitle>
      <DialogContent>
          <Typography variant="subtitle1">
            {plan.name}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t('plans.purchasePlanDialog.price')} {numberFormatter.format(plan.price)} zł
          </Typography>
          <Typography variant="caption">
            {t('plans.purchasePlanDialog.description')}
          </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={submitting}>
          {t('common.cancelButton')}
        </Button>
        <Button onClick={() => onSubmit(plan.id)} disabled={submitting} color="secondary">
          {t('plans.purchasePlanDialog.purchaseButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PurchasePlanDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  plan: PropTypes.object.isRequired,
};

export default PurchasePlanDialog;
