import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { actions as termsActions } from '../../actions/termsAndConditionsActions';
import SmallCheckBox from '../form/SmallCheckBox';
import routePaths from '../../routePaths';
import i18n from '../../i18n';

class AcceptTermsAndConditionsDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      terms: false,
      personalDataProcessingClause: false,
    }
  }

  render() {
    const { open, actions, terms, fullScreen } = this.props;
    return (
      <Dialog open={open} fullScreen={fullScreen}>
        <DialogTitle>{i18n.t('termsAndConditions.dialog.title')}</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            {terms.fetching
              ? <div style={{ textAlign: 'center' }}><CircularProgress size={50} color="secondary" /></div>
              : <React.Fragment>
                  <div>
                    <SmallCheckBox
                      label={<span>{i18n.t('termsAndConditions.dialog.termsAndConditionsLabel')} <Link
                        to={routePaths.operatorTerms.replace(':operator', terms.data.tenant)}
                        onClick={() => actions.hideDialog()}
                      >{i18n.t('termsAndConditions.dialog.termsAndConditionsLabelUrl')}</Link></span>}
                      onChange={event => this.setState({ terms: event.target.checked })}
                    />
                    
                  </div>
                  <div>
                    <SmallCheckBox
                      label={terms.data.personalDataProcessingClause}
                      onChange={event => this.setState({ personalDataProcessingClause: event.target.checked })}
                    />
                  </div>
                </React.Fragment>
            }
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => actions.hideDialog()}>
            {i18n.t('common.cancelButton')}
          </Button>
          <Button
            onClick={() => actions.acceptTermsAndConditions(terms.data.tenant, terms.data.version)}
            color="secondary"
            disabled={!this.state.terms || !this.state.personalDataProcessingClause}
          >
            {i18n.t('termsAndConditions.dialog.acceptButton')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AcceptTermsAndConditionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
  terms: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(termsActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    terms: state.termsAndConditions,
    open: state.termsAndConditions.displayDialog,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(AcceptTermsAndConditionsDialog));
