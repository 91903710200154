import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import DocumentTitle from 'react-document-title';
import AnonymousContentPanel from '../AnonymousContentPanel';
import SmsCodeVerificationForm from '../SmsCodeVerificationForm';
import FinishRegistrationForm from './FinishRegistrationForm';
import './SignInPageStyles.scss';
import SelectSignInType from "./SelectSignInType";
import {LoginMode, Oauth2Provider} from "../../../models/auth/AuthModel";
import {useLocation} from "react-router";
import {actions as authActions} from "../../../actions/authActions";

const SignInPage = ({ authActions, auth }) => {
  const [loginMode, setLoginMode] = useState(LoginMode.select);
  const params = useLocation();
  const onVerifyCode = (code, authenticationToken) => {
    switch (loginMode){
      case LoginMode.sms: authActions.verifySmsCode(code, authenticationToken); break;
      case LoginMode.email: authActions.verifyEmailCode(code, authenticationToken); break;
      default: break;
    }
  }

  useEffect(() => {
    const urlSearchParams =  new URLSearchParams(params.search);
    const mode = urlSearchParams.get('loginMode');
    if(mode === Oauth2Provider.plockCard) {
      setLoginMode(LoginMode.oauth2)
      authActions.requestOauth2Url(Oauth2Provider.plockCard)
    }
  }, []);

  return (
    <DocumentTitle title="ROOVEE">
      <AnonymousContentPanel>
        {auth.authenticationProgress === 1 && <SelectSignInType
          loginMode={loginMode}
          setLoginMode={setLoginMode}
        />}
        {auth.authenticationProgress === 2 && <SmsCodeVerificationForm
          onSubmit={data => onVerifyCode(data.code, auth.authenticationToken)}
          phoneNumber={auth.phoneNumber}
          email={auth.email}
          loginMode={loginMode}
        />}
        {auth.authenticationProgress === 3 && <FinishRegistrationForm
          registrationAsEmail={auth.registrationAsEmail}
          initialValues={{
            email: auth.registrationAsEmail ? auth.email : null
          }}
          onSubmit={data => authActions.finishRegistration(data, auth.authenticationToken)}

        />}
      </AnonymousContentPanel>
    </DocumentTitle>
  );
}

SignInPage.propTypes = {
  authActions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
