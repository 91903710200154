import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';
import { Grid } from '@material-ui/core';
import Wallet from './dashboard/Wallet';
import UserStateCard from './userState/UserStateCard';
import BikesMapCard from './map/BikesMapCard';
import config from '../config';
import {useHistory} from "react-router";

const HomePage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <DocumentTitle title={t('dashboard.title')}>
      <div className="page">
        <div style={{margin: 16}}>
          <Grid container justify="center" alignItems="stretch" spacing={2}>
            <Grid item xs={12} md={4}>
              <Wallet
                history={history}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <UserStateCard />
            </Grid>
            <Grid item xs={12} md={8}>
              <BikesMapCard
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${config.GOOGLE_MAPS_API_KEY}`}
                loadingElement={<div />}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </DocumentTitle>
  );
}

export default HomePage;
