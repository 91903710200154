import React from 'react'
import AnonymousContentPanel from "../../AnonymousContentPanel";
import DocumentTitle from "react-document-title";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import routePaths from "../../../../routePaths";
import {useTranslation} from "react-i18next";

const Oauth2FailurePage = () => {
  const [t] = useTranslation();
  return (
    <DocumentTitle title="ROOVEE">
      <AnonymousContentPanel>
        <div>
          <div style={{fontSize: 18}}>{t('oauth2.error.line1')}</div>
          <div style={{fontSize: 18}}>{t('oauth2.error.line2')}</div>
          <div style={{marginTop: 15}}>
            <Button component={Link}
                    to={routePaths.home}
                    className="btn btn-default"
            >
              {t('oauth2.redirectToMainPage')}
            </Button>
          </div>
        </div>
      </AnonymousContentPanel>
    </DocumentTitle>
  )
}

export default Oauth2FailurePage;
