import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import NativeFormTextField from '../../form/NativeFormTextField';
import NativeFormSelect from '../../form/NativeFormSelect';
import { phoneNumberFormValidation } from '../../../models/auth/AuthModelValidation';
import PhoneNumberPrefixes from '../../../models/PhoneNumberPrefixes'
import './NewPhoneNumberForm.scss';

let NewPhoneNumberForm = ({ handleSubmit, submitting }) => {
  const [t] = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <p>{t('changePhoneNumber.newNumberForm.header')}</p>
      <div className="form-group">
        <Field
          name="phoneNumberPrefix"
          component={NativeFormSelect}
          label={t('changePhoneNumber.newNumberForm.countryLabel')}
          values={PhoneNumberPrefixes.map(p => ({
            value: p.prefix,
            key: p.countryCode,
            name: `${p.prefix} ${t(`countries.${p.countryCode}`)}`,
          }))
          }
        />
      </div>
      <div className="form-group">
        <Field
          name="phoneNumber"
          type="number"
          component={NativeFormTextField}
          label={t('changePhoneNumber.newNumberForm.phoneNumberLabel')}
          fullWidth
          autoFocus
        />
      </div>
      <button type="submit" className="btn btn-default" disabled={submitting}>
        {submitting ? t('changePhoneNumber.newNumberForm.submitButtonProgress') : t('changePhoneNumber.newNumberForm.submitButton')}
      </button>
    </form>
  );
};

NewPhoneNumberForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

NewPhoneNumberForm = reduxForm({
  form: 'newPhoneNumber',
  validate: phoneNumberFormValidation,
  initialValues: { phoneNumberPrefix: '+48' }
})(NewPhoneNumberForm);

export default NewPhoneNumberForm;
