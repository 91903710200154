import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {Field, reduxForm} from "redux-form";
import FormTextField from "../form/FormTextField";
import {Button, makeStyles} from "@material-ui/core";
import PhoneNumberPrefixes from "../../models/PhoneNumberPrefixes";
import {useTranslation} from "react-i18next";
import FormSelect from "../form/FormSelect";
import {WithdrawalFundsValidation} from "../../models/WithdrawalFundsValidation";
import {useSelector} from "react-redux";

const useStyles = makeStyles(() => ({
  confirmButton: {
    marginTop: 10
  },
  input: {
    marginBottom: 5,
  },
  amountExceeded: {
    marginTop: 5,
    color: '#f62b2b'
  }
}));

let WithdrawalFundsForm = ({handleSubmit, availableToWithdrawal, disabled}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const state = useSelector(x => x.form.withdrawalFundsForm)
  const [showAmountExceededError, setShowAmountExceededError] = useState(false);

  const isNotAmountExceeded = () => {
    if(state && state.values && state.values.amount && state.fields && state.fields.amount && state.fields.amount.touched){
        if(state.values.amount > availableToWithdrawal){
          return true;
        }
    }
    return false;
  }

  useEffect(() => {
    setShowAmountExceededError(isNotAmountExceeded);
  }, [state])


  return(
    <form onSubmit={handleSubmit}>
      <div className={classes.input}>
        <Field
          name="firstName"
          component={FormTextField}
          label={t('withdrawalFunds.form.firstName')}
          fullWidth
          disabled={disabled}
        />
      </div>
      <div className={classes.input}>
        <Field
          name="lastName"
          component={FormTextField}
          label={t('withdrawalFunds.form.lastName')}
          fullWidth
          disabled={disabled}
        />
      </div>
      <div className={classes.input}>
        <Field
          name="address"
          component={FormTextField}
          label={t('withdrawalFunds.form.address')}
          fullWidth
          disabled={disabled}
        />
      </div>
      <div className={classes.input}>
        <Field
          fullWidth
          name="countryCode"
          component={FormSelect}
          label={t('withdrawalFunds.form.ibanPrefix')}
          values={PhoneNumberPrefixes.map(p => ({
            value: p.countryCode,
            key: p.countryCode,
            name: `${p.countryCode} ${t(`countries.${p.countryCode}`)}`,
          }))
          }
          disabled={disabled}
        />
      </div>
      <div className={classes.input}>
        <Field
          name="accountNumber"
          component={FormTextField}
          label={t('withdrawalFunds.form.accountNumber')}
          fullWidth
          disabled={disabled}
        />
      </div>
      <div className={classes.input}>
        <Field
          name="swift"
          component={FormTextField}
          label={t('withdrawalFunds.form.swift')}
          fullWidth
          disabled={disabled}
        />
      </div>
      <div className={classes.input}>
        <Field
          name="amount"
          type="number"
          component={FormTextField}
          label={t('withdrawalFunds.form.amount')}
          fullWidth
          disabled={disabled}
        />
      </div>
      {showAmountExceededError && <div>
        <div className={classes.amountExceeded}>{t('withdrawalFunds.error.amountExceededInfo')}</div>
        <div
          className={classes.amountExceeded}>{t('withdrawalFunds.error.amountExceeded', {maxAmountToWithdraw: availableToWithdrawal})}</div>
      </div>}
      <Button
        type="submit"
        className={`btn btn-default ${classes.confirmButton}`}
        color="primary"
        variant="contained"
      >
        {t('withdrawalFunds.button.confirm')}
      </Button>
    </form>
  )

}

WithdrawalFundsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  availableToWithdrawal: PropTypes.number,
  disabled: PropTypes.bool,
};

WithdrawalFundsForm.defaultProps = {
  availableToWithdrawal: 0.0,
  disabled: true,
};

WithdrawalFundsForm = reduxForm({
  form: 'withdrawalFundsForm',
  validate: WithdrawalFundsValidation,
})(WithdrawalFundsForm);

export default WithdrawalFundsForm;
