import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import secondary from '@material-ui/core/colors/lightBlue';

const theme = responsiveFontSizes(createMuiTheme({
  palette: {
    primary: {
      main: '#FDE900',
    },
    secondary,
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: secondary[600]
        }
      },
    }
  }
}));

export default theme;
