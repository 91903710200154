import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions  from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssignCardForm from './AssignCardForm';

const AssignCardDialog = ({ open, onSubmit, onCancel, submitting, dispatch, card }) => {
  const [t] = useTranslation();
  const isPKM = card.cardProviderId === "PKM"
  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle>{t('cards.assignCardDialog.titlePrefix')} {card.cardProviderId} - {t('cards.assignCardDialog.title')}</DialogTitle>
      <DialogContent>
        {!isPKM && card.tenants.map(tenant => (
          <React.Fragment key={tenant.tenantId}>
            <Typography display="block">{tenant.name}</Typography>
            <Typography variant="caption" display="block">{tenant.description}</Typography>
          </React.Fragment>
        ))}
        {!isPKM && <AssignCardForm
          onSubmit={onSubmit}
          requireBirthday={card.requireBirthday}
          initialValues={{
            requireBirthday: card.requireBirthday
          }}
        />}
        {isPKM && <div>
          <div>W celu aktywacji karty proszę zalogować się za pośrednictwem Karty Płockiej.</div>
        </div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={submitting}>
          {t('common.cancelButton')}
        </Button>
        {! isPKM && <Button onClick={() => dispatch(submit('assignCard'))} disabled={submitting} color="secondary">
          {t('cards.assignCardDialog.assignButton')}
        </Button>}
      </DialogActions>
    </Dialog>
  );
}

AssignCardDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  card: PropTypes.object.isRequired,
};

export default connect()(AssignCardDialog);
