import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { BikeParts } from '../../models/BikeParts';
import BikePartButton from './BikePartButton';

class BikePartsPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brokenParts: [],
    };

    this.handleBrokenPartChange = this.handleBrokenPartChange.bind(this);
  }

  handleBrokenPartChange(part, isSelected) {
    let brokenParts = [];
    if (isSelected) {
      brokenParts = [...this.state.brokenParts, part];
    } else {
      brokenParts =this.state.brokenParts.filter(p => p !== part);
    }
    
    this.setState({ brokenParts });
    this.props.onChange(brokenParts);
  }

  render() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.basket} onSelect={this.handleBrokenPartChange} />
        </Grid>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.bell} onSelect={this.handleBrokenPartChange} />
        </Grid>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.brakes} onSelect={this.handleBrokenPartChange} />
        </Grid>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.chain} onSelect={this.handleBrokenPartChange} />
        </Grid>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.drive} onSelect={this.handleBrokenPartChange} />
        </Grid>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.handlebar} onSelect={this.handleBrokenPartChange} />
        </Grid>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.lights} onSelect={this.handleBrokenPartChange} />
        </Grid>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.lock} onSelect={this.handleBrokenPartChange} />
        </Grid>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.qr} onSelect={this.handleBrokenPartChange} />
        </Grid>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.seat} onSelect={this.handleBrokenPartChange} />
        </Grid>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.phoneHolder} onSelect={this.handleBrokenPartChange} />
        </Grid>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.unusable} onSelect={this.handleBrokenPartChange} />
        </Grid>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.vandalism} onSelect={this.handleBrokenPartChange} />
        </Grid>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.kickstand} onSelect={this.handleBrokenPartChange} />
        </Grid>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.wheel} onSelect={this.handleBrokenPartChange} />
        </Grid>
        <Grid item xs={3}>
          <BikePartButton bikePart={BikeParts.childSeat} onSelect={this.handleBrokenPartChange} />
        </Grid>
      </Grid>
    );
  }
}

BikePartsPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default BikePartsPicker;
