export const ZoneTypes = {
  operationsZone: 'operationsZone',
  preferredBikeReturnZone: 'preferredBikeReturnZone',
};

export const ZoneAreaTypes = Object.freeze({
  polygon: 'polygon',
  circle: 'circle',
});

