import moment from 'moment';
import _ from 'lodash';
import i18n from '../i18n';

class FareAdjustmentModel {
  constructor(data) {
    this.amount = data.amount;
    this.getName = () => i18n.t('rideDetails.pricingCalculation.fareAdjustment');
  }
}

const deductionType = {
  bikeLeftInZone: { value: 'bikeLeftInZone', name: 'rideDetails.pricingCalculation.deductionType.bikeLeftInZone' },
};

class DeductionModel {
  constructor(data) {
    this.getName = () => i18n.t(deductionType[data.name].name);
    this.amount = data.amount;
  }
}

const penaltyType = {
  bikeLeftOutOfPreferredZone: { value: 'bikeLeftOutOfPreferredZone', name: 'rideDetails.pricingCalculation.penaltyType.bikeLeftOutOfPreferredZone' },
  bikeLeftOutOfOperatorZone: { value: 'bikeLeftOutOfOperatorZone', name: 'rideDetails.pricingCalculation.penaltyType.bikeLeftOutOfOperatorZone' },
};

class PenaltyModel {
  constructor(data) {
    this.getName = () => i18n.t(penaltyType[data.name].name);
    this.amount = data.amount;
  }
}

class CardBenefitModel {
  constructor(data) {
    this.getName = () => data.cardName[i18n.language.substring(0, 2)];
    this.amount = data.discount;
  }
}

const feeType = {
  free: { value: 'free', name: 'rideDetails.pricingCalculation.feeType.free' },
  fixed: { value: 'fixed', name: 'rideDetails.pricingCalculation.feeType.fixed' },
  perInterval: { value: 'perInterval', name: 'rideDetails.pricingCalculation.feeType.perInterval' },
};

class ThresholdFareModel {
  constructor(data) {
    this.amount = data.fare;
    const { isUnbounded, thresholdUpperBound, fee: { type } } = data.threshold;
    this.getName = () => i18n.t(feeType[type].name) + (isUnbounded
      ? ''
      : i18n.t('rideDetails.pricingCalculation.fareThreshold', { threshold: moment.duration(thresholdUpperBound).asMinutes() }));
  }
}

export class PricingCalculationModel {
  constructor({ thresholdFares = [], appliedDeductions = [], appliedPenalties = [], cardBenefits = [], planName }) {
    this.thresholdFares = thresholdFares.map(t => new ThresholdFareModel(t));
    this.appliedDeductions = appliedDeductions.map(d => new DeductionModel(d));
    this.appliedPenalties = appliedPenalties.map(p => new PenaltyModel(p));
    this.cardBenefits = (cardBenefits || []).map(p => new CardBenefitModel(p));
    this.totalFare = _.sum(this.thresholdFares.map(t => t.fare));
    this.planName = planName;
  }
}

export class HistoricalRideModel {
  constructor({ id, startedAt, finishedAt, fare, bikeNumber, trace, traceLengthInMeters, fareAdjustments = [], pricingCalculation, rating }) {
    this.id = id;
    this.startedAt = startedAt;
    this.finishedAt = finishedAt;
    this.bikeNumber = bikeNumber;
    this.fare = fare;
    this.duration = this.getDuration(startedAt, finishedAt);
    this.trace = trace.filter(t => !(t.lat === 0 && t.lng === 0));
    this.traceLengthInMeters = traceLengthInMeters;
    this.traceIsAccurate = this.checkTraceAccuracy();
    this.traceBoundingBox = this.getBoundingBox(trace);
    this.fareAdjustments = fareAdjustments.map(f => new FareAdjustmentModel(f));
    this.pricingCalculation = new PricingCalculationModel(pricingCalculation || {});
    this.rating = rating;
  }

  checkTraceAccuracy() {
    return this.trace && this.trace.length > 3;
  }

  getDuration(start, end) {
    const ms = moment(end).diff(moment(start));
    const duration = moment.duration(ms);
    const days = moment.utc(end).diff(moment(start), 'days');
    /* eslint no-underscore-dangle:0 */
    const formatTime = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
    if(days >= 1) {
      return `${days}:${formatTime}`
    }
    return formatTime;
  }

  getBoundingBox(trace) {
    if (!this.checkTraceAccuracy()) return null;
    const minLatitude = _.minBy(trace, t => t.lat).lat;
    const maxLatitude = _.maxBy(trace, t => t.lat).lat;
    const minLongitude = _.minBy(trace, t => t.lng).lng;
    const maxLongitude = _.maxBy(trace, t => t.lng).lng;
    return {
      longitude: minLongitude + ((maxLongitude - minLongitude) / 2),
      latitude: minLatitude + ((maxLatitude - minLatitude) / 2),
      longitudeDelta: (maxLongitude - minLongitude),
      latitudeDelta: (maxLatitude - minLatitude),
    };
  }
}
