import i18n from '../i18n';
import walletApi from '../api/walletApi';
import {actions as notificationActions} from './notificationActions';
import * as logger from '../utils/logger';

export const types = {
  GET_WALLET: 'GET_WALLET',
  GET_WALLET_SUCCESS: 'GET_WALLET_SUCCESS',
  GET_WALLET_FAILURE: 'GET_WALLET_FAILURE',
  TOP_UP: 'TOP_UP',
  TOP_UP_SUCCESS: 'TOP_UP_SUCCESS',
  TOP_UP_FAILURE: 'TOP_UP_FAILURE',
  CONFIRM_AUTOMATIC_WITHDRAW_INFO: 'CONFIRM_AUTOMATIC_WITHDRAW_INFO',
  CONFIRM_AUTOMATIC_WITHDRAW_INFO_SUCCESS: 'CONFIRM_AUTOMATIC_WITHDRAW_INFO_SUCCESS',
  CONFIRM_AUTOMATIC_WITHDRAW_INFO_FAILURE: 'CONFIRM_AUTOMATIC_WITHDRAW_INFO_FAILURE',
  WITHDRAWAL_REFUNDS_FORM_REQUEST: 'WITHDRAWAL_REFUNDS_FORM_REQUEST',
  WITHDRAWAL_REFUNDS_FORM_SUCCESS: 'WITHDRAWAL_REFUNDS_FORM_SUCCESS',
  WITHDRAWAL_REFUNDS_FORM_FAILURE: 'WITHDRAWAL_REFUNDS_FORM_FAILURE',
};

export const actions = {
  loadWallet: () => dispatch => {
    dispatch({type: types.GET_WALLET});
    return walletApi
      .getWallet()
      .then(response => {
        if (response.ok) {
          return dispatch({type: types.GET_WALLET_SUCCESS, wallet: response.data});
        }
        dispatch({type: types.GET_WALLET_FAILURE});
        dispatch(notificationActions.showError(i18n.t('wallet.actions.loadWalletFailure')));
        return logger.error(response);
      });
  },

  topUp: (amount, email) => dispatch => {
    dispatch({type: types.TOP_UP});
    return walletApi
      .createPayment(amount, email)
      .then(response => {
        if (response.ok) {
          const {paymentUrl} = response.data;
          return dispatch({type: types.TOP_UP_SUCCESS, paymentUrl});
        }

        dispatch({type: types.TOP_UP_FAILURE});
        dispatch(notificationActions.showError(i18n.t('wallet.actions.topUpFailure')));
        return logger.error(response);
      });
  },

  withdrawalFundsForm: (data) => dispatch => {
    dispatch({ type: types.WITHDRAWAL_REFUNDS_FORM_REQUEST });
    const parseData = {
      ...data,
      accountNumber: data.accountNumber.replace(/[^0-9\s]/g, '').trim(),
    };
    return walletApi
      .withdrawRefundsForm(parseData)
      .then(response => {
        if (response.ok) {
          if (response.data && response.data.failure) {
            dispatch(notificationActions.showError(i18n.t(response.data.failure.errorCode, response.data.failure.metadata)));
          } else if (response.data && response.data.errors) {
            if (response.data.errors.includes('Invalid IBAN')) {
              dispatch({ type: types.WITHDRAWAL_REFUNDS_FORM_FAILURE });
              dispatch(notificationActions.showError(i18n.t('errorCode.wallet.withdrawFunds.invalidIban')));
            }
            dispatch({ type: types.WITHDRAWAL_REFUNDS_FORM_FAILURE });
            dispatch(notificationActions.showError(i18n.t('errorCode.wallet.withdrawFunds.error')));
          }
          else {
            dispatch({ type: types.WITHDRAWAL_REFUNDS_FORM_SUCCESS });
            dispatch(notificationActions.showSuccess(i18n.t('withdrawalFunds.success')));
            return true
          }
        } else {
          dispatch({ type: types.WITHDRAWAL_REFUNDS_FORM_FAILURE });
          dispatch(notificationActions.showError(i18n.t('errorCode.wallet.withdrawFunds.error')));
        }
        return false
      });
  },

  confirmAutomaticWithdraw: (id) => dispatch => {
    dispatch({type: types.CONFIRM_AUTOMATIC_WITHDRAW_INFO});
    return walletApi.confirmAutomaticWithdraw(id).then(() => {
      dispatch(notificationActions.showSuccess(i18n.t('wallet.actions.confirmAutomaticWithdrawalInfoSuccess')));
      return dispatch({type: types.CONFIRM_AUTOMATIC_WITHDRAW_INFO_SUCCESS, id});
    })
      .catch(() => {
        return dispatch({type: types.CONFIRM_AUTOMATIC_WITHDRAW_INFO_FAILURE, id});
      });
  }
};
