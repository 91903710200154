import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './authReducer';
import notifications from './notificationsReducer';
import userState from './userStateReducer';
import wallet from './walletReducer';
import plans from './plansReducer';
import termsAndConditions from './termsAndConditionsReducer';
import bikes from './bikesReducer';
import zones from './zonesReducer';
import ride from './rideReducer';
import ridesHistory from './ridesHistoryReducer';
import operators from './operatorsReducer';
import cards from './cardsReducer';
import userCampaign from "./userCampaignStateReducer";
import { reducer as formReducer } from 'redux-form';

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  auth,
  notifications,
  form: formReducer,
  userState,
  wallet,
  plans,
  termsAndConditions,
  bikes,
  zones,
  ride,
  ridesHistory,
  operators,
  cards,
  userCampaign
});

export default rootReducer;

