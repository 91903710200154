import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as authActions } from '../../../actions/authActions';
import { useTranslation } from 'react-i18next';
import RequestPhoneNumberChangeForm from './RequestPhoneNumberChangeForm';
import AnonymousContentPanel from '../AnonymousContentPanel';

const RequestPhoneNumberChangePage = ({ authActions }) => {
  const [t] = useTranslation();
  return (
    <AnonymousContentPanel>
      <p>{t('changePhoneNumber.request.title')}</p>
      <RequestPhoneNumberChangeForm
        onSubmit={data => authActions.requestPhoneNumberChange(data)}
      />
    </AnonymousContentPanel>
  );
}

RequestPhoneNumberChangePage.propTypes = {
  authActions: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(RequestPhoneNumberChangePage);
