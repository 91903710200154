import i18n from '../i18n';
import termsAndConditionsApi from '../api/termsAndConditionsApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';
export const types = {
  GET_TERMS: 'GET_TERMS',
  GET_TERMS_SUCCESS: 'GET_TERMS_SUCCESS',
  GET_TERMS_FAILURE: 'GET_TERMS_FAILURE',
  ACCEPT_TERMS: 'ACCEPT_TERMS',
  ACCEPT_TERMS_SUCCESS: 'ACCEPT_TERMS_SUCCESS',
  ACCEPT_TERMS_FAILURE: 'ACCEPT_TERMS_FAILURE',
  DISPLAY_DIALOG: 'DISPLAY_DIALOG',
  HIDE_DIALOG: 'HIDE_DIALOG',
  GET_TENANTS: 'GET_TENANTS',
  GET_TENANTS_SUCCESS: 'GET_TENANTS_SUCCESS',
  GET_TENANTS_FAILURE: 'GET_TENANTS_FAILURE',
};

export const actions = {
  showDialog: tenant => dispatch => {
    dispatch({ type: types.DISPLAY_DIALOG });
    dispatch(actions.getTermsAndConditions(tenant));
  },

  hideDialog: () => dispatch => {
    dispatch({ type: types.HIDE_DIALOG });
  },
  
  getTermsAndConditions: tenant => dispatch => {
    dispatch({ type: types.GET_TERMS });
    return termsAndConditionsApi
      .getTermsAndConditions(tenant)
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.GET_TERMS_SUCCESS, terms: response.data });
        }

        dispatch({ type: types.GET_TERMS_FAILURE });
        dispatch(notificationActions.showError(i18n.t('termsAndConditions.actions.loadTermsFailure')));
        return logger.error(response);
      });
  },

  acceptTermsAndConditions: (tenant, version) => dispatch => {
    dispatch({ type: types.ACCEPT_TERMS });
    return termsAndConditionsApi
      .acceptTermsAndConditions(tenant, version)
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.ACCEPT_TERMS_SUCCESS, tenant });
        }

        dispatch({ type: types.ACCEPT_TERMS_FAILURE });
        dispatch(notificationActions.showError(i18n.t('termsAndConditions.actions.acceptTermsFailure')));
        return logger.error(response);
      });
  },

  getTenants: () => dispatch => {
    dispatch({ type: types.GET_TENANTS });
    return termsAndConditionsApi
      .getTenants()
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.GET_TENANTS_SUCCESS, tenants: response.data });
        }

        dispatch({ type: types.GET_TENANTS_FAILURE });
        dispatch(notificationActions.showError(i18n.t('operators.actions.loadTenantsFailure')));
        return logger.error(response);
      });
  },
};
