import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DocumentTitle from 'react-document-title';
import { Grid, Typography, Fade } from '@material-ui/core';
import yellow from '@material-ui/core/colors/yellow';
import _ from 'lodash';
import { actions } from '../../actions/plansActions';
import PlanDetailsCard from './PlanDetailsCard';
import TenantDetailsCard from './TenantDetailsCard';
import i18n from '../../i18n';

class PlansPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTenant: null,
    };

    this.renderTenants = this.renderTenants.bind(this);
    this.renderPlansByTenant = this.renderPlansByTenant.bind(this);
    this.renderActivePlanInfo = this.renderActivePlanInfo.bind(this);
  }

  componentDidMount() {
    this.props.actions.load();
  }

  renderTenants() {
    const plansByTenant = _.groupBy(this.props.plans.data, p => p.tenant);
    return Object.keys(plansByTenant).map(tenant => (
      <Grid item xs={12} sm={6} md={4} key={tenant}>
        <TenantDetailsCard
          tenant={tenant}
          plansCount={plansByTenant[tenant].length}
          selected={this.state.selectedTenant === tenant ? true : false}
          onClick={() => this.setState({ selectedTenant: null }, () => this.setState({ selectedTenant: tenant }))}
        />
      </Grid>
    ));
  }

  renderActivePlanInfo() {
    return this.props.activePlans.some(p => p.tenantId === this.state.selectedTenant)
      ? <Typography variant="body2" gutterBottom align="left" style={{backgroundColor: yellow[100], padding: 16}}>{i18n.t('plans.alreadyHasPlan')}</Typography>
      : null;
  }

  renderPlansByTenant() {
    const activePlan = this.props.activePlans.some(p => p.tenantId === this.state.selectedTenant);
    const tenantPlans = this.props.plans.data.filter(plan => plan.tenant === this.state.selectedTenant);
    const plansByValidity = _.groupBy(tenantPlans, p => p.validity);
    return Object.keys(plansByValidity).map(validity => (
      <React.Fragment key={validity}>
        <Typography variant="subtitle1" gutterBottom style={{marginTop: 24}}>{i18n.t(`plans.validity.${validity}`)}</Typography>
        <Grid container spacing={3} alignContent="stretch">
          {plansByValidity[validity].map(plan => (
            <Grid item xs={12} sm={6} md={6} key={plan.id}>
              <PlanDetailsCard
                plan={plan}
                disabled={activePlan}
                insufficientFunds={this.props.balance < plan.price}
                termsAcceptanceRequired={!this.props.acceptedTerms.some(t => t.tenant === this.state.selectedTenant && t.isLatestVersionAccepted)}
              />
            </Grid>))}
        </Grid>
      </React.Fragment>
    ));
  }

  render() {
    return (
      <DocumentTitle title={i18n.t('plans.title')}>
        <div className="page">
        <div style={{margin: 16}}>
          <Grid container justify="center">
            <Grid item xs={12} md={8}>
              <Typography variant="h4" gutterBottom>
                {i18n.t('plans.header')}
              </Typography>
              <Grid container spacing={3}>
                {this.renderTenants()}
              </Grid>
              {this.state.selectedTenant &&
                <Fade in={this.state.selectedTenant ? true : false}>
                  <div style={{marginTop: 24}}>
                    {this.renderActivePlanInfo()}
                    {this.renderPlansByTenant()}
                  </div>
                </Fade>}
              {(!this.state.selectedTenant && this.props.plans > 0) &&
                <Typography variant="caption" gutterBottom align="center" style={{marginTop: 24}}>
                  {i18n.t('plans.chooseOperator')}
                </Typography>}
              {this.props.plans.data.length === 0 &&
                <Typography variant="caption" gutterBottom align="center" style={{marginTop: 24}}>
                  <div style={{marginTop: 30, fontSize: 18}}>{i18n.t('plans.emptyState')}</div>
                </Typography>}
            </Grid>
          </Grid>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

PlansPage.propTypes = {
  actions: PropTypes.object.isRequired,
  plans: PropTypes.object.isRequired,
  activePlans: PropTypes.array.isRequired,
  acceptedTerms: PropTypes.array.isRequired,
  balance: PropTypes.number,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    plans: state.plans,
    activePlans: state.userState.activePlans,
    acceptedTerms: state.userState.acceptedTermsAndConditions,
    balance: state.wallet.data.balance,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlansPage);
