import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import FormTextField from '../form/FormTextField';
import { bikeDefectFormValidation } from '../../models/BikeDefectValidation';
import FormBikePartsPicker from '../form/FormBikePartsPicker';
import FormImageUpload from '../form/FormImageUpload';

let BikeDefectForm = ({ handleSubmit }) => {
  const [t] = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="bikeNumber"
          type="number"
          component={FormTextField}
          label={t('bikeDefects.form.bikeNumberLabel')}
          autoFocus
        />
      </div>
      <div>
        <Field
          name="description"
          component={FormTextField}
          label={t('bikeDefects.form.descriptionLabel')}
          fullWidth
        />
      </div>
      <div>
        <Field
          name="brokenParts"
          component={FormBikePartsPicker}
          label={t('bikeDefects.form.bikePartsPickerLabel')}
        />
      </div>
      <div>
        <Field
          type="file"
          name="photo"
          component={FormImageUpload}
          label={t('bikeDefects.form.photoLabel')}
          removeLabel={t('bikeDefects.form.removePhotoLabel')}
        />
      </div>
    </form>
  );
};

BikeDefectForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

BikeDefectForm = reduxForm({
  form: 'bikeDefect',
  validate: bikeDefectFormValidation,
})(BikeDefectForm);

export default BikeDefectForm;