import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import ActivePlansSlider from './ActivePlansSlider';
import i18n from '../../i18n';

const styles = () => ({
  paper: {
    padding: 16,
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column'
  },
});

class UserStateCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { plans, classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>{i18n.t('userState.header')}</Typography>
        {plans.length > 0 && <ActivePlansSlider activePlans={plans} />}
      </Paper>
    );
  }
}

UserStateCard.propTypes = {
  classes: PropTypes.object.isRequired,
  plans: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  const plans = state.userState.defaultPlanFreeMinutes;
  return {
    plans: [...plans.filter(d => !state.userState.activePlans.some(p => p.tenantId === d.tenantId)), ...state.userState.activePlans],
  };
}

export default connect(mapStateToProps)(withStyles(styles)(UserStateCard));
