import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions  from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import BikeDefectForm from './BikeDefectForm';

const BikeDefectDialog = ({ open, fullScreen, onSubmit, onCancel, dispatch, submitting }) => {
  const [t] = useTranslation();
  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle>{t('bikeDefects.dialog.title')}</DialogTitle>
      <DialogContent>
        <BikeDefectForm onSubmit={onSubmit} initialValues={{ bikeNumber: null, description: null }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={submitting}>
          {t('common.cancelButton')}
        </Button>
        <Button onClick={() => dispatch(submit('bikeDefect'))} disabled={submitting} color="secondary">
          {t('bikeDefects.dialog.submitButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

BikeDefectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
};

export default connect()(withMobileDialog()(BikeDefectDialog));
