export class ReservationModel {
  constructor(id, bikeId, bikeNumber, bikeLocation, expiresAt) {
    this.id = id;
    this.bikeId = bikeId;
    this.bikeNumber = bikeNumber;
    this.bikeLocation = bikeLocation;
    this.expiresAt = expiresAt;
  }
}

export const RideModes = Object.freeze({
  reservation: { value: 'reservation' },
  ride: { value: 'ride' },
});
