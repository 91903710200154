import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography } from '@material-ui/core';
import PhotoIcon from '@material-ui/icons/AddAPhotoTwoTone';
import _ from 'lodash';

const provideFile = delegate => event => {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => delegate(reader.result);
};

const FormImageUpload = ({
  input,
  label,
  removeLabel,
  meta: { error }
}) => {
  const id = _.uniqueId('fileInput');
  return (
    <Grid container spacing={1} style={{marginTop: 16}}>
      <Grid item xs={3} style={{textAlign: 'center'}}>
        {input.value
        ? <React.Fragment>
            <img src={input.value} style={{ width: 60, height: 60, objectFit: 'cover' }} />
            <Typography variant="caption" color="secondary" style={{ cursor: 'pointer' }} onClick={() => input.onChange(null)} display="block">{removeLabel}</Typography>
          </React.Fragment>
        : <React.Fragment>
            <input
              accept="image/png, image/jpeg, image/jpg"
              id={id}
              style={{ display: 'none' }}
              type="file"
              onChange={provideFile(input.onChange)}
              onBlur={provideFile(input.onBlur)}
            />
            <label htmlFor={id}>
              <IconButton component="span"><PhotoIcon /></IconButton>
            </label>
            <Typography variant="caption" display="block">{label}</Typography>
          </React.Fragment>
        }
        {error && <Typography variant="caption" color="error" display="block">{error}</Typography>}
      </Grid>
    </Grid>
  );
};

FormImageUpload.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  removeLabel: PropTypes.string,
  meta: PropTypes.object,
};

export default FormImageUpload;
