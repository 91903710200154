import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import numberFormatter from '../../utils/numberFormatter';

const styles = () => ({
  itemPrice: {
    fontFamily: 'Inconsolata',
    fontWeight: '600',
    paddingRight: 32,
  },
});

const PriceList = ({ classes, priceList }) => (
  <Paper>
    <List>
      {priceList.map((item, i) => (
        <ListItem key={i}>
          <ListItemText primary={item.description} />
          <ListItemSecondaryAction className={classes.itemPrice}>
            {numberFormatter.format(item.amount)}&nbsp;zł
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  </Paper>
);

PriceList.propTypes = {
  classes: PropTypes.object.isRequired,
  priceList: PropTypes.array.isRequired,
};

export default withStyles(styles)(PriceList);
