import i18n from '../i18n';
import cardsApi from '../api/cardsApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';
import moment from "moment";

export const types = {
  GET_CARDS: 'GET_CARDS',
  GET_CARDS_SUCCESS: 'GET_CARDS_SUCCESS',
  GET_CARDS_FAILURE: 'GET_CARDS_FAILURE',
  ASSIGN_CARD: 'ASSIGN_CARD',
  ASSIGN_CARD_SUCCESS: 'ASSIGN_CARD_SUCCESS',
  ASSIGN_CARD_FAILURE: 'ASSIGN_CARD_FAILURE',
  REMOVE_CARD: 'REMOVE_CARD',
  REMOVE_CARD_SUCCESS: 'REMOVE_CARD_SUCCESS',
  REMOVE_CARD_FAILURE: 'REMOVE_CARD_FAILURE',
};

export const actions = {
  load: () => dispatch => {
    dispatch({ type: types.GET_CARDS });
    return cardsApi
      .get()
      .then(response => {
        if (response.ok && response.data && response.data.success !== false) {
          return dispatch({ type: types.GET_CARDS_SUCCESS, data: response.data });
        }

        dispatch({ type: types.GET_CARDS_FAILURE });
        dispatch(notificationActions.showError(i18n.t('cards.actions.loadCardsFailure')));
        return logger.error(response);
      });
  },

  assign: (cardProviderId, { cardNumber, birthday }) => dispatch => {
    dispatch({ type: types.ASSIGN_CARD });
    return cardsApi
      .assign({ cardProviderId, cardNumber, birthday: moment(birthday).format('YYYY-MM-DD') })
      .then(response => {
        if (response.ok && response.data && response.data.success !== false) {
          dispatch({ type: types.ASSIGN_CARD_SUCCESS });
          dispatch(actions.load());
          return;
        }

        dispatch({ type: types.ASSIGN_CARD_FAILURE });
        const error = response.data.failure ? i18n.t(response.data.failure.errorCode, response.data.failure.metadata) : i18n.t('cards.actions.assignFailure');
        dispatch(notificationActions.showError(error));
        return logger.error(response.data);
      });
  },

  remove: id => dispatch => {
    dispatch({ type: types.REMOVE_CARD });
    return cardsApi
      .remove(id)
      .then(response => {
        if (response.ok && response.data && response.data.success !== false) {
          dispatch({ type: types.REMOVE_CARD_SUCCESS });
          dispatch(actions.load());
          return;
        }

        dispatch({ type: types.REMOVE_CARD_FAILURE });
        dispatch(notificationActions.showError(i18n.t('cards.actions.removeFailure')));
        return logger.error(response);
      });
  }
};
