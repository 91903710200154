export class NotificationModel {
  constructor(message, type) {
    this.message = message || '';
    this.type = type || '';
  }
}
  
export const NotificationTypes = Object.freeze({
  success: 'success',
  error: 'error'
});
  