import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import numberFormatter from '../../utils/numberFormatter';
import moment from 'moment';
import _ from 'lodash';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PauseIcon from '@material-ui/icons/PauseCircleOutlineRounded';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircleTwoTone';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircleTwoTone';
import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import routePaths from '../../routePaths';
import i18n from '../../i18n';

import { topUpStatus, balanceItemType } from '../../models/WalletModel';

const styles = {
  tableCell: { padding: '4px 16px 4px 16px' },
  icon: {
    pending: { fontSize: 16, marginRight: 16, color: grey[400] },
    failed: { fontSize: 16, marginRight: 16, color: red[300] },
    success: { fontSize: 16, marginRight: 16, color: lightGreen[500] }
  }
};

const getTopUpIconByStatus = (status) => {
  if (!status) return null;
  if (status.value === topUpStatus.created.value) return <PauseIcon style={styles.icon.pending} />;
  if (status.value === topUpStatus.pending.value) return <PauseIcon style={styles.icon.pending} />;
  if (status.value === topUpStatus.failed.value) return <CancelIcon style={styles.icon.failed} />;
  if (status.value === topUpStatus.success.value) return <AddCircleIcon style={styles.icon.success} />;
  return null;
}

const getIconByType = (type, status) => {
  if (!type) return null;
  if (type.value === balanceItemType.refund.value) return <AddCircleIcon style={styles.icon.success} />;
  if (type.value === balanceItemType.reward.value) return <AddCircleIcon style={styles.icon.success} />;
  if (type.value === balanceItemType.externalTopUp.value) return <AddCircleIcon style={styles.icon.success} />;
  if (type.value === balanceItemType.onlineTopUp.value) return getTopUpIconByStatus(status);
  if (type.value === balanceItemType.withdrawal.value) return <RemoveCircleIcon style={styles.icon.success} />;
  if (type.value === balanceItemType.automaticWithdrawal.value) return <RemoveCircleIcon style={styles.icon.success} />;
  if (type.value === balanceItemType.withdrawalRefundForm.value) return <RemoveCircleIcon style={styles.icon.success} />;
  if (type.value === balanceItemType.payment.value) return <RemoveCircleIcon style={styles.icon.success} />;
  return null;
}

class HistoryTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { wallet: { latestTopUps, rewards, refunds, withdrawals, payments,
      withdrawalFromForm, automaticWithdrawal } } = this.props;
    const data = _.orderBy([...latestTopUps, ...rewards, ...refunds, ...withdrawals, ...payments,
      ...withdrawalFromForm, ...automaticWithdrawal], ['date'], ['desc']);
    return (
      <div>

        <Paper style={{ overflow: 'auto' }}>
          <Table>
            <TableBody>
              {data.map((x, id) =>
                <TableRow hover key={id} style={{ height: 62.4 }}>
                  <TableCell style={styles.tableCell}>
                    <ListItem dense style={{ paddingLeft: 0, paddingRight: 0 }}>
                      {getIconByType(x.type, x.status)}
                      {x.type.value === balanceItemType.payment.value && x.tags.rentalId
                        ? <ListItemText
                            primary={i18n.t(x.type.name)}
                            secondary={<span>{x.getDescription()}<a href={`${routePaths.rides}/${x.tags.rentalId}`}>{i18n.t('walletHistory.ride')}</a></span>} />
                        : <ListItemText primary={i18n.t(x.type.name)} secondary={x.getDescription()} />
                      }
                    </ListItem>
                  </TableCell>
                  <TableCell style={styles.tableCell} align="right">{numberFormatter.format(x.amount)}{' zł'}</TableCell>
                  <TableCell style={styles.tableCell} align="right">{moment(x.date).format('DD.MM.YYYY HH:mm')}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

HistoryTable.propTypes = {
  wallet: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    userState: state.userState,
    wallet: state.wallet.data,
  };
}

export default connect(mapStateToProps)(HistoryTable);
