import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Button, Typography } from '@material-ui/core';
import LocationIcon from '@material-ui/icons/LocationOnTwoTone';
import BikeIcon from '@material-ui/icons/DirectionsBikeTwoTone';
import moment from 'moment';
import _ from 'lodash';
import i18n from '../../i18n';

const styles = () => ({
  card: {
    position: 'absolute',
    top: 10,
    left: 10,
    padding: 10,
  },
  label: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: 10,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
  },
});

class ActiveReservationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remaining: {
        minutes: '--',
        seconds: '--',
      },
      bikeAddress: null,
    };

    this.calculateRemainingTime = this.calculateRemainingTime.bind(this);
    this.getDirectionsUrl = this.getDirectionsUrl.bind(this);
  }

  geocoder = new window.google.maps.Geocoder();

  refreshBikeAddress() {
    this.geocoder.geocode({ location: this.props.reservation.bikeLocation }, (results, status) => {
      if (status == 'OK' && results && results.length > 0) {
        this.setState({ bikeAddress: results[0].formatted_address });
      } else {
        this.setState({ bikeAddress: i18n.t('map.activeReservationCard.unknownAddress') });
      }
    });
  }

  getRemainingMs() {
    const remained = moment(this.props.reservation.expiresAt).diff(moment(), 'milliseconds');
    if (remained < 0) {
      return 0;
    }
    return remained;
  }

  calculateRemainingTime() {
    const remaining = this.getRemainingMs() / 1000;
    const minutesRemained = Math.floor(remaining / 60);
    const secondsRemained = Math.floor(remaining % 60);
    const timeIsUp = minutesRemained === 0 && secondsRemained === 0;
    this.setState({
      remaining: {
        minutes: minutesRemained,
        seconds: _.padStart(secondsRemained, 2, '0'),
      },
      timeIsUp,
    });
    if (timeIsUp) this.props.onExpiration(this.props.reservation.id);
  }

  getDirectionsUrl() {
    const location = this.props.reservation.bikeLocation;
    const destinationString = `${location.lat},${location.lng}`;
    return `https://www.google.com/maps/dir/?api=1&destination=${destinationString}&travelmode=walking`;
  }

  componentDidMount() {
    this.calculateRemainingTime();
    this.refreshBikeAddress();
    this.timerId = setInterval(this.calculateRemainingTime, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  render() {
    const { reservation, onCancel, classes } = this.props;
    return (
      <Paper elevation={8} className={classes.card}>
        <Typography variant="body2" color="secondary" className={classes.label} gutterBottom><BikeIcon color="disabled" className={classes.icon} />{reservation.bikeNumber}</Typography>
        <Typography className={classes.label} gutterBottom><LocationIcon color="disabled" className={classes.icon} /> {this.state.bikeAddress}</Typography>
        <Typography variant="caption" gutterBottom>{i18n.t('map.activeReservationCard.validTill')}</Typography>
        <Typography variant="h4" align="center">{this.state.remaining.minutes}:{this.state.remaining.seconds}</Typography>
        <div className={classes.actions}>
          <Button size="small" color="secondary" disabled={this.state.timeIsUp} href={this.getDirectionsUrl()}>
            {i18n.t('map.activeReservationCard.navigateButton')}
          </Button>
          <Button size="small" color="inherit" disabled={this.state.timeIsUp} onClick={() => onCancel(reservation.id)}>
            {i18n.t('map.activeReservationCard.cancelReservationButton')}
          </Button>
        </div>
      </Paper>
    );
  }
}

ActiveReservationCard.propTypes = {
  classes: PropTypes.object.isRequired,
  reservation: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onExpiration: PropTypes.func.isRequired,
};

export default withStyles(styles)(ActiveReservationCard);
