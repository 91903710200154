import React from "react"
import {Button, FormControl, Grid, Paper, Typography} from "@material-ui/core";
import i18n from "../../../i18n";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {
  assignPhoneNumberVerificationFormValidation
} from "../../../models/auth/AuthModelValidation";
import FormTextField from "../../form/FormTextField";
import './style.scss'


let AssignPhoneNumberVerificationForm = ({handleSubmit, fetching, phoneNumber}) => {

  return(
    <form onSubmit={handleSubmit}>
      <div>
        <h4>{i18n.t('assignPhoneNumber.verificationForm.header')}</h4>
      </div>
      <Paper style={{padding: 16, marginTop: 24}}>
        <div>{i18n.t('assignPhoneNumber.verificationForm.subHeader', {phoneNumber})}</div>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="body2" class='vertical-center'>
              {i18n.t('assignPhoneNumber.verificationForm.codeLabel')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={9}>
            <div className="form-group">
              <FormControl>
              <Field
                name="code"
                type="number"
                component={FormTextField}
                label={i18n.t('assignPhoneNumber.verificationForm.codeLabel')}
              />
              </FormControl>
            </div>
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
          <Button disabled={fetching} type="submit">
            {fetching ? i18n.t('assignPhoneNumber.verificationForm.submitButtonProgress') :
              i18n.t('assignPhoneNumber.verificationForm.submitButtonSMSCode')}
          </Button>
        </div>
      </Paper>
    </form>
  )

}
AssignPhoneNumberVerificationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  fetching: PropTypes.bool.isRequired,
  phoneNumber: PropTypes.string.isRequired
};

AssignPhoneNumberVerificationForm = reduxForm({
  form: 'assignPhoneNumberVerification',
  validate: assignPhoneNumberVerificationFormValidation,
})(AssignPhoneNumberVerificationForm);

export default AssignPhoneNumberVerificationForm;
