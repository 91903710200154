import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Grid, Paper, Typography} from '@material-ui/core';
import DocumentTitle from 'react-document-title';
import WithdrawalFundsForm from "./WithdrawalFundsForm";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {actions as walletActions} from "../../actions/walletActions";
import {connect} from "react-redux";
import WithdrawalFundsViewDialog from "./WithdrawalFundsViewDialog";
import {WithdrawalFundsEnum} from "../../models/enums/WithdrawalFundsEnum";
import {useHistory} from "react-router";
import routePaths from "../../routePaths";

const WithdrawalFundsPage = ({wallet, actions}) => {
  const [t] = useTranslation();
  const history = useHistory();
  const [withdrawalData, setWithdrawalData] = useState({
    name: '',
    lastName: '',
    address: '',
    countryCode: 'PL',
    accountNumber: '',
    amount: null,
  });
  const [mode, setMode] = useState(WithdrawalFundsEnum.edit);

  const onHandleSetWithdrawalData = (data) => {
    setWithdrawalData(data);
    setMode(WithdrawalFundsEnum.view)
  }

  const onHandleSave = async (data) => {
    const res = await actions.withdrawalFundsForm(data);
    if (res){
      history.replace(routePaths.home)
    }
  }

  return (
    <DocumentTitle title={t('withdrawalFunds.header')}>
      <div className="page">
        <div style={{margin: 16 }}>
          <Grid container justify="center" >
            <Grid item xs={8} md={8} style={{maxWidth: 700}}>
              <Typography variant="h4" gutterBottom>{t('withdrawalFunds.header')}</Typography>
              <Paper style={{padding: 5}}>
                <Typography variant="64" gutterBottom>{t('withdrawalFunds.subtitle')}</Typography>
                <WithdrawalFundsForm
                  onSubmit={onHandleSetWithdrawalData}
                  availableToWithdrawal={wallet.availableToWithdrawal}
                  disabled={mode !== WithdrawalFundsEnum.edit}
                  initialValues={withdrawalData}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
        <WithdrawalFundsViewDialog
          open={mode === WithdrawalFundsEnum.view}
          onSubmit={onHandleSave}
          onCancel={() => setMode(WithdrawalFundsEnum.edit)}
          submitting={false}
          withdrawalData={withdrawalData}
        />
      </div>
    </DocumentTitle>
  );
}

WithdrawalFundsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  wallet: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(walletActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    wallet: state.wallet.data,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalFundsPage);
