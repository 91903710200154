import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Typography} from "@material-ui/core";

const FormCheckBoxLabelWithChildren = ({
  input,
  label,
  meta: { touched, error },
}) => {
  return (
    <span>
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value}
            onChange={e => {
              input.onChange(e, !input.value);
            }}
            value={input.name}
          />
        }
        label={label}
      />
      {touched && error ? <Typography variant="caption" color="error">{error}</Typography> : null}
    </span>
  );
}

FormCheckBoxLabelWithChildren.propTypes = {
  input: PropTypes.object,
  label: PropTypes.node,
  meta: PropTypes.object,

};

export default FormCheckBoxLabelWithChildren;
