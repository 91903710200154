export default [
  {
    countryCode: 'AF',
    prefix: '+93',
  },
  {
    countryCode: 'AX',
    prefix: '+358',
  },
  {
    countryCode: 'AL',
    prefix: '+355',
  },
  {
    countryCode: 'DZ',
    prefix: '+213',
  },
  {
    countryCode: 'AS',
    prefix: '+1',
  },
  {
    countryCode: 'AD',
    prefix: '+376',
  },
  {
    countryCode: 'AO',
    prefix: '+244',
  },
  {
    countryCode: 'AI',
    prefix: '+1',
  },
  {
    countryCode: 'AQ',
    prefix: '+672',
  },
  {
    countryCode: 'AG',
    prefix: '+1',
  },
  {
    countryCode: 'AR',
    prefix: '+54',
  },
  {
    countryCode: 'AM',
    prefix: '+374',
  },
  {
    countryCode: 'AW',
    prefix: '+297',
  },
  {
    countryCode: 'AU',
    prefix: '+61',
  },
  {
    countryCode: 'AT',
    prefix: '+43',
  },
  {
    countryCode: 'AZ',
    prefix: '+994',
  },
  {
    countryCode: 'BS',
    prefix: '+1',
  },
  {
    countryCode: 'BH',
    prefix: '+973',
  },
  {
    countryCode: 'BD',
    prefix: '+880',
  },
  {
    countryCode: 'BB',
    prefix: '+1',
  },
  {
    countryCode: 'BY',
    prefix: '+375',
  },
  {
    countryCode: 'BE',
    prefix: '+32',
  },
  {
    countryCode: 'BZ',
    prefix: '+501',
  },
  {
    countryCode: 'BJ',
    prefix: '+229',
  },
  {
    countryCode: 'BM',
    prefix: '+1',
  },
  {
    countryCode: 'BT',
    prefix: '+975',
  },
  {
    countryCode: 'BO',
    prefix: '+591',
  },
  {
    countryCode: 'BQ',
    prefix: '+599',
  },
  {
    countryCode: 'BA',
    prefix: '+387',
  },
  {
    countryCode: 'BW',
    prefix: '+267',
  },
  {
    countryCode: 'BR',
    prefix: '+55',
  },
  {
    countryCode: 'IO',
    prefix: '+246',
  },
  {
    countryCode: 'VG',
    prefix: '+1',
  },
  {
    countryCode: 'VI',
    prefix: '+1',
  },
  {
    countryCode: 'BN',
    prefix: '+673',
  },
  {
    countryCode: 'BG',
    prefix: '+359',
  },
  {
    countryCode: 'BF',
    prefix: '+226',
  },
  {
    countryCode: 'BI',
    prefix: '+257',
  },
  {
    countryCode: 'KH',
    prefix: '+855',
  },
  {
    countryCode: 'CM',
    prefix: '+237',
  },
  {
    countryCode: 'CA',
    prefix: '+1',
  },
  {
    countryCode: 'CV',
    prefix: '+238',
  },
  {
    countryCode: 'KY',
    prefix: '+1',
  },
  {
    countryCode: 'CF',
    prefix: '+236',
  },
  {
    countryCode: 'TD',
    prefix: '+235',
  },
  {
    countryCode: 'CL',
    prefix: '+56',
  },
  {
    countryCode: 'CN',
    prefix: '+86',
  },
  {
    countryCode: 'CX',
    prefix: '+61',
  },
  {
    countryCode: 'CC',
    prefix: '+61',
  },
  {
    countryCode: 'CO',
    prefix: '+57',
  },
  {
    countryCode: 'KM',
    prefix: '+269',
  },
  {
    countryCode: 'CG',
    prefix: '+242',
  },
  {
    countryCode: 'CD',
    prefix: '+243',
  },
  {
    countryCode: 'CK',
    prefix: '+682',
  },
  {
    countryCode: 'CR',
    prefix: '+506',
  },
  {
    countryCode: 'HR',
    prefix: '+385',
  },
  {
    countryCode: 'CU',
    prefix: '+53',
  },
  {
    countryCode: 'CW',
    prefix: '+599',
  },
  {
    countryCode: 'CY',
    prefix: '+357',
  },
  {
    countryCode: 'CZ',
    prefix: '+420',
  },
  {
    countryCode: 'DK',
    prefix: '+45',
  },
  {
    countryCode: 'DJ',
    prefix: '+253',
  },
  {
    countryCode: 'DM',
    prefix: '+1',
  },
  {
    countryCode: 'DO',
    prefix: '+1',
  },
  {
    countryCode: 'EC',
    prefix: '+593',
  },
  {
    countryCode: 'EG',
    prefix: '+20',
  },
  {
    countryCode: 'SV',
    prefix: '+503',
  },
  {
    countryCode: 'GQ',
    prefix: '+240',
  },
  {
    countryCode: 'ER',
    prefix: '+291',
  },
  {
    countryCode: 'EE',
    prefix: '+372',
  },
  {
    countryCode: 'ET',
    prefix: '+251',
  },
  {
    countryCode: 'FK',
    prefix: '+500',
  },
  {
    countryCode: 'FO',
    prefix: '+298',
  },
  {
    countryCode: 'FJ',
    prefix: '+679',
  },
  {
    countryCode: 'FI',
    prefix: '+358',
  },
  {
    countryCode: 'FR',
    prefix: '+33',
  },
  {
    countryCode: 'GF',
    prefix: '+594',
  },
  {
    countryCode: 'PF',
    prefix: '+689',
  },
  {
    countryCode: 'GA',
    prefix: '+241',
  },
  {
    countryCode: 'GM',
    prefix: '+220',
  },
  {
    countryCode: 'GE',
    prefix: '+995',
  },
  {
    countryCode: 'DE',
    prefix: '+49',
  },
  {
    countryCode: 'GH',
    prefix: '+233',
  },
  {
    countryCode: 'GI',
    prefix: '+350',
  },
  {
    countryCode: 'GR',
    prefix: '+30',
  },
  {
    countryCode: 'GL',
    prefix: '+299',
  },
  {
    countryCode: 'GD',
    prefix: '+1',
  },
  {
    countryCode: 'GP',
    prefix: '+590',
  },
  {
    countryCode: 'GU',
    prefix: '+1',
  },
  {
    countryCode: 'GT',
    prefix: '+502',
  },
  {
    countryCode: 'GN',
    prefix: '+224',
  },
  {
    countryCode: 'GW',
    prefix: '+245',
  },
  {
    countryCode: 'GY',
    prefix: '+592',
  },
  {
    countryCode: 'HT',
    prefix: '+509',
  },
  {
    countryCode: 'VA',
    prefix: '+379',
  },
  {
    countryCode: 'HN',
    prefix: '+504',
  },
  {
    countryCode: 'HK',
    prefix: '+852',
  },
  {
    countryCode: 'HU',
    prefix: '+36',
  },
  {
    countryCode: 'IS',
    prefix: '+354',
  },
  {
    countryCode: 'IN',
    prefix: '+91',
  },
  {
    countryCode: 'ID',
    prefix: '+62',
  },
  {
    countryCode: 'CI',
    prefix: '+225',
  },
  {
    countryCode: 'IR',
    prefix: '+98',
  },
  {
    countryCode: 'IQ',
    prefix: '+964',
  },
  {
    countryCode: 'IE',
    prefix: '+353',
  },
  {
    countryCode: 'IL',
    prefix: '+972',
  },
  {
    countryCode: 'IT',
    prefix: '+39',
  },
  {
    countryCode: 'JM',
    prefix: '+1',
  },
  {
    countryCode: 'JP',
    prefix: '+81',
  },
  {
    countryCode: 'JO',
    prefix: '+962',
  },
  {
    countryCode: 'KZ',
    prefix: '+7',
  },
  {
    countryCode: 'KE',
    prefix: '+254',
  },
  {
    countryCode: 'KI',
    prefix: '+686',
  },
  {
    countryCode: 'KW',
    prefix: '+965',
  },
  {
    countryCode: 'KG',
    prefix: '+996',
  },
  {
    countryCode: 'LA',
    prefix: '+856',
  },
  {
    countryCode: 'LV',
    prefix: '+371',
  },
  {
    countryCode: 'LB',
    prefix: '+961',
  },
  {
    countryCode: 'LS',
    prefix: '+266',
  },
  {
    countryCode: 'LR',
    prefix: '+231',
  },
  {
    countryCode: 'LY',
    prefix: '+218',
  },
  {
    countryCode: 'LI',
    prefix: '+423',
  },
  {
    countryCode: 'LT',
    prefix: '+370',
  },
  {
    countryCode: 'LU',
    prefix: '+352',
  },
  {
    countryCode: 'MO',
    prefix: '+853',
  },
  {
    countryCode: 'MK',
    prefix: '+389',
  },
  {
    countryCode: 'MG',
    prefix: '+261',
  },
  {
    countryCode: 'MW',
    prefix: '+265',
  },
  {
    countryCode: 'MY',
    prefix: '+60',
  },
  {
    countryCode: 'MV',
    prefix: '+960',
  },
  {
    countryCode: 'ML',
    prefix: '+223',
  },
  {
    countryCode: 'MT',
    prefix: '+356',
  },
  {
    countryCode: 'MH',
    prefix: '+692',
  },
  {
    countryCode: 'MQ',
    prefix: '+596',
  },
  {
    countryCode: 'MR',
    prefix: '+222',
  },
  {
    countryCode: 'MU',
    prefix: '+230',
  },
  {
    countryCode: 'YT',
    prefix: '+262',
  },
  {
    countryCode: 'MX',
    prefix: '+52',
  },
  {
    countryCode: 'FM',
    prefix: '+691',
  },
  {
    countryCode: 'MD',
    prefix: '+373',
  },
  {
    countryCode: 'MC',
    prefix: '+377',
  },
  {
    countryCode: 'MN',
    prefix: '+976',
  },
  {
    countryCode: 'ME',
    prefix: '+382',
  },
  {
    countryCode: 'MS',
    prefix: '+1',
  },
  {
    countryCode: 'MA',
    prefix: '+212',
  },
  {
    countryCode: 'MZ',
    prefix: '+258',
  },
  {
    countryCode: 'MM',
    prefix: '+95',
  },
  {
    countryCode: 'NA',
    prefix: '+264',
  },
  {
    countryCode: 'NR',
    prefix: '+674',
  },
  {
    countryCode: 'NP',
    prefix: '+977',
  },
  {
    countryCode: 'NL',
    prefix: '+31',
  },
  {
    countryCode: 'NC',
    prefix: '+687',
  },
  {
    countryCode: 'NZ',
    prefix: '+64',
  },
  {
    countryCode: 'NI',
    prefix: '+505',
  },
  {
    countryCode: 'NE',
    prefix: '+227',
  },
  {
    countryCode: 'NG',
    prefix: '+234',
  },
  {
    countryCode: 'NU',
    prefix: '+683',
  },
  {
    countryCode: 'NF',
    prefix: '+672',
  },
  {
    countryCode: 'KP',
    prefix: '+850',
  },
  {
    countryCode: 'MP',
    prefix: '+1',
  },
  {
    countryCode: 'NO',
    prefix: '+47',
  },
  {
    countryCode: 'OM',
    prefix: '+968',
  },
  {
    countryCode: 'PK',
    prefix: '+92',
  },
  {
    countryCode: 'PW',
    prefix: '+680',
  },
  {
    countryCode: 'PS',
    prefix: '+970',
  },
  {
    countryCode: 'PA',
    prefix: '+507',
  },
  {
    countryCode: 'PG',
    prefix: '+675',
  },
  {
    countryCode: 'PY',
    prefix: '+595',
  },
  {
    countryCode: 'PE',
    prefix: '+51',
  },
  {
    countryCode: 'PH',
    prefix: '+63',
  },
  {
    countryCode: 'PN',
    prefix: '+64',
  },
  {
    countryCode: 'PL',
    prefix: '+48',
  },
  {
    countryCode: 'PT',
    prefix: '+351',
  },
  {
    countryCode: 'PR',
    prefix: '+1',
  },
  {
    countryCode: 'QA',
    prefix: '+974',
  },
  {
    countryCode: 'XK',
    prefix: '+383',
  },
  {
    countryCode: 'RE',
    prefix: '+262',
  },
  {
    countryCode: 'RO',
    prefix: '+40',
  },
  {
    countryCode: 'RU',
    prefix: '+7',
  },
  {
    countryCode: 'RW',
    prefix: '+250',
  },
  {
    countryCode: 'BL',
    prefix: '+590',
  },
  {
    countryCode: 'SH',
    prefix: '+290',
  },
  {
    countryCode: 'KN',
    prefix: '+1',
  },
  {
    countryCode: 'LC',
    prefix: '+1',
  },
  {
    countryCode: 'MF',
    prefix: '+590',
  },
  {
    countryCode: 'PM',
    prefix: '+508',
  },
  {
    countryCode: 'VC',
    prefix: '+1',
  },
  {
    countryCode: 'WS',
    prefix: '+685',
  },
  {
    countryCode: 'SM',
    prefix: '+378',
  },
  {
    countryCode: 'ST',
    prefix: '+239',
  },
  {
    countryCode: 'SA',
    prefix: '+966',
  },
  {
    countryCode: 'SN',
    prefix: '+221',
  },
  {
    countryCode: 'RS',
    prefix: '+381',
  },
  {
    countryCode: 'SC',
    prefix: '+248',
  },
  {
    countryCode: 'SL',
    prefix: '+232',
  },
  {
    countryCode: 'SG',
    prefix: '+65',
  },
  {
    countryCode: 'SX',
    prefix: '+1',
  },
  {
    countryCode: 'SK',
    prefix: '+421',
  },
  {
    countryCode: 'SI',
    prefix: '+386',
  },
  {
    countryCode: 'SB',
    prefix: '+677',
  },
  {
    countryCode: 'SO',
    prefix: '+252',
  },
  {
    countryCode: 'ZA',
    prefix: '+27',
  },
  {
    countryCode: 'GS',
    prefix: '+500',
  },
  {
    countryCode: 'KR',
    prefix: '+82',
  },
  {
    countryCode: 'SS',
    prefix: '+211',
  },
  {
    countryCode: 'ES',
    prefix: '+34',
  },
  {
    countryCode: 'LK',
    prefix: '+94',
  },
  {
    countryCode: 'SD',
    prefix: '+249',
  },
  {
    countryCode: 'SR',
    prefix: '+597',
  },
  {
    countryCode: 'SJ',
    prefix: '+47',
  },
  {
    countryCode: 'SZ',
    prefix: '+268',
  },
  {
    countryCode: 'SE',
    prefix: '+46',
  },
  {
    countryCode: 'CH',
    prefix: '+41',
  },
  {
    countryCode: 'SY',
    prefix: '+963',
  },
  {
    countryCode: 'TW',
    prefix: '+886',
  },
  {
    countryCode: 'TJ',
    prefix: '+992',
  },
  {
    countryCode: 'TZ',
    prefix: '+255',
  },
  {
    countryCode: 'TH',
    prefix: '+66',
  },
  {
    countryCode: 'TL',
    prefix: '+670',
  },
  {
    countryCode: 'TG',
    prefix: '+228',
  },
  {
    countryCode: 'TK',
    prefix: '+690',
  },
  {
    countryCode: 'TO',
    prefix: '+676',
  },
  {
    countryCode: 'TT',
    prefix: '+1',
  },
  {
    countryCode: 'TN',
    prefix: '+216',
  },
  {
    countryCode: 'TR',
    prefix: '+90',
  },
  {
    countryCode: 'TM',
    prefix: '+993',
  },
  {
    countryCode: 'TC',
    prefix: '+1',
  },
  {
    countryCode: 'TV',
    prefix: '+688',
  },
  {
    countryCode: 'UG',
    prefix: '+256',
  },
  {
    countryCode: 'UA',
    prefix: '+380',
  },
  {
    countryCode: 'AE',
    prefix: '+971',
  },
  {
    countryCode: 'GB',
    prefix: '+44',
  },
  {
    countryCode: 'US',
    prefix: '+1',
  },
  {
    countryCode: 'UY',
    prefix: '+598',
  },
  {
    countryCode: 'UZ',
    prefix: '+998',
  },
  {
    countryCode: 'VU',
    prefix: '+678',
  },
  {
    countryCode: 'VE',
    prefix: '+58',
  },
  {
    countryCode: 'VN',
    prefix: '+84',
  },
  {
    countryCode: 'WF',
    prefix: '+681',
  },
  {
    countryCode: 'EH',
    prefix: '+212',
  },
  {
    countryCode: 'YE',
    prefix: '+967',
  },
  {
    countryCode: 'ZM',
    prefix: '+260',
  },
  {
    countryCode: 'ZW',
    prefix: '+263',
  },
];
